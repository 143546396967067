import { useTranslation } from "react-i18next";
import { Card } from "../../layout/portalPageLayout";
import { Row } from "../Layout/Layout";
import { H3 } from "../Typography/Typography";
import { PrimaryButtonMedium } from "../Buttons/Buttons";
import { DocumentView } from "./DocumentView";
import type {
  IOrderItem,
  OrderStatus,
  QuoteRequestItem,
  QuoteStatus,
  SampleRequestDetail,
  SampleRequestStatus,
  TransactionDocument,
} from "../../types/types";

type DocumentViewItem = QuoteRequestItem | IOrderItem | SampleRequestDetail;

export const TransactionsDocumentView = ({
  items,
  other_documents,
  onAddDocumentClick,
  status,
  can_add_documents = true,
}: {
  items: DocumentViewItem[];
  other_documents: TransactionDocument[];
  onAddDocumentClick: () => void;
  status: OrderStatus | QuoteStatus | SampleRequestStatus;
  can_add_documents?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Row>
        <H3>{t("Documents")}</H3>
        {status !== "completed" &&
          !status.includes("pending") &&
          can_add_documents && (
            <PrimaryButtonMedium onClick={onAddDocumentClick}>
              {t("Add")}
            </PrimaryButtonMedium>
          )}
      </Row>
      <DocumentView items={items} other_documents={other_documents} />
    </Card>
  );
};
