import styled from "styled-components/macro";
import type { DataMutate } from "../../../../types/types";
import type { CollectionSchema, PIMProduct } from "../../../../types/types.PIM";
import { CollectionComponent } from "./CollectionComponent";

const CollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:last-child {
    border-bottom: none;
  }
`;

const BorderBottomContainer = styled.div<{ firstItem: boolean }>`
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  padding: ${({ firstItem }) => (firstItem ? "0 0 44px" : "44px 0")};
`;

export const MatchingCollectionsContainer = ({
  collections,
  product,
  fetchProductData,
  isPortfolio = false,
  replaceProductId,
}: {
  collections: CollectionSchema[];
  product: PIMProduct;
  isPortfolio?: boolean;
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
}) => {
  return (
    <CollectionsContainer>
      {collections.reduce<JSX.Element[]>((acc, collection, idx) => {
        if (!isPortfolio || !collection.is_restricted) {
          acc.push(
            <BorderBottomContainer firstItem={idx === 0} key={collection.id}>
              <CollectionComponent
                collection={collection}
                product={product}
                fetchProductData={fetchProductData}
                isPortfolio={isPortfolio}
                replaceProductId={replaceProductId}
              />
            </BorderBottomContainer>
          );
        }
        return acc;
      }, [])}
    </CollectionsContainer>
  );
};
