import React from "react";
import styled from "styled-components/macro";
import type { WithChildren } from "../../types/types";
import { InvisibleButton } from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { ChipContainer } from "../Chips/Chips";
import { XIcon } from "../Icons/Icons";

type DeletableChipDivProps = {
  active?: boolean;
};

const DeletableChipDiv = styled.div<DeletableChipDivProps>`
  display: inline-block;
  position: relative;
  border-radius: 19px;
  border: solid 1px ${({ theme }) => theme.secondaryBorder};
  background-color: ${({ theme, active }) =>
    (active && theme.secondaryButtonBG) || theme.tertiaryButtonBG};
  padding: ${({ active }) => (active ? `8px 0 5px 20px` : `8px 20px 5px`)};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${(props) =>
    props.active
      ? props.theme.secondaryTextColor
      : props.theme.primaryTextColor};
  &:hover,
  &:focus {
    border: solid 1px ${({ theme }) => theme.tertiaryBorder};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.primaryTextColor};
  }
  outline: none;
  button {
    transform: translate(0px, 3px);
    margin-left: 9px;
    margin-right: 6px;
  }
`;

const GreyText = styled.p`
  color: ${(props) => props.theme.secondaryTextColor};
`;

type DeletableChipProps = WithChildren<{
  active?: boolean;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}>;

export const DeletableChip = ({
  children,
  handleClick,
  active,
}: DeletableChipProps) => {
  return (
    <DeletableChipDiv
      active={active}
      style={{ marginRight: "8px", marginBottom: "10px" }}
    >
      {children}
      {active && (
        <InvisibleButton type="button" onClick={handleClick}>
          <XIcon height={16} width={16} />
        </InvisibleButton>
      )}
    </DeletableChipDiv>
  );
};

type DeletableChipsProps = {
  chips: ChipType[];
  noneSelectedName: string;
  handleClick: (name: string) => void;
};

export const DeletableChips = ({
  chips,
  noneSelectedName,
  handleClick,
}: DeletableChipsProps) => {
  if (chips.length > 0) {
    return (
      <ChipContainer>
        {chips.map((chip) => (
          <DeletableChip
            handleClick={() => handleClick(chip.name)}
            active={chip?.active ?? true}
            key={chip.name}
          >
            {chip.name}
          </DeletableChip>
        ))}
      </ChipContainer>
    );
  } else return <GreyText>{`No ${noneSelectedName} selected.`}</GreyText>;
};
