import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  CancelButton,
  PrimaryButtonFitContainer,
} from "../../../components/Buttons/Buttons";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { SmallSectionHeader } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { FormGrid2x2 } from "../../../layout/FormLayout";
import type {
  OptionType,
  Product,
  ProductSKU,
  UUID,
} from "../../../types/types";
import {
  packagingTypeOrUnitToOption,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import { convertProductSKUToOption, useStoreState } from "../../../util/util";
import type { CustomSkuData } from "../../SharedPages/SellerQuoteDetailPage/AddCustomSkuForm";
import { SAMPLE_REQUEST_PURPOSES } from "../../public/AddToSampleRequestForm/AddToSampleRequestForm";
import { strings } from "../../../util/strings";
import { SearchSelectInfiniteScroll } from "../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import type { PIMProductBase } from "../../../types/types.PIM";
import { ProductApplicationSelect } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";

/**
 * Heavily derived from `FormInputs` in `BuyerQuoteItemForm`
 * `price_per_uom` will only exist if price tiers don't.
 */
export type AddOrEditProductFormOutput = {
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
  // The actual inputs in the form depend on what the user selects for packaging.
  // We put all of them here, some as optional, and then enforce things with
  // form validation. (I tried creating a stricter type but it led to too many
  // type checking errors related to react-hook-form, e.g. for default arguments.)
  packaging: OptionType<ProductSKU> | null;
  no_of_units: string;
  unit_of_measure?: OptionType<string>;
  custom_packaging_quantity?: string;
  product_id: string;
  purpose: OptionType<string>; // TODO is this nullable?
  total_annual_quantity: string;
  total_quantity_unit: OptionType<UUID>;
};

export type InlineDisplayCustomSKU = Omit<
  CustomSkuData,
  "package_description"
> & { kind: "Buyer SKU" };

type AddOrEditProductFormProps = {
  isDisabled: boolean;
  product: Product | null;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  isEditMode: boolean;
  onCancel: () => void;
  buyer_tenant_id: UUID;
};

export function AddOrEditSampleProductForm({
  isDisabled,
  product,
  setProduct,
  isEditMode,
  onCancel,
  buyer_tenant_id,
}: AddOrEditProductFormProps) {
  const methodsOfUseForm = useFormContext();
  const {
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
  } = methodsOfUseForm;
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { packagingUnits } = usePackagingUnits();
  const packagingUnitOptions = packagingUnits
    ? packagingUnits?.map(packagingTypeOrUnitToOption)
    : [];

  const handleProductChange = (option: OptionType<Product>) => {
    setValue("product_id", option.value.id);
    if (errors["product_id"]) {
      clearErrors("product_id");
    }
    if (product) {
      setValue("unit_of_measure", { value: undefined, label: undefined });
      setValue("packaging", { value: undefined, label: undefined });
      setValue("no_of_units", null);
    }
    setProduct(option.value);
  };

  const productID = watch("product_id");

  useEffect(() => {
    register({ name: "product_id", required: strings(t).thisIsARequiredField });
  }, [register, t]);

  const productSkuOptions =
    product &&
    product.product_skus
      .map(convertProductSKUToOption)
      .filter((item) => !!item.value.is_sample);

  // We don't want user to be able to change product when editing, instead they
  // should remove it from the order and create a new item.
  const productSelectIsDisabled = isDisabled || isEditMode;

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
    t("No sample SKUs are available for this Product");

  return (
    <>
      <>
        <SearchSelectInfiniteScroll
          name={"product_id"}
          errors={errors}
          formState={formState}
          placeholder={t("Product")}
          baseUrl={endpoints.v2_tenants_id_pim_products(tenant_id)}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("status", "published");
            if (buyer_tenant_id) {
              params.append("buyer_tenant_id", buyer_tenant_id);
            }
            return params;
          })()}
          getOptions={(response: PIMProductBase[]) =>
            response.map((product) => ({ value: product, label: product.name }))
          }
          value={{
            value: product,
            label: product ? product.name : null,
          }}
          onChange={(data: OptionType<Product>) => handleProductChange(data)}
          isDisabled={productSelectIsDisabled}
        />
        <div>
          <Controller
            as={SelectBoxV2}
            control={control}
            isDisabled={isDisabled}
            name="packaging"
            placeholder={t("Preferred SKU")}
            options={productSkuOptions ? productSkuOptions : []}
            rules={{ required: strings(t).thisIsARequiredField }}
            errors={errors}
            formState={formState}
            noOptionsMessage={noOptionsMessage}
          />
        </div>
        <TextField
          name={"no_of_units"}
          label={t("Number of units")}
          disabled={isDisabled}
          theref={register({
            required: true,
          })}
          errors={errors}
          formState={formState}
          type="number"
        />

        <SmallSectionHeader>{t("Potential annual usage")}</SmallSectionHeader>
        <FormGrid2x2>
          <TextField
            name={"total_annual_quantity"}
            label={t("Total Annual Quantity")}
            disabled={isDisabled}
            theref={register({
              required: true,
            })}
            errors={errors}
            formState={formState}
            type="number"
          />
          <Controller
            as={SelectBoxV2}
            control={control}
            name="total_quantity_unit"
            placeholder={t("Unit of Measure")}
            id="total_quantity_unit"
            isDisabled={isDisabled}
            options={packagingUnitOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        </FormGrid2x2>
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={product?.product_applications ?? []}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="purpose"
          placeholder={t("Purpose")}
          rules={{ required: true }}
          options={SAMPLE_REQUEST_PURPOSES}
          isDisabled={isDisabled}
          errors={errors}
          formState={formState}
        />
      </>
      <PrimaryButtonFitContainer
        type="submit"
        onClick={() => {
          if (!productID) {
            setError("product_id", {
              type: "required",
              message: strings(t).thisIsARequiredField,
            });
          }
        }}
      >
        {isEditMode ? t("Save Changes") : t("Add product")}
      </PrimaryButtonFitContainer>
      <ReactTooltip
        delayShow={1000}
        id="price-tiers-warning"
        delayHide={500}
        effect="solid"
      />
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CancelButton style={{ paddingLeft: "13px" }} onClick={onCancel}>
            {t("Cancel")}
          </CancelButton>
        </div>
      )}
    </>
  );
}
