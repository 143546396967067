import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  ArchiveButton,
  ButtonWithConfirmDialog,
  EditButton,
} from "../../../../../components/Buttons/Buttons";
import {
  CaretRight,
  SystemDefaultIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type {
  ListPatchArgSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import type { AxiosError } from "axios";
import Axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { checkTextOverflow, useStoreState } from "../../../../../util/util";
import { EditableTitle } from "../../../../../components/EditableTitle/EditableTitle";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ReactTooltip from "react-tooltip";
import type { ListItemFormValue } from "./ListHome";
import { IconContainer, ListItemNameSchemaFn } from "./ListHome";
import { SmallSectionHeaderRegular } from "../../../../../components/Typography/Typography";
import type { DataMutate } from "../../../../../types/types";
import { SystemIconWrapper } from "../components/PIM.components.util";
import { useAuthContext } from "../../../../../components/Auth";

export const ListItemBase = styled.div`
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  > h3 {
    flex: 1 0 0.5;
  }
  border-top: ${({ theme }) => `0.5px solid ${theme.secondaryBorder}`};
  border-bottom: ${({ theme }) => `0.5px solid ${theme.secondaryBorder}`};
  border-right: ${({ theme }) => `2px solid ${theme.secondaryBorder}`};
  border-left: ${({ theme }) => `3px solid ${theme.secondaryBorder}`};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ListItemWrapper = styled(ListItemBase)<{ active?: boolean }>`
  > div {
    > button,
    button:disabled {
      opacity: 0;
    }
  }
  &:hover {
    > div {
      > button {
        opacity: 1;
      }
      > button:disabled {
        opacity: 0.5;
      }
    }
  }
  border-top: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.brandColor}`
      : `0.5px solid ${theme.secondaryBorder}`};
  border-bottom: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.brandColor}`
      : `0.5px solid ${theme.secondaryBorder}`};
  border-right: ${({ theme, active }) =>
    active
      ? `2px solid ${theme.brandColor}`
      : `2px solid ${theme.secondaryBorder}`};
  border-left: ${({ theme, active }) =>
    active
      ? `3px solid ${theme.brandColor}`
      : `3px solid ${theme.secondaryBorder}`};
  background-color: ${({ theme, active }) =>
    active ? theme.primaryButtonBG : theme.colors.white};
  justify-content: flex-start;
`;

export const ListItemName = styled(SmallSectionHeaderRegular)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const ListItem = ({
  item,
  active,
  selectItem,
  mutateList,
}: {
  item: ShortListSchema;
  active: boolean;
  selectItem: (reset?: boolean) => void;
  mutateList: DataMutate<PaginatedListSchema>;
}) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const editNameUseForm = useForm({
    resolver: zodResolver(ListItemNameSchemaFn(t)),
  });
  const editNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void; focus: () => void }>(
      null
    );
  const nameRef = useRef<HTMLHeadingElement>(null);
  const { hasPermission } = useAuthContext();

  const handleDeleteListItem = async () => {
    try {
      await Axios.patch<ListPatchArgSchema, ShortListSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, item.id),
        {
          is_deleted: true,
        }
      );
      notifySuccess(t("List archived successfully"));
      mutateList();
      selectItem(true);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not archive list. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setTimeout(() => editNameRef.current?.setEditMode(true));
  };

  const handleEditListConfirm = async ({ name }: ListItemFormValue) => {
    if (name.trim() !== item.name.trim()) {
      try {
        await Axios.patch<ListPatchArgSchema, ShortListSchema>(
          endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, item.id),
          {
            name: name.trim(),
          }
        );
        notifySuccess(t("List name updated successfully"));
        setEditMode(false);
        mutateList();
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Could not edit list. Something went wrong."),
          {
            error,
          }
        );
      }
    }
  };

  const isTextOverflow = () =>
    nameRef.current ? checkTextOverflow(nameRef.current).widthOverflow : false;

  useEffect(() => {
    if (!active && editMode) {
      setEditMode(false);
    }
  }, [active, editMode]);

  return (
    <ListItemWrapper active={active} onClick={(e) => selectItem()}>
      {editMode ? (
        <form
          id="edit-list-form"
          noValidate
          onSubmit={editNameUseForm.handleSubmit(handleEditListConfirm)}
        >
          <EditableTitle
            title={item.name}
            formId="edit-list-form"
            name="name"
            methodsOfUseForm={editNameUseForm}
            ref={editNameRef}
            onClose={() => setEditMode(false)}
            fontSize="small"
            fontWeight="regular"
          />
        </form>
      ) : (
        <>
          <ListItemName
            ref={nameRef}
            data-for={`list-item-name-${item.id}-tooltip`}
            data-tip={
              isTextOverflow() ? `${item.name} (${item.count ?? 0})` : ""
            }
          >
            {`${item.name} (${item.count ?? 0})`}
          </ListItemName>
          <ReactTooltip id={`list-item-name-${item.id}-tooltip`} />
        </>
      )}
      <SystemIconWrapper
        style={{
          padding: 0,
          position: "relative",
          top: "2px",
        }}
        data-for={`system${item.id}`}
        data-tip={t("System Default")}
      >
        {item.is_system && <SystemDefaultIcon width={18} height={18} />}
      </SystemIconWrapper>
      {item.is_system && <ReactTooltip id={`system${item.id}`} />}
      <IconContainer>
        {!item.is_system && (
          <>
            {hasPermission("delete_lists") && (
              <ButtonWithConfirmDialog
                Button={(props) => (
                  <>
                    <ArchiveButton
                      {...props}
                      width={24}
                      height={24}
                      datafor={`list-item-${item.id}-delete-tooltip`}
                      datatip={
                        item.is_system
                          ? t("You cannot archive system default list")
                          : ""
                      }
                    />
                    <ReactTooltip id={`list-item-${item.id}-delete-tooltip`} />
                  </>
                )}
                testid={`list-item-${item.id}`}
                disabled={item.is_system}
                handleConfirm={handleDeleteListItem}
                confirmMessage={t(
                  "Are you sure you want to archive this list, {{itemName}}?",
                  { itemName: item.name }
                )}
              />
            )}
            {hasPermission("modify_lists") && (
              <EditButton
                onClick={handleEditClick}
                width={24}
                height={24}
                datafor={`list-item-${item.id}-tooltip`}
                datatip={
                  item.is_system
                    ? t("You cannot edit a system default list")
                    : ""
                }
                disabled={item.is_system}
              />
            )}
          </>
        )}
        <CaretRight />
        <ReactTooltip id={`list-item-${item.id}-tooltip`} />
      </IconContainer>
    </ListItemWrapper>
  );
};
