import styled from "styled-components/macro";
import { ProductListItemWrapper } from "../../layout/publicPageLayout";
import { H3 } from "../../components/Typography/Typography";
import type { PIMProduct } from "../../types/types.PIM";
import { getAttributeWithObjectType, useMediaQueries } from "../../util/util";
import { useEffect, useRef, useState } from "react";

export const PAGE_SIZE = 10;

export const LoaderContainer = styled(ProductListItemWrapper)`
  border: none;
  align-items: center;
`;

export const TotalProducts = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 10px 0 20px;
  height: 15px;
`;

export const CustomH3 = styled(H3)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px; // becauase overflow is hidden, it sometimes cut off
`;

export const CustomProductName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  margin-bottom: 5px;
  max-width: 100%;
  display: block;
`;

export const CasNumber = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const getProductName = (product: PIMProduct) =>
  getAttributeWithObjectType<string>({
    product,
    group_name: "general",
    object_type: "product_name",
  });

export const getBrandName = (product: PIMProduct) =>
  getAttributeWithObjectType<string>({
    product,
    group_name: "general",
    object_type: "brand_name",
  });

export const usePortfolioUpdateHeight = (product: PIMProduct | undefined) => {
  const [triggerListHeightUpdate, setTriggerListHeightUpdate] =
    useState<number>();
  const [productListHeight, setProductListHeight] =
    useState<number | string>("fit-content");
  const heightRef = useRef<HTMLDivElement>(null);
  const { isLargeScreen } = useMediaQueries();

  useEffect(() => {
    let timeoutId = 0;
    if (heightRef.current && !isLargeScreen) {
      timeoutId = setTimeout(() => {
        setProductListHeight(heightRef?.current?.clientHeight ?? "fit-content");
      });
    } else {
      timeoutId = setTimeout(() => {
        setProductListHeight("fit-content");
      });
    }
    return () => clearTimeout(timeoutId);
  }, [isLargeScreen, triggerListHeightUpdate, product]);
  return { productListHeight, setTriggerListHeightUpdate, heightRef };
};
