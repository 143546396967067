import { zodResolver } from "@hookform/resolvers/zod";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect } from "react";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { endpoints } from "../../../../../endpoints";
import type {
  ListPostArgSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { useStoreState } from "../../../../../util/util";
import {
  ListItem,
  ListItemBase,
  ListItemName,
  ListItemWrapper,
} from "./ListItem";
import { z } from "zod";
import { EditableTitle } from "../../../../../components/EditableTitle/EditableTitle";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type { AxiosError, AxiosResponse } from "axios";
import Axios from "axios";
import type {
  DataMutate,
  UUID,
  WithPagination,
} from "../../../../../types/types";
import { CaretRight } from "../../../../../components/Icons/Icons";
import { useAuthContext } from "../../../../../components/Auth";
import { zodRequiredString } from "../../../../../util/zod.util";
import type { TFunction } from "i18next";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { StringParam, useQueryParams } from "use-query-params";

interface IListHomeProps {
  lists?: WithPagination<{ data: ShortListSchema[] }>;
  archivedLists?: WithPagination<{ data: ShortListSchema[] }>;
  listError: any;
  mutateList: DataMutate<PaginatedListSchema>;
  onSelected: (
    list: ShortListSchema | undefined,
    showArchived?: boolean
  ) => void;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
}

const ListHomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListHeader = styled(ListItemBase)`
  min-height: 58px;
  background-color: ${({ theme }) => theme.colors["gray-3"]};
  cursor: auto;
`;

export const ListFooter = styled(ListItemBase)`
  flex: 1;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: auto;
`;

export const IconContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
`;

const ListItemNameSchema = z.object({
  name: z.string(),
});

export const ListItemNameSchemaFn = (t: TFunction, maxLength?: number) =>
  z.object({
    name: zodRequiredString(t).max(
      maxLength ?? 25,
      t("Maximum {{chars}} characters allowed", { chars: maxLength ?? 25 })
    ),
  });

export type ListItemFormValue = z.infer<typeof ListItemNameSchema>;

export const ARCHIVED_UUID = "archived_name";

export const ListHome = forwardRef<HTMLDivElement, IListHomeProps>(
  (
    {
      lists,
      archivedLists,
      mutateList,
      onSelected,
      listError,
      setOffset,
      offset,
    }: IListHomeProps,
    ref
  ) => {
    const [selectedListID, setSelectedListID] = useState<UUID>();
    const [showAddList, setShowAddList] = useState(false);
    const perPage = 50;
    const [pagination, setPagination] = useState({
      perPage,
      pageCount: 0,
      pageIndex: 0,
    });
    const [query, setQuery] = useQueryParams({
      selectedListID: StringParam,
      selectedArchivedListID: StringParam,
    });
    const { t } = useTranslation();
    const addListRef =
      useRef<{ setEditMode: (editMode: boolean) => void; focus: () => void }>(
        null
      );
    const addListUseForm = useForm({
      resolver: zodResolver(ListItemNameSchemaFn(t)),
    });
    const { tenant_id } = useStoreState();
    const { notifySuccess, notifyError } = useContext(Notifications);
    const { hasPermission } = useAuthContext();

    const handleClick = useCallback(
      (item: ShortListSchema | undefined) => {
        setSelectedListID(item?.id);
        onSelected(item);
        setQuery({ selectedListID: undefined });
      },
      [onSelected, setQuery]
    );

    const handleAddList = () => {
      setShowAddList(true);
      handleClick(undefined);
      setTimeout(() => addListRef.current?.setEditMode(true));
    };

    const handleAddListConfirm = async ({
      name: newName,
    }: ListItemFormValue) => {
      try {
        const { data } = await Axios.post<
          ListPostArgSchema,
          AxiosResponse<ShortListSchema>
        >(endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id), {
          name: newName.trim(),
        });
        notifySuccess(t("List created successfully"));
        setShowAddList(false);
        await mutateList();
        handleClick(data);
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Could not add list. Something went wrong."),
          {
            error,
          }
        );
      }
    };

    const handleShowArchived = useCallback(() => {
      onSelected(undefined, true);
      setSelectedListID(ARCHIVED_UUID);
    }, [onSelected]);

    const isLoading = !lists && !listError;

    useEffect(() => {
      setPagination({
        perPage,
        pageCount: Math.ceil((lists?.pagination.total ?? 10) / perPage),
        pageIndex: (lists?.pagination.offset ?? 10) / perPage + 1,
      });
    }, [lists?.pagination.offset, lists?.pagination.total]);

    useEffect(() => {
      if (query.selectedArchivedListID) {
        handleShowArchived();
      }
      if (query.selectedListID && lists?.data) {
        const selectedList = lists.data.find(
          (list) => list.id === query.selectedListID
        );
        handleClick(selectedList);
      }
    }, [
      query.selectedListID,
      lists?.data,
      handleClick,
      query.selectedArchivedListID,
      handleShowArchived,
    ]);

    if (isLoading) {
      return <DelayedSpinner />;
    }

    if (listError) {
      return (
        <ErrorPlaceholder
          message={t(
            "There was an error fetching lists. Please try again later."
          )}
        />
      );
    }

    return (
      <ListHomeWrapper ref={ref}>
        <ListHeader>{t("Lists")}</ListHeader>
        {lists!.data.length > 0 &&
          lists!.data.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              active={selectedListID === item.id}
              selectItem={(reset) =>
                handleClick(reset === true ? undefined : item)
              }
              mutateList={mutateList}
            />
          ))}
        {archivedLists && archivedLists.pagination.total > 0 && (
          <ListItemWrapper
            onClick={handleShowArchived}
            active={selectedListID === ARCHIVED_UUID}
          >
            <ListItemName>{`${t("Archived")} (${
              archivedLists.pagination.total
            })`}</ListItemName>
            <IconContainer>
              <CaretRight />
            </IconContainer>
          </ListItemWrapper>
        )}
        {showAddList && (
          <ListItemWrapper style={{ padding: "16px" }}>
            <form
              id="add-list-form"
              noValidate
              onSubmit={addListUseForm.handleSubmit(handleAddListConfirm)}
            >
              <EditableTitle
                title={" "}
                formId="add-list-form"
                name="name"
                methodsOfUseForm={addListUseForm}
                ref={addListRef}
                onClose={() => setShowAddList(false)}
                fontSize="small"
                fontWeight="regular"
              />
            </form>
          </ListItemWrapper>
        )}
        {pagination.pageCount > 1 && (
          <ListItemBase style={{ height: "fit-content" }}>
            <Pagination
              pagination={pagination}
              offset={offset}
              handlePageClick={(offset) => setOffset(offset)}
            />
          </ListItemBase>
        )}
        <ListFooter>
          {hasPermission("modify_lists") && (
            <PrimaryButtonWithPlusIcon onClick={handleAddList}>
              <span style={{ fontSize: "15px" }}>{t("New List")}</span>
            </PrimaryButtonWithPlusIcon>
          )}
        </ListFooter>
      </ListHomeWrapper>
    );
  }
);
