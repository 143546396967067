import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStoreState } from "../../util/util";
import ReactGA from "react-ga4";
import { useAuthContext } from "../Auth";

export const initializeGA = (trackingId: string) => {
  ReactGA.initialize(trackingId, {
    gtagOptions: {
      send_page_view: false, // Prevent automatic page view tracking
    },
  });
};

/**
 * Initializes google analytics tracking, tracks when the route/page changes,
 * but only for guest users (not logged in).
 * Must be used inside a <Router /> so that `useLocation` will work.
 */
export const AnalyticsTracker = () => {
  const location = useLocation();
  const { storefront_metadata, analytics_allowed } = useStoreState();
  const { roleIsGuest } = useAuthContext();
  const { custom_domain, google_analytics_key } = storefront_metadata;

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    // This useEffect handles initializing analytics tracking.
    if (!initialized) {
      const shouldInitialize = (() => {
        if (analytics_allowed) {
          return true;
        }
        if (custom_domain && google_analytics_key && !roleIsGuest) {
          return true;
        } else return false;
      })();

      const key =
        custom_domain && google_analytics_key
          ? google_analytics_key
          : process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

      if (shouldInitialize && key) {
        initializeGA(key);
        setInitialized(true);
      }
    }
  }, [
    analytics_allowed,
    custom_domain,
    google_analytics_key,
    initialized,
    roleIsGuest,
  ]);

  useEffect(() => {
    // This useEffect handles tracking location changes. (It logs the initial
    // page load, without needing any special logic for that.)
    if (location) {
      const page = location.pathname + location.search + location.hash;

      if (initialized) {
        ReactGA.send({ hitType: "page_view", page });
      }
    }
  }, [initialized, location]);

  return <></>;
};
