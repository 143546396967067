import React from "react";

import type { DataMutate, Permission } from "../../../types/types";
import type { CollectionSchema, PIMProduct } from "../../../types/types.PIM";
import { AccessHomePage } from "./Access/AccessHomePage";
import { Activity } from "./Activity/Activity";
import { ProductAssets } from "./Assets/ProductAssetsTabs";
import { ProductOverview } from "./ProductOverview/ProductOverview";
import { CollectionComponent } from "./Collections/CollectionComponent";
import { MatchingCollectionsContainer } from "./Collections/MatchingCollectionsContainer";
import { ProductConnections } from "./Connections/Connections";
import { ProductGroupCard } from "./ProductOverview/ProductGroupCard";
import { Tab } from "../../../components/ReachTabs/Tab";
import { TabList } from "../../../components/ReachTabs/TabList";
import { TabPanels } from "@reach/tabs";
import { CustomTabPanel } from "../../../components/ReachTabs/TabPanel";
import { useAuthContext } from "../../../components/Auth";

export const ProductTabs = ({
  product,
  hasPermission,
  t,
  replaceProductId,
  mutate,
  isPortfolio = false,
  roleIsGuest = false,
  roleIsBuyer = false,
  refreshKey,
}: {
  product: PIMProduct;
  hasPermission: (p?: Permission | Permission[] | undefined) => boolean;
  t: (s: string) => string;
  replaceProductId: (id: string) => void;
  mutate: DataMutate<PIMProduct>;
  isPortfolio?: boolean;
  roleIsGuest?: boolean;
  roleIsBuyer?: boolean;
  refreshKey: any;
}) => {
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const isPropertiesCollection = (collection: CollectionSchema) =>
    collection.name.toLowerCase().includes("properties");

  const isSpecificationsCollection = (collection: CollectionSchema) =>
    collection.name.toLowerCase().includes("specifications");

  const isSKUs = (collection: CollectionSchema) =>
    collection.name.toLowerCase().includes("skus");

  const properties = product.product_schema.collections.filter(
    isPropertiesCollection
  );

  const specifications = product.product_schema.collections.filter(
    isSpecificationsCollection
  );

  const propertiesAndSpecifications = [...properties, ...specifications];

  const propertiesCollection = product.product_schema.collections.find(
    isPropertiesCollection
  );

  const specificationsCollection = product.product_schema.collections.find(
    isSpecificationsCollection
  );

  const portfolioPropertiesAndSpecifications = () => {
    let propsAndSpecs: CollectionSchema[] = [];
    if (
      propertiesCollection?.value_rows?.length &&
      propertiesCollection?.value_rows?.length > 0
    ) {
      propsAndSpecs.push(propertiesCollection);
    }
    if (
      specificationsCollection?.value_rows?.length &&
      specificationsCollection?.value_rows?.length > 0
    ) {
      propsAndSpecs.push(specificationsCollection);
    }
    return propsAndSpecs;
  };

  const skusCollection = product.product_schema.collections.find(isSKUs);

  const TabsList = [<Tab key="overview">{t("Overview")}</Tab>];
  const TabPanelList = [
    <CustomTabPanel key="overview" refreshKey={refreshKey}>
      <ProductOverview
        product={product}
        mutateProduct={mutate}
        replaceProductId={replaceProductId}
        isPortfolio={isPortfolio}
      />
    </CustomTabPanel>,
  ];

  if (
    (propertiesAndSpecifications.length > 0 &&
      isPortfolio &&
      ((propertiesCollection?.value_rows?.length &&
        propertiesCollection?.value_rows?.length > 0) ||
        (specificationsCollection?.value_rows?.length &&
          specificationsCollection?.value_rows?.length > 0))) ||
    (propertiesAndSpecifications.length > 0 && !isPortfolio)
  ) {
    TabsList.push(<Tab key="properties">{t("Properties")}</Tab>);
    TabPanelList.push(
      <CustomTabPanel key="properties" refreshKey={refreshKey}>
        <MatchingCollectionsContainer
          collections={
            roleIsSomeKindOfSeller && !isPortfolio
              ? propertiesAndSpecifications
              : portfolioPropertiesAndSpecifications()
          }
          product={product}
          fetchProductData={mutate}
          replaceProductId={replaceProductId}
          isPortfolio={isPortfolio}
        />
      </CustomTabPanel>
    );
  }

  if (!!skusCollection && !isPortfolio) {
    TabsList.push(<Tab key="skus">{t("SKUs")}</Tab>);
    TabPanelList.push(
      <CustomTabPanel key="skus" refreshKey={refreshKey}>
        <CollectionComponent
          collection={skusCollection}
          product={product}
          fetchProductData={mutate}
          replaceProductId={replaceProductId}
          isPortfolio={isPortfolio}
        />
      </CustomTabPanel>
    );
  }
  if (hasPermission("modify_products") && !isPortfolio) {
    TabsList.push(<Tab key="access">{t("Access")}</Tab>);
    TabPanelList.push(
      <CustomTabPanel key="access" refreshKey={refreshKey}>
        <AccessHomePage product={product} mutateProduct={mutate} />
      </CustomTabPanel>
    );
  }

  if (hasPermission("view_assets") || roleIsBuyer || roleIsGuest) {
    // sellers should see an empty table so that they can add to it. Otherwise
    // we are showing nothing for the public/buyer view.
    const roleIsNonSeller = roleIsGuest || roleIsBuyer;
    const hasAssetsOrExternalLinks =
      product.assets.length > 0 || product.asset_external_links.length > 0;
    // an external link is a type of asset, from the perspective of the UI.
    // Its a separate object for unknown reasons

    if (
      (roleIsNonSeller && hasAssetsOrExternalLinks) ||
      hasPermission("view_assets")
    ) {
      TabsList.push(<Tab key="documents">{t("Documents")}</Tab>);
      TabPanelList.push(
        <CustomTabPanel key="documents" refreshKey={refreshKey}>
          <ProductAssets
            product={product}
            fetchProductData={mutate}
            replaceProductId={replaceProductId}
            viewMode={isPortfolio}
          />
        </CustomTabPanel>
      );
    }
  }

  if (!isPortfolio) {
    TabsList.push(<Tab key="connections">{t("Connections")}</Tab>);
    TabPanelList.push(
      <CustomTabPanel key="connections" refreshKey={refreshKey}>
        <ProductConnections product={product} />
      </CustomTabPanel>
    );

    TabsList.push(<Tab key="activity">{t("Activity")}</Tab>);
    TabPanelList.push(
      <CustomTabPanel key="activity" refreshKey={refreshKey}>
        <div style={{ marginTop: "16px" }}>
          <Activity
            target_id={
              product.display_status === "staged" ||
              product.display_status === "unpublished_staged"
                ? product.published_product_id
                : product.id
            }
            mode="product"
          />
        </div>
      </CustomTabPanel>
    );

    const SEOGroup = product.product_schema.groups.find(
      (group) => group.name === "SEO"
    );
    if (SEOGroup) {
      TabsList.push(<Tab key="seo">{t("SEO")}</Tab>);
      TabPanelList.push(
        <CustomTabPanel key="seo" refreshKey={refreshKey}>
          <ProductGroupCard
            product={product}
            group={SEOGroup}
            key={"SEO"}
            mutateProduct={mutate}
            isPortfolio={false}
            replaceProductId={replaceProductId}
          />
        </CustomTabPanel>
      );
    }
  }

  return (
    <>
      <TabList>{TabsList}</TabList>
      <TabPanels>{TabPanelList}</TabPanels>
    </>
  );
};
