import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, RadioButtonContainer } from "../../../../../layout/FormLayout";
import {
  removeUnderscore,
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import type {
  DataMutate,
  FilterLabel,
  StorefrontFilterSetting,
  TranslationsSchema,
} from "../../../../../types/types";
import type {
  AttributeObjectType,
  AttributeSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { TextAreaCounter } from "../../../../../components/TextAreaCounter/TextAreaCounter";
import { SectionTitle } from "../../../../../components/Form/Form";
import type { FormValues } from "./CreateAttribute";
import {
  CreateAttributeSchemaFn,
  listToOptions,
  MarginBottomH6,
  MarginBottomHeaderLeft,
} from "./CreateAttribute";
import { DeletableChips } from "../../../../../components/DeletableChips/DeletableChips";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import noop from "lodash/noop";
import type { AttributeObjectFilterType } from "../../SellerAdminSettings/PortfolioControlsTab/util";
import { mapAttributeObjectToFilterType } from "../../SellerAdminSettings/PortfolioControlsTab/util";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";

export const EditAttribute = ({
  attribute,
  refreshAttribute,
  onComplete,
  list,
}: {
  attribute: AttributeSchema;
  refreshAttribute: DataMutate<
    AttributeSchema & { translations: TranslationsSchema[] }
  >;
  onComplete: () => void;
  list: ShortListSchema | undefined;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [productReferenceType, setProductReferenceType] = useState<string>(
    attribute.is_multiple_choice ? "multi" : "single"
  );
  const {
    slug: tenant_id,
    storefront_id,
    storefront_metadata: { default_language },
  } = useStoreState();
  const { data: typesResponse } = useSWR<{ data: string[] }>(
    endpoints.v2_pim_types()
  );
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateAttributeSchemaFn(t)),
    defaultValues: {
      name: attribute.name,
      display_name: attribute?.display_name ?? undefined,
      description: attribute?.description ?? undefined,
      input_type: {
        label: toTitleCase(removeUnderscore(attribute.input_type)),
        value: attribute.input_type,
      },
      list_id: {
        label: toTitleCase(list?.name ?? ""),
        value: attribute.list_id,
      },
    },
  });
  const { handleSubmit, register, formState, control, errors, watch } =
    methodsOfUseForm;

  const inputValue = watch("input_type");

  const showList = () =>
    (inputValue.value === "single_select" ||
      inputValue.value === "multi_select") &&
    list &&
    attribute.list_id;

  const isAttributeObjectFilterType = (objectType: AttributeObjectType) => {
    try {
      return Boolean(
        mapAttributeObjectToFilterType(objectType as AttributeObjectFilterType)
      );
    } catch (error) {
      return false;
    }
  };

  const updateFilterLabels = async (newLabel: string) => {
    const filterType = mapAttributeObjectToFilterType(
      attribute.object_type as AttributeObjectFilterType
    );
    const storefrontFilterSetting = await Axios.get<StorefrontFilterSetting[]>(
      endpoints.v1_storefronts_id_products_filters_settings(storefront_id)
    ).then(({ data }) =>
      data.find((setting) => setting.filter_type === filterType)
    );
    if (storefrontFilterSetting) {
      const { is_active, is_searchable } = storefrontFilterSetting;
      const updatedFilterLabels: FilterLabel[] =
        storefrontFilterSetting.filter_labels.map(({ label, language }) =>
          language !== default_language
            ? { label: "", language }
            : { label: newLabel, language }
        );
      await Axios.patch(
        endpoints.v1_storefronts_id_products_filters_settings_filterType(
          storefront_id,
          filterType
        ),
        {
          is_active,
          is_searchable,
          filter_labels: updatedFilterLabels,
          is_visible: true,
        }
      );
    }
  };

  const changeProductReferenceType = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    if (e.currentTarget.value) {
      setProductReferenceType(e.currentTarget.value);
    }
  };

  const onSubmit = async ({
    display_name,
    description,
  }: FormValues): Promise<void> => {
    setSubmitting(true);
    if (display_name) {
      display_name = display_name.trim();
    }
    try {
      await Axios.patch<
        FormValues,
        {
          display_name?: string;
          description?: string;
          is_multiple_choice?: boolean;
        }
      >(
        endpoints.v2_tenants_id_pim_attributes_id(tenant_id!!, attribute.id),
        attribute.input_type === "product_reference"
          ? {
              display_name,
              description,
              is_multiple_choice: productReferenceType === "multi",
            }
          : {
              display_name,
              description,
            }
      );
      if (display_name && isAttributeObjectFilterType(attribute.object_type)) {
        await updateFilterLabels(display_name);
        // always re-index after updating filter labels
        await Axios.post(
          endpoints.v1_storefronts_id_or_slug_reindex(storefront_id)
        );
      }
      await refreshAttribute();
      notifySuccess(t("Attribute successfully edited"));
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not edit attribute. Something went wrong."),
        {
          error,
        }
      );
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Edit Attribute")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Attribute Definition")}</MarginBottomH6>
        <TextField
          name="name"
          label={t("Attribute Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
          readOnly
        />
        <TextField
          name="display_name"
          label={t("Display Name")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextAreaCounter
          name="description"
          label={t("Short Description")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          watch={watch}
        />
        <MarginBottomH6>{t("Attribute Type")}</MarginBottomH6>
        {typesResponse && (
          <>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="input_type"
              autoComplete="on"
              placeholder={t("Attribute Type")}
              options={typesResponse.data.map((type) => ({
                label: toTitleCase(removeUnderscore(type)),
                value: type,
              }))}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
              isDisabled={true}
            />
            {inputValue.value === "product_reference" && (
              <RadioButtonContainer>
                <RadioButton
                  name="product_reference_type"
                  value="single"
                  checked={productReferenceType === "single"}
                  optionTitle={t("Single Reference")}
                  handleChange={changeProductReferenceType}
                />
                <RadioButton
                  name="product_reference_type"
                  value="multi"
                  checked={productReferenceType === "multi"}
                  optionTitle={t("Multi Reference")}
                  handleChange={changeProductReferenceType}
                />
              </RadioButtonContainer>
            )}
          </>
        )}
        {showList() && (
          <>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="list_id"
              autoComplete="on"
              placeholder={t("List")}
              options={list ? [listToOptions(list)] : []}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
              isDisabled={true}
            />
            {!!attribute.choices &&
              attribute.choices.length > 0 &&
              !!list?.parent && (
                <>
                  <ToggleSwitch
                    label={t("Use only specific values")}
                    theref={null}
                    onClick={noop}
                    name="specific_values"
                    isChecked={true}
                    disabled
                  />
                  <DeletableChips
                    chips={
                      Array.from(attribute.choices)?.map?.((choice) => ({
                        name: choice,
                        active: false,
                      })) ?? []
                    }
                    noneSelectedName="choices"
                    handleClick={noop}
                  />
                </>
              )}
          </>
        )}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
