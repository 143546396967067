import type { MouseEvent, ChangeEvent } from "react";
import React, { useEffect, useState, useContext } from "react";
import { DropDown } from "../../../components/DropDown/DropDown";
import { Pagination } from "../../../components/Pagination/Pagination";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { IDMobile } from "../../../components/IDs/IDs";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import { Table } from "../../../components/Table/Table";
import { useDebounce } from "../../../util/hooks";
import type {
  IQuotesAPIResponse,
  IQuoteRequest,
  StatusAndId,
  QuoteStatus,
  User,
  QuoteRequestItem,
} from "../../../types/types";
import {
  ContentWrapper,
  FiltersWrapper,
  HorizontalButtonsContainer,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import {
  SellerQuoteStatusFilters,
  getQuoteProductsAsText,
  SellerQuoteStatus,
  getQuoteItemsList,
} from "../../../components/QuoteStatus";
import {
  convertStringArrayToObj,
  formatDateTime,
  rowHover,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { useRoutePath } from "../../../util/Routing";
import { Auth } from "../../../components/Auth";
import ReactTooltip from "react-tooltip";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  PrimaryButtonWithPlusIcon,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { SellerCreateQuote } from "../../Seller/SellerCreateQuote/SellerCreateQuote";
import { Modal } from "../../../components/Modal/Modal";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { calculatePriceDetails } from "../../../components/PriceDetails/PriceDetails";
import {
  TooltipCell,
  constructQuery,
  update_status_string_list,
} from "../../Buyer/BuyerOrders/BuyerOrders.util";
import { DownloadReport } from "../SellerOrdersListPage/DownloadReport/DownloadReport";
import { SlideOut } from "../../../components/SlideOut/SlideOut";

const localStrings = (t: TFunction) => ({
  pageTitle: t("Quotes"),
  sellerSearchBarPlaceholder: t("Search by Product, Customers or Quote ID"),
  buyerSearchBarPlaceholder: t("Search by Product or Quote ID"),
  status: t("Status"),
  perPage: t("Per Page"),
});

type QuotesTable = {
  id: string;
  products: { items: QuoteRequestItem[]; id: string };
  from: string;
  total_value: string;
  last_activity: string;
  status: StatusAndId<QuoteStatus>;
  unread: boolean;
};

/**
 * Shared page component for the seller "Quotes" page.
 * The page displays a list of quote requests.
 */
export const SellerQuotesListPage = () => {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50, 100];
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [showCreateQuoteModal, setShowCreateQuoteModal] = useState(false);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );
  const [downloadReportSlideOut, setDownloadReportSlideOut] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(Auth);
  const { storefront_id } = useStoreState();
  const { notifications } = useInAppNotifications(storefront_id, user as User);
  const { accountPath } = useRoutePath();

  const {
    data: quotesResponse,
    error: quotesError,
    mutate: mutateQuotesList,
  } = useSWR(
    constructQuery({
      baseUrl: `/v1/storefronts/${storefront_id}/quotes?`,
      query: debouncedSearchQuery,
      status: update_status_string_list(selectedStatusList),
      offset,
      perPage,
    })
  );

  const isLoading = !quotesResponse && !quotesError;
  const [tableData, setTableData] = useState<QuotesTable[]>([]);
  /**
   * Schema change for AD-697:
   * 1. Add tooltip to the products column for quotes list
   * 2. Add tooltip to all instances where there is ellipsis hiding text
   */
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Quote ID"),
        accessor: "number",
        Cell: ({ value }: { value: string }) => (
          <IDMobile>{`${value}`}</IDMobile>
        ),
      },
      {
        Header: t("Product(s)"),
        accessor: "products",
        Cell: ({
          value,
        }: {
          value: { items: QuoteRequestItem[]; id: string };
        }) => {
          return (
            <TooltipCell data-for={`${value.id}-tooltip`} data-tip={""}>
              {getQuoteProductsAsText(value.items, t)}
              <ReactTooltip
                id={`${value.id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                backgroundColor="#60676F"
                multiline={true}
              >
                {getQuoteItemsList(value.items)}
              </ReactTooltip>
            </TooltipCell>
          );
        },
      },
      {
        Header: t("From"),
        accessor: "from",
      },
      {
        Header: t("Total Value"),
        accessor: "total_value",
      },
      {
        Header: t("Last Activity"),
        accessor: "last_activity",
      },
      {
        Header: t("Status"),
        accessor: "status",
        width: 50,
        minWidth: 50,
        align: "right",
        Cell: ({ value }: { value: StatusAndId<QuoteStatus> }) => (
          <SellerQuoteStatus status={value.status} id={value.id} />
        ),
      },
    ],
    [t]
  );

  const getSearchPlaceholder = () => {
    if (user?.rbac_role === "seller_admin") {
      return localStrings(t).sellerSearchBarPlaceholder;
    }
    return localStrings(t).buyerSearchBarPlaceholder;
  };

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  useEffect(() => {
    setQuery({ offset, perPage, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      SellerQuoteStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    const getUnread = (quote: IQuoteRequest): boolean => {
      return notifications?.quotes?.ids
        ? notifications?.quotes?.ids?.includes(quote?.id)
        : false;
    };

    const handleQuotesData = ({ data, pagination }: IQuotesAPIResponse) => {
      setTableData(
        data.map((quote: IQuoteRequest) => ({
          id: quote.id,
          number: quote.number,
          products: { items: quote.items, id: quote.id },
          from: quote.buyer_name || "--",
          total_value: quote.items.length
            ? (() => {
                const { totalString } = calculatePriceDetails({
                  items: quote.items,
                  fees: quote.fees,
                  // It should be impossible for quote items to have different
                  // currencies. This is just a weird API design thing.
                  currencyCode: quote.items[0].currency,
                });

                return totalString;
              })()
            : "-/-",
          last_activity: formatDateTime(quote.modified_at) || "--",
          status: { status: quote.status || "--", id: quote.id },
          unread: getUnread(quote),
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (quotesResponse) {
      const { data: quotes, pagination } = quotesResponse;
      handleQuotesData({ data: quotes, pagination });
    }
  }, [quotesResponse, setTableData, perPage, notifications]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const closeDownloadReportSlideout = () => setDownloadReportSlideOut(false);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (e: MouseEvent) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
      (status) => params.append("status", status)
    );
    history.push(`${accountPath}/quotes/${e.currentTarget.id}?${params}`);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings(t).pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={getSearchPlaceholder()}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        <HorizontalButtonsContainer>
          <SecondaryButtonMedium
            onClick={() => setDownloadReportSlideOut(true)}
          >
            {t("Download Report")}
          </SecondaryButtonMedium>
          <PrimaryButtonWithPlusIcon
            onClick={() => setShowCreateQuoteModal(true)}
            data-alignright
          >
            {t("Create New")}
          </PrimaryButtonWithPlusIcon>
        </HorizontalButtonsContainer>
      </PageHeader>
      <Modal
        show={showCreateQuoteModal}
        closeModal={() => setShowDialogBox(true)}
        modalWidth={"95%"}
      >
        <SellerCreateQuote
          onSuccess={() => {
            setShowCreateQuoteModal(false);
            mutateQuotesList();
          }}
        />
      </Modal>
      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={SellerQuoteStatusFilters(t)}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          showIcon
          hideBorder
          textLeft={t("items") + ":"}
          textRight={localStrings(t).perPage}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={quotesError}
          rowClick={handleRowClick}
          rowHover={rowHover}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        ></Pagination>
        <SlideOut
          closeFlyout={closeDownloadReportSlideout}
          show={downloadReportSlideOut}
        >
          <DownloadReport
            closeSlideout={closeDownloadReportSlideout}
            transactionType={"quote"}
          />
        </SlideOut>
        <ConfirmDialog
          show={showDialogBox}
          confirmMessage={t("Are you sure you want to cancel this quote?")}
          closeDialog={() => setShowDialogBox(false)}
          handleConfirm={() => {
            setShowCreateQuoteModal(false);
            setShowDialogBox(false);
          }}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};
