import type { Dispatch, SetStateAction } from "react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  PageWrapper,
  PageHeader,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../layout/portalPageLayout";
import type { SEODetail } from "../../../types/types";

import { ErrorPlaceholder } from "../../../components/Error";
import type { ErrorWithStatus } from "../../../util/util";

import { useTranslation } from "react-i18next";
import type { PIMProduct } from "../../../types/types.PIM";
import { Auth } from "../../../components/Auth";
import { ProductTabs } from "../../SharedPages/ProductDetailPage/util";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import type { ProductMutate } from "../../SharedPages/ProductDetailPage/ProductDetailPage";
import { ProductDetailsTransactionsSection } from "./ProductDetailsTransactionsButtons";
import { Tabs } from "@reach/tabs";
import { Modal } from "../../../components/Modal/Modal";

const ProductHeader = styled(PageHeader)`
  align-items: flex-start;
  justify-content: flex-start;
`;

const ProductHeaderImage = styled.img`
  min-width: 100px;
  max-width: 200px;
  max-height: 150px;
  margin-right: 15px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  padding: 1px;
  align-self: center;
  cursor: pointer;
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0;
  h1 {
    margin: 0px 5px 2px 0;
  }
`;

const ModalWrapper = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProductDetails {
  product: PIMProduct | undefined;
  mutateProduct: ProductMutate;
  error: ErrorWithStatus | undefined;
  updateListHeight?: Dispatch<SetStateAction<number | undefined>>;
  setbackToProductList?: Dispatch<SetStateAction<boolean>>;
}
/**
 * Portfolio Product detail page
 */
export function PIMProductDetailsPortfolio({
  product,
  mutateProduct,
  error,
  updateListHeight,
  setbackToProductList,
}: IProductDetails) {
  const { hasPermission, roleIsGuest, roleIsSomeKindOfBuyer } =
    useContext(Auth);

  const { t } = useTranslation();
  const [showProductImageModal, setShowProductImageModal] = useState(false);
  const [fullSizeProductImage, setFullSizeProductImage] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    // we don't know the length of the tabs that the buyer will see, so when the
    // product changes reset to the first tab, because there will always be 1.
    if (product) {
      setCurrentTab(0);
    }
  }, [product]);

  const changeTab = (index: number) => {
    if (updateListHeight) {
      updateListHeight(index);
    }
    setCurrentTab(index);
  };

  if (!product) {
    // Product data is still loading.
    return <DelayedSpinner />;
  }

  const productCoverImage = product.assets.find(
    ({ is_cover_image }) => is_cover_image
  )?.signed_url;

  const seoDetails = (): SEODetail => {
    const seoGroupSummary = product.product_schema.groups.find(
      (group) => group.name.toLowerCase() === "seo"
    );

    const seoDataType: Record<string, string> = {};

    seoGroupSummary?.attributes?.forEach(
      ({ object_type, values }) =>
        (seoDataType[object_type] = (values?.[0]?.value as string) ?? "")
    );

    return {
      meta_description: seoDataType?.seo_meta_description ?? null,
      meta_keywords: seoDataType?.seo_meta_keywords ?? null,
      meta_title: seoDataType?.seo_meta_title ?? null,
      page_title: seoDataType?.seo_page_title ?? null,
      og_title: seoDataType?.seo_og_title ?? null,
      og_type: seoDataType?.seo_og_type ?? null,
      og_image: seoDataType?.seo_og_image ?? null,
      og_description: seoDataType?.seo_og_description ?? null,
      og_locale: seoDataType?.seo_og_locale ?? null,
      og_url: seoDataType?.seo_og_url ?? null,
    };
  };

  if (error) {
    return (
      <ErrorPlaceholder
        message={error.message ?? t("There was an error loading the product.")}
      />
    );
  }

  return (
    <PageWrapper style={{ position: "relative" }}>
      {seoDetails() && (
        <SEOHelmet
          seo={seoDetails()}
          extraProps={{
            slug: product.name,
            image_url: product.image_url,
          }}
        />
      )}
      <ProductHeader>
        {productCoverImage && (
          <ProductHeaderImage
            src={productCoverImage}
            alt={product.name}
            onClick={() => setShowProductImageModal(true)}
          />
        )}
        <ProductHeaderContainer>
          <PageTitle>{product.name}</PageTitle>
          <ProductDetailsTransactionsSection
            product={product}
            setbackToProductList={setbackToProductList}
          />
        </ProductHeaderContainer>
      </ProductHeader>

      <FullWidthHorizontalSeparator margin={"56px 0 0"} />
      {product && (
        <Tabs
          onChange={changeTab}
          style={{ marginTop: "8px" }}
          index={currentTab}
          key={product.id}
          defaultIndex={0}
        >
          {ProductTabs({
            product,
            t,
            refreshKey: currentTab,
            mutate: mutateProduct,
            hasPermission,
            replaceProductId: () => Promise.resolve(),
            isPortfolio: true,
            roleIsGuest,
            roleIsBuyer: roleIsSomeKindOfBuyer,
          })}
        </Tabs>
      )}

      {showProductImageModal && productCoverImage && (
        <Modal
          show={showProductImageModal}
          overlay={true}
          closeModal={() => setShowProductImageModal(false)}
          modalWidth="100%"
        >
          <ModalWrapper>
            <img
              src={productCoverImage}
              alt={product.name}
              style={{
                maxWidth: fullSizeProductImage ? "auto" : "100%",
                maxHeight: fullSizeProductImage ? "auto" : "100%",
                cursor: fullSizeProductImage ? "zoom-out" : "zoom-in",
              }}
              onClick={() => setFullSizeProductImage((current) => !current)}
            />
          </ModalWrapper>
        </Modal>
      )}
    </PageWrapper>
  );
}
