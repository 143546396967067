import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components/macro";
import { useLocation, Link } from "react-router-dom";
import { Nav } from "./shared/Nav";
import {
  AppWrapper,
  AppHeader,
  SidePanel,
  MainWrapper,
  PageWrapper,
  Logo,
  LogoWrapper,
  SideNavFooterWrapper,
  AppVersionInfo,
  ContentLogoWrapper,
  SideNavFooterIcon,
} from "../layout/portalLayout";
import { UserAreaContainer } from "../layout/publicLayout";
import { UserArea } from "./shared/UserArea";
import { Auth, useAuthContext } from "../components/Auth";
import type { LayoutComponentProps } from "../types/types";
import { CartWidget } from "../components/quoteCart/CartWidget";
import { useMediaQueries, useStoreState } from "../util/util";
import { useRoutePath } from "../util/Routing";
import { InvisibleButton } from "../components/Buttons/Buttons";
import {
  IonicPIMLogo,
  MenuIcon,
  SettingsIcon,
} from "../components/Icons/Icons";
import { Store } from "../Store";
import { useTranslation } from "react-i18next";
import {
  ClickableLink,
  ClickableLinkOverflow,
  H6,
} from "../components/Typography/Typography";
import { InfoIcon } from "../components/Icons/Icons";
import ReactTooltip from "react-tooltip";
import { DocumentationArea } from "./shared/DocumentationArea/DocumentationArea";
import { PrivacyPolicyLink } from "../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { CookiePolicyModal } from "../components/CookiePolicyModal/CookiePolicyModal";
import { ErrorBoundary } from "../components/ErrorBoundary";
import styled from "styled-components";

const H6Footer = styled(H6)`
  margin-bottom: 8.5px;
  color: ${({ theme }) => theme.navLinkColor};
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  display: "block";
  width: "100%";
`;

/**
 * Provides the headers, footers, basic page layout, etc. for portal pages.
 */
export const LayoutPortal = ({ children, routes }: LayoutComponentProps) => {
  const location = useLocation();
  const { roleIsSomeKindOfBuyer, roleIsSomeKindOfSeller } = useContext(Auth);
  const theme = useTheme();
  const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
  const { adminPath, storePath } = useRoutePath();
  const {
    api_metadata,
    collapsed_nav,
    storefront_metadata: {
      route_configuration,
      sample_requests_visibility,
      quote_requests_visibility,
      custom_support_url,
    },
    edition,
  } = useStoreState();
  const { storeDispatch } = useContext(Store);
  const { t } = useTranslation();
  const navLayout =
    location.pathname.indexOf(adminPath) === 0 ? "admin" : "portal";
  const { isLargeScreen } = useMediaQueries();
  const navRoutes = routes.filter((route) => route.nav?.[navLayout]);
  const { roleIsSellerAdmin } = useAuthContext();
  const getSupportLinkActive = () => {
    return `${storePath}/support` === location.pathname;
  };
  useEffect(() => {
    if (isLargeScreen) {
      storeDispatch({
        type: "SET_COLLAPSED_NAV",
        payload: true,
      });
    }
  }, [isLargeScreen, storeDispatch]);

  // Check if orders and samples are disabled for Valtris
  const isCartDisabled = () => {
    const isVisibilityDisabled =
      sample_requests_visibility === "disabled" &&
      quote_requests_visibility === "disabled";
    const isVisibilityHidden =
      sample_requests_visibility === "hidden" &&
      quote_requests_visibility === "hidden";
    return (
      isVisibilityHidden || (isVisibilityDisabled && !roleIsSomeKindOfBuyer)
    );
  };

  return (
    <>
      <AppWrapper>
        <SidePanel collapsedSideNav={collapsed_nav}>
          <LogoWrapper collapsedSideNav={collapsed_nav}>
            {!collapsed_nav && theme.portalLogoUrl && (
              <Logo src={theme.portalLogoUrl} />
            )}
            <InvisibleButton
              onClick={() =>
                storeDispatch({
                  type: "SET_COLLAPSED_NAV",
                  payload: !collapsed_nav,
                })
              }
            >
              <MenuIcon fill={theme.primaryButtonTextColor} />
            </InvisibleButton>
          </LogoWrapper>
          <Nav
            navRoutes={navRoutes}
            navLayout={navLayout}
            collapsedSideNav={collapsed_nav}
          />
          {!collapsed_nav ? (
            <SideNavFooterWrapper active={getSupportLinkActive()}>
              {edition === "pim" ? (
                <div style={{ verticalAlign: "middle", marginBottom: "10px" }}>
                  <a
                    href={"https://ionicpim.com"}
                    style={{ all: "unset", cursor: "pointer" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IonicPIMLogo />
                  </a>
                </div>
              ) : (
                <a
                  href={"https://www.agiliscommerce.com"}
                  style={{
                    all: "unset",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://cdn.agilischemicals.com/assets/Agilis_Logo_rainbow.svg"
                    width="30"
                    style={{ verticalAlign: "middle", marginBottom: "3px" }}
                    alt="Agilis Logo"
                    title={
                      api_metadata ? `${api_metadata.release_version}` : ""
                    }
                  />
                </a>
              )}

              {roleIsSellerAdmin && (
                <Link to={`${adminPath}/settings`}>
                  <H6Footer
                    data-for="support-icon-tip"
                    data-tip={t("Settings").length > 19 ? t("Settings") : ""}
                  >
                    {t("Settings")}
                  </H6Footer>
                </Link>
              )}
              {custom_support_url ? (
                <ClickableLink href={custom_support_url} target="_blank">
                  <H6Footer
                    data-for="support-icon-tip"
                    data-tip={t("Support").length > 19 ? t("Support") : ""}
                  >
                    {t("Support")}
                  </H6Footer>
                </ClickableLink>
              ) : (
                <Link to={`${storePath}/support`}>
                  <H6Footer
                    data-for="support-icon-tip"
                    data-tip={t("Support").length > 19 ? t("Support") : ""}
                  >
                    {t("Support")}
                  </H6Footer>
                </Link>
              )}
              <PrivacyPolicyLink
                text={t("Privacy Policy")}
                agilisPolicy
                can_overflow={true}
              />
              <ClickableLinkOverflow
                data-for="support-icon-tip"
                data-tip={
                  t("Cookie Preferences").length > 27
                    ? t("Cookie Preferences")
                    : ""
                }
                onClick={() => setShowCookiePolicyModal(true)}
              >
                {t("Cookie Preferences")}
              </ClickableLinkOverflow>
              {api_metadata?.release_version && (
                <AppVersionInfo>
                  <span>{api_metadata.release_version}</span>
                </AppVersionInfo>
              )}

              <CookiePolicyModal
                withdrawMode
                show={showCookiePolicyModal}
                closeModal={() => setShowCookiePolicyModal(false)}
              />
              <ReactTooltip
                id="support-icon-tip"
                place="right"
                data-html={true}
                effect="solid"
                offset={{ left: 6 }}
                backgroundColor={theme.tooltipBG}
              />
            </SideNavFooterWrapper>
          ) : (
            <div style={{ marginBottom: "15px" }}>
              {roleIsSellerAdmin && (
                <SideNavFooterIcon
                  active={getSupportLinkActive()}
                  data-tip={t("Settings")}
                  data-for="support-icon-tip"
                >
                  <Link to={`${adminPath}/settings`}>
                    <SettingsIcon fill={theme.primaryTextColor} />
                  </Link>
                </SideNavFooterIcon>
              )}
              <SideNavFooterIcon
                active={getSupportLinkActive()}
                data-tip={t("Support")}
                data-for="support-icon-tip"
              >
                {custom_support_url ? (
                  <ClickableLink href={custom_support_url} target="_blank">
                    <InfoIcon fill={theme.primaryTextColor} />
                  </ClickableLink>
                ) : (
                  <Link to={`${storePath}/support`}>
                    <InfoIcon fill={theme.primaryTextColor} />
                  </Link>
                )}
              </SideNavFooterIcon>
              <ReactTooltip
                id="support-icon-tip"
                place="right"
                data-html={true}
                effect="solid"
                offset={{ left: 6 }}
                backgroundColor={theme.tooltipBG}
              />
            </div>
          )}
        </SidePanel>
        <MainWrapper>
          <AppHeader collapsedSideNav={collapsed_nav}>
            {collapsed_nav && theme.portalLogoUrl && (
              <ContentLogoWrapper>
                <Logo src={theme.publicLogoUrl} />
              </ContentLogoWrapper>
            )}
            <UserAreaContainer>
              {roleIsSomeKindOfSeller && <DocumentationArea />}
              {/* if user is buyer show cart */}
              {/* we can show the cart here because its inside login, don't need the portfolio for this one. */}
              {!isCartDisabled() && roleIsSomeKindOfBuyer && <CartWidget />}
              {/* Don't show link to storefront if buyer */}
              {!roleIsSomeKindOfBuyer && route_configuration.portfolio && (
                <Link to={storePath}>{t("Storefront")}</Link>
              )}
              <UserArea />
            </UserAreaContainer>
          </AppHeader>
          <ErrorBoundary>
            <PageWrapper>{children}</PageWrapper>
          </ErrorBoundary>
        </MainWrapper>
      </AppWrapper>
    </>
  );
};
