import Split from "react-split";
import {
  ContentWrapper,
  PortfolioContentLayout,
  ProductContentWrapper,
  ProductListItemWrapper,
  ProductListMainWrapper,
  SelectedProductListItemWrapper,
} from "../../../layout/publicPageLayout";
import { Loader } from "../../../components/Loader/Loader";
import type {
  PIMProduct,
  PIMProductPaginatedOutput,
} from "../../../types/types.PIM";
import { Fragment, useEffect, useState } from "react";
import type { SetStateAction } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ConditionalWrapper,
  useMediaQueries,
  useStoreState,
} from "../../../util/util";
import {
  CasNumber,
  CustomH3,
  CustomProductName,
  LoaderContainer,
  PAGE_SIZE,
  TotalProducts,
  getBrandName,
  getProductName,
  usePortfolioUpdateHeight,
} from "../../public/pages.public.util";
import { SoftHeader2 } from "../../../components/Typography/Typography";
import { ErrorPlaceholder } from "../../../components/Error";
import { PIMProductDetailsPortfolio } from "../../public/ProductDetailsPortfolio/PIMProductDetailsPortfolio";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { endpoints } from "../../../endpoints";

interface IProductsPortfolioView {
  productsResponse: PIMProductPaginatedOutput[] | undefined;
  productsError: AxiosError<any>;
  size: number;
  setSize: (
    size: number | ((size: number) => number)
  ) => Promise<PIMProductPaginatedOutput[] | undefined>;
  resetFirstProduct: boolean;
  setResetFirstProduct: (value: SetStateAction<boolean>) => void;
}

export const ProductsPortfolioView = ({
  productsResponse,
  productsError,
  size,
  setSize,
  resetFirstProduct,
  setResetFirstProduct,
}: IProductsPortfolioView) => {
  const [product, setProduct] = useState<PIMProduct | undefined>();
  const { isLargeScreen } = useMediaQueries();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const location = useLocation();
  const history = useHistory();

  const replaceProductSlug = ({
    path,
    search,
    hash,
    slug,
  }: {
    path: string;
    search: string;
    hash: string;
    slug: string;
  }): string => {
    const pathParts = path.split("/");
    if (pathParts[6] === undefined) {
      // page has been loaded with just the tenant slug and no product is

      const newPathParts = pathParts.map((part, index) =>
        index === 5 ? `products/${slug}` : part
      );

      return newPathParts.join("/") + search + hash;
    } else {
      // initial page load has been completed or there was already a
      // product in the URL.
      const baseUrl = [...pathParts.slice(0, 6), slug].join("/");
      return baseUrl + search + hash;
    }
  };

  let { productSlug } =
    useParams<{
      productSlug?: string;
    }>();
  useSWR<PIMProduct>(
    productSlug
      ? endpoints.v2_storefronts_id_pim_products_id(storefront_id, productSlug)
      : null,
    {
      onSuccess: (product) => {
        setProduct(product);
        setResetFirstProduct(false);
      },
      revalidateOnFocus: false,
    }
  );

  const products = productsResponse ? [...productsResponse] : [];

  const hasProducts = !productsError && products;
  const isLoadingInitialData = !productsResponse && !productsError;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      productsResponse &&
      typeof productsResponse[size - 1] === "undefined");

  const handleProductClick = (clickedProduct: PIMProduct) => {
    setProduct(clickedProduct);
    const destination = replaceProductSlug({
      path: location.pathname,
      search: location.search,
      hash: location.hash,
      slug: clickedProduct.slug,
    });
    history.replace(destination);
  };

  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });

  const { productListHeight, setTriggerListHeightUpdate, heightRef } =
    usePortfolioUpdateHeight(product);

  useEffect(() => {
    // If the observer is the viewport update size of documents to fetched
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);

  useEffect(() => {
    if (
      (!product && productsResponse && productsResponse[0].data.length > 0) ||
      (productsResponse &&
        productsResponse[0].data.length > 0 &&
        resetFirstProduct)
    ) {
      setProduct(productsResponse[0].data[0]);
      const destination = replaceProductSlug({
        path: location.pathname,
        search: location.search,
        hash: location.hash,
        slug: productsResponse[0]?.data[0]?.slug,
      });
      setResetFirstProduct(false);
      history.replace(destination);
    }
    if (productsResponse && productsResponse[0].data.length === 0) {
      setProduct(undefined);
    }
  }, [
    product,
    productsResponse,
    history,
    location,
    resetFirstProduct,
    setResetFirstProduct,
  ]);

  return (
    <ContentWrapper>
      <TotalProducts data-testid="portfolio-total-products">
        {!isLoadingInitialData &&
          products &&
          products.length &&
          `${products[0].pagination.total} ${t("products")}`}
      </TotalProducts>
      <PortfolioContentLayout>
        <Split
          sizes={[25, 75]}
          minSize={220}
          expandToMin={false}
          gutterSize={7}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction={"horizontal"}
          cursor="col-resize"
          style={{
            display: "flex",
            flexDirection: isLargeScreen ? "column" : "row",
            width: "100%",
          }}
        >
          <ProductListMainWrapper
            data-testid="product-list-container"
            style={{
              height: productListHeight,
            }}
          >
            {isLoadingInitialData && (
              <LoaderContainer>
                <Loader isLoading={isLoadingInitialData} omitContainer={true} />
              </LoaderContainer>
            )}
            {hasProducts &&
              products.map((nested) =>
                nested.data.map((listProduct: PIMProduct, index: number) => (
                  <Fragment key={listProduct.id}>
                    {/* Conditionally wrap the items in selected/unselected styled components */}
                    <ConditionalWrapper
                      condition={listProduct.id === product?.id}
                      wrapper={(children) => (
                        <SelectedProductListItemWrapper as="div">
                          {children}
                        </SelectedProductListItemWrapper>
                      )}
                      falseWrapper={(children) => (
                        <ProductListItemWrapper
                          onClick={() => handleProductClick(listProduct)}
                        >
                          {children}
                        </ProductListItemWrapper>
                      )}
                    >
                      <CustomH3
                        title={getProductName(listProduct) ?? undefined}
                      >
                        {getProductName(listProduct)}
                      </CustomH3>
                      {!!getBrandName(listProduct) && (
                        <SoftHeader2>
                          <CustomProductName
                            title={getBrandName(listProduct) ?? undefined}
                          >
                            {getBrandName(listProduct)}
                          </CustomProductName>
                        </SoftHeader2>
                      )}
                      {!!listProduct.identifiers.cas_number && (
                        <SoftHeader2>
                          <CasNumber title={listProduct.identifiers.cas_number}>
                            {`CAS # ${listProduct.identifiers.cas_number}`}
                          </CasNumber>
                        </SoftHeader2>
                      )}
                    </ConditionalWrapper>
                    {/* If we get to the end and the request hasn't finished yet display spinner */}
                    {!!isLoadingMore && index === nested.data.length - 1 && (
                      <LoaderContainer>
                        <Loader
                          isLoading={!!isLoadingMore}
                          omitContainer={true}
                        />
                      </LoaderContainer>
                    )}
                    {/* Display intersection observer ref when we get towards the end.
            We fetch products in multiples of PAGE_SIZE so if the length of this array
            is < PAGE_SIZE we don't need to show the observer ref.
            This is set to PAGE_SIZE - 4 so that unless the user is scrolling quickly more 
            products will load before hitting bottom
          */}
                    {index === PAGE_SIZE - 4 && (
                      <div key={listProduct.id + "observer"} ref={inViewRef} />
                    )}
                  </Fragment>
                ))
              )}
          </ProductListMainWrapper>
          <ProductContentWrapper ref={heightRef}>
            {products && products[0]?.data?.length === 0 && (
              <ErrorPlaceholder message={t("No products found")} />
            )}
            {!products && productsError && (
              <ErrorPlaceholder
                message={t(
                  "There was an error fetching products for this storefront"
                )}
              />
            )}
            {product && (
              <PIMProductDetailsPortfolio
                product={product}
                mutateProduct={() => Promise.resolve(product)}
                updateListHeight={setTriggerListHeightUpdate}
                error={undefined}
              />
            )}
          </ProductContentWrapper>
        </Split>
      </PortfolioContentLayout>
    </ContentWrapper>
  );
};
