import { memo } from "react";
import { SellerAdminOrganization } from "./pages/admin/SellerAdmin/SellerAdminOrganization/SellerAdminOrganization";
import { PublicIndexWrapper } from "./pages/public";
import { pageForEdition } from "./util/util";
import { Login } from "./pages/Misc/Login";
import { BuyerOrders } from "./pages/Buyer/BuyerOrders";
import { SellerOrdersList } from "./pages/Seller/SellerOrdersList/SellerOrdersList";
import { SellerAdminCustomersList } from "./pages/admin/SellerAdmin/SellerAdminCustomersList/SellerAdminCustomersList";
import { SellerAdminLeadsList } from "./pages/admin/SellerAdmin/SellerAdminLeadsList";
import { SellerAdminLeadDetail } from "./pages/admin/SellerAdmin/SellerAdminLeadDetail";
import { SellerAdminProductDetail } from "./pages/admin/SellerAdmin/SellerAdminProductDetail/SellerAdminProductDetail";
import { SellerAdminCustomerDetail } from "./pages/admin/SellerAdmin/SellerAdminCustomerDetail/SellerAdminCustomerDetail";
import { SellerAdminUserDetail } from "./pages/admin/SellerAdmin/SellerAdminUserDetail";
import {
  LeadsIcon,
  OrderIcon,
  HomeIcon,
  QuoteIcon,
  StackIcon,
  UsersIcon,
  OpportunitiesIcon,
  AnalyticsIcon,
  SoldByIcon,
  LabFlaskIcon,
  CompanyIcon,
  ProductsV2Icon,
  AttributesNavIcon,
  SettingsIcon,
  TemplatesNavIcon,
  ListsIcon,
  AssetsIcon,
  RolesUserIcon,
  PersonalCollectionIcon,
  DistributorsIcon,
  DashboardIcon,
  TeamsIcon,
} from "./components/Icons/Icons";
import { PageProvider } from "./PageProvider";
import { SellerAdminSettings } from "./pages/admin/SellerAdmin/SellerAdminSettings/SellerAdminSettings";
import { ActivateAccount } from "./pages/Misc/ActivateAccount";
import { UserPreferences } from "./pages/Misc/UserPreferences";
import { ContactUsPage } from "./pages/public/ContactUsPage/ContactUsPage";
import { Registration } from "./pages/public/Registration/Registration";
import { LayoutPortal } from "./layout/LayoutPortal";
import { LayoutPublic } from "./layout/LayoutPublic";
import { BuyerQuotesList } from "./pages/Buyer/BuyerQuotesList";
import { SellerQuotesList } from "./pages/Seller/SellerQuotesList/SellerQuotesList";
import { BuyerQuoteDetail } from "./pages/Buyer/BuyerQuoteDetail/BuyerQuoteDetail";
import { BuyerAdminOrganization } from "./pages/admin/BuyerAdmin/BuyerAdminOrganization/BuyerAdminOrganization";
import { NotSupportedForEdition } from "./pages/Misc/NotSupportedForEdition";
import { SellerQuoteDetail } from "./pages/Seller/SellerQuoteDetail/SellerQuoteDetail";
import { BuyerOrderDetailWrapper } from "./pages/Buyer/BuyerOrderDetail/BuyerOrderDetail";
import { SellerOrderDetail } from "./pages/Seller/SellerOrderDetail/SellerOrderDetail";
import { AllowedRoutes, RedirectPage } from "./util/Routing";
import type { IRoutePaths, RouteType } from "./types/types";
import { Homepage } from "./pages/public/HomePage/HomePage";
import { SetUpCompany } from "./pages/public/SetUpCompany/SetUpCompanyForm";
import { ForgotPassword } from "./pages/public/ForgotPassword/ForgotPassword";
import { ForgotUserName } from "./pages/public/ForgotUserName/ForgotUserName";
import { ResetPassword } from "./pages/Misc/ResetPassword";
import { ProspectsListPage } from "./pages/admin/SellerAdmin/SellerAdminDigitalMarketing/Prospects/ProspectsList";
import { ProspectsListDetails } from "./pages/admin/SellerAdmin/SellerAdminDigitalMarketing/Prospects/ProspectsListDetails";
import { MarketingListPage } from "./pages/admin/SellerAdmin/SellerAdminDigitalMarketing/Marketing/MarketingListPage";
import { CartSwitcherGuest } from "./pages/Misc/CartSwitchers/CartSwitcherGuest";
// import { SellerAdminSampleRequestDetail } from "./pages/admin/SellerAdmin/SellerAdminSampleRequestDetail/SellerAdminSampleRequestDetail";
import { BuyerMyProducts } from "./pages/Buyer/BuyerMyProducts/BuyerMyProducts";
import { BuyerMyProductsPriceTiers } from "./pages/Buyer/BuyerMyProductsPriceTiers/BuyerMyProductsPriceTiers";
import { SellerAdminSampleRequestLeadDetail } from "./pages/admin/SellerAdmin/SellerAdminSampleRequestLeadDetail/SellerAdminSampleRequestLeadDetail";
import { SellerSampleRequests } from "./pages/Seller/SellerSampleRequests/SellerSampleRequests";
import { SellerSampleRequestDetail } from "./pages/Seller/SellerSampleRequestDetail/SellerSampleRequestDetail";
import { CartSwitcherBuyer } from "./pages/Misc/CartSwitchers/CartSwitcherBuyer";
import { BuyerSampleRequests } from "./pages/Buyer/BuyerSampleRequests/BuyerSampleRequests";
import { BuyerSampleRequestDetail } from "./pages/Buyer/BuyerSampleRequestDetail/BuyerSampleRequestDetail";
import { SupportPage } from "./components/SupportPage/SupportPage";
import { LeadDetailPage } from "./pages/SharedPages/LeadsPage/LeadDetailPage";
import { ProductsList } from "./pages/SharedPages/OrganizationPage/ProductsList/ProductsList";
import { BuyerOrganization } from "./pages/Buyer/BuyerOrganization/BuyerOrganization";
//PIM Routes
import { SellerAdminAttributesCollectionDetails } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributesCollections/SellerAdminAttributesCollectionDetails/SellerAdminAttributesCollectionDetails";
import { AttributeDetailPage } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributes/AttributeDetailPage";
import { AttributeGroupDetail } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributeGroups/AttributeGroupDetail";
import { SellerAdminPIMAttributeGroups } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributeGroups/SellerAdminPIMAttributeGroups";
import { SellerAdminTemplateListingPage } from "./pages/admin/SellerAdmin/PIM/SellerAdminTemplates/SellerAdminTemplateListingPage";
import { SellerAdminAttributesCollectionsList } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributesCollections/SellerAdminAttributesCollectionsList/SellerAdminAttributesCollectionsList";
import { SellerAdminTemplateGroupDetail } from "./pages/admin/SellerAdmin/PIM/SellerAdminTemplates/SellerAdminTemplateGroupDetail";
import { SellerAdminTemplateCollectionTable } from "./pages/admin/SellerAdmin/PIM/SellerAdminTemplates/SellerAdminTemplateCollectionTable";
import { SellerAdminPIMListHomePage } from "./pages/admin/SellerAdmin/PIM/Lists/SellerAdminPIMListHomePage";
import { CreatePimProductPage } from "./pages/SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreatePimProductPage";
import { SellerAdminTemplateActivityTable } from "./pages/admin/SellerAdmin/PIM/SellerAdminTemplates/SellerAdminTemplateActivityTable";
import { AssetList } from "./pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/AssetsList/AssetsList";
import { SellerAdminRolesListingPage } from "./pages/admin/SellerAdmin/SellerAdminRoles/SellerAdminRolesListingPage";
import { SellerAdminRoleDetail } from "./pages/admin/SellerAdmin/SellerAdminRoleDetail/SellerAdminRoleDetail";
import { AttributesLandingPageRedirects } from "./pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributes/AttributesRedirects";
import { BuyerProductDetailPortfolio } from "./pages/Buyer/BuyerProductDetailPortfolio/BuyerProductDetailPortfolio";
import { DistributorsList } from "./pages/Distributor/DistributorList/DistributorsList";
import { SellerAdminDistributorDetail } from "./pages/admin/SellerAdmin/SellerAdminDistributorDetail/SellerAdminDistributorDetail";
import { NotFoundPage } from "./pages/Misc/NotFoundPage";
import { BuyerProductsList } from "./pages/Buyer/BuyerProductsList/BuyerProductsList";
import { Dashboard } from "./pages/Seller/Dashboard/Dashboard";
import { GeneratedAssetsSettingsPage } from "./pages/admin/SellerAdmin/PIM/SellerAdminTemplates/TemplateGeneratedAssets/GeneratedAssetsSettingsPage";
import { SellerAdminTeamDetail } from "./pages/admin/SellerAdmin/SellerAdminTeams/SellerAdminTeamDetail";
import { SellerAdminTeamsListPageWrapper } from "./pages/admin/SellerAdmin/SellerAdminTeams/SellerAdminTeamsListWrapper";
import { AssetBulkUploadHomePage } from "./pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/BulkUploads/AssetBulkUploadHomePage";
import { ThankYouPage } from "./pages/public/ThankYouPage/ThankYouPage";
import { TwoFAPage } from "./pages/public/TwoFAPages/TwoFAPage";

const routePaths: IRoutePaths = {
  accountPath: "/store/:tenantSlug/account",
  adminPath: "/store/:tenantSlug/admin",
  storePath: "/store/:tenantSlug",
};

const { accountPath, adminPath, storePath } = routePaths;

// ORDER FOR ROUTES:
// Dashboard and Analytics
// PIM:
// - products, templates, assets, attributes, list
// TRANSACTIONS:
// - orders, samples, quotes
// CHANNEL MANAGEMENT:
// - customers, storefronts
// MY ORGANIZATION:
// - company info, users, roles
// MARKETING:
// - leads, prospects, emails

export const DashboardAndAnalyticsRoutes: RouteType[] = [
  {
    path: `${adminPath}/dashboard`,
    component: Dashboard,
    browserTitle: "Dashboard",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Dashboard", icon: DashboardIcon },
      portal: { name: "Dashboard", icon: DashboardIcon },
    },
  },
];

export const SellerPIMRoutes: RouteType[] = [
  {
    path: `${adminPath}/pim/products`,
    component: ProductsList,
    browserTitle: "Products",
    exact: true,
    permission: "view_products",
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Products", icon: ProductsV2Icon },
      portal: { name: "Products", icon: ProductsV2Icon },
    },
  },
  {
    path: `${adminPath}/pim/assets`,
    component: AssetList,
    browserTitle: "Assets",
    exact: true,
    permission: "view_assets",
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Assets", icon: AssetsIcon },
      portal: { name: "Assets", icon: AssetsIcon },
    },
  },
  {
    path: `${adminPath}/pim/assets/uploads`,
    component: AssetBulkUploadHomePage,
    browserTitle: "Assets",
    exact: true,
    permission: ["view_assets", "modify_assets"],
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates`,
    component: SellerAdminTemplateListingPage,
    browserTitle: "Templates",
    exact: true,
    permission: "view_templates",
    parentNav: "PIM",
    layout: LayoutPortal,
    nav: {
      admin: { name: "Templates", icon: TemplatesNavIcon },
      portal: { name: "Templates", icon: TemplatesNavIcon },
    },
  },
  {
    path: `${adminPath}/pim/templates/:template_id/groups`,
    component: SellerAdminTemplateGroupDetail,
    browserTitle: "Templates",
    permission: "view_templates",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates/:template_id/groups/:group_id`,
    component: AttributeGroupDetail,
    browserTitle: "Attribute group detail",
    permission: "view_templates",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates/:template_id/assets`,
    component: GeneratedAssetsSettingsPage,
    browserTitle: "Templates",
    permission: ["view_templates", "modify_templates"],
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates/:template_id/activity`,
    component: SellerAdminTemplateActivityTable,
    browserTitle: "Templates",
    permission: "view_templates",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates/:template_id/collections`,
    component: SellerAdminTemplateCollectionTable,
    browserTitle: "template collections",
    permission: "view_templates",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/templates/:template_id/collections/:id`,
    component: SellerAdminAttributesCollectionDetails,
    browserTitle: "Collection Details",
    permission: "view_templates",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/attributes`,
    component: AttributesLandingPageRedirects,
    browserTitle: "Attributes",
    exact: true,
    // despite the name of the URL, this page is a tabbed page containing
    // groups/collections/attributes.
    // We need one of the below permissions to view it.
    permission: ["view_attributes", "view_collections", "view_groups"],
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Attributes", icon: AttributesNavIcon },
      portal: { name: "Attributes", icon: AttributesNavIcon },
    },
  },
  {
    path: `${adminPath}/pim/attributes/collections`,
    component: SellerAdminAttributesCollectionsList,
    browserTitle: "Attribute Collections",
    permission: "view_collections",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/attributes/collections/:id`,
    component: SellerAdminAttributesCollectionDetails,
    browserTitle: "Collection Details",
    permission: "view_collections",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/attributes/groups`,
    component: SellerAdminPIMAttributeGroups,
    browserTitle: "Attribute groups",
    permission: "view_groups",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/attributes/groups/:group_id`,
    component: AttributeGroupDetail,
    browserTitle: "Attribute group detail",
    permission: "view_groups",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/pim/lists`,
    component: SellerAdminPIMListHomePage,
    browserTitle: "Lists",
    exact: true,
    permission: "view_lists",
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Lists", icon: ListsIcon },
      portal: { name: "Lists", icon: ListsIcon },
    },
  },
  {
    path: `${adminPath}/pim/products/new`,
    component: CreatePimProductPage,
    browserTitle: "Products",
    exact: true,
    layout: LayoutPortal,
    permission: "modify_products",
    // navName: "Seller Admin Product Detail",
  },
  {
    path: `${adminPath}/pim/products/:product_id`,
    component: SellerAdminProductDetail,
    browserTitle: "Products",
    exact: true,
    permission: "view_products",
    layout: LayoutPortal,
    // navName: "Seller Admin Product Detail",
  },
  {
    path: `${adminPath}/pim/attributes/:id`,
    component: AttributeDetailPage,
    permission: "view_attributes",
    exact: true,
    layout: LayoutPortal,
  },
];

export const SellerAdminRoutes: RouteType[] = [
  // SELLER ADMIN NAV LINK ROUTES - In the order they appear in the nav bars.
  // PIM
  // CHANNEL MANAGEMENT
  {
    path: `${adminPath}/tenants`,
    component: SellerAdminCustomersList,
    browserTitle: "Customers",
    exact: true,
    layout: LayoutPortal,
    parentNav: "CHANNEL MANAGEMENT",
    nav: {
      admin: { name: "Customers", icon: UsersIcon },
      portal: { name: "Customers", icon: UsersIcon },
    },
  },
  {
    path: `${adminPath}/distributors`,
    component: DistributorsList,
    browserTitle: "Distributors",
    exact: true,
    layout: LayoutPortal,
    parentNav: "CHANNEL MANAGEMENT",
    nav: {
      admin: { name: "Distributors", icon: DistributorsIcon },
      portal: { name: "Distributors", icon: DistributorsIcon },
    },
  },
  // MARKETING
  {
    path: `${adminPath}/leads`,
    component: pageForEdition({
      DefaultPage: SellerAdminLeadsList,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Leads",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MARKETING",
    nav: {
      admin: { name: "Leads", icon: LeadsIcon },
      portal: { name: "Leads", icon: LeadsIcon },
    },
  },
  {
    path: `${adminPath}/prospects`,
    component: pageForEdition({
      DefaultPage: ProspectsListPage,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    metaName: "digital_marketing_enabled",
    browserTitle: "Prospects",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Prospects", icon: OpportunitiesIcon },
      admin: { name: "Prospects", icon: OpportunitiesIcon },
    },
  },
  {
    path: `${adminPath}/marketing`,
    component: pageForEdition({
      DefaultPage: MarketingListPage,
      StarterEditionPage: NotSupportedForEdition,
    }),
    metaName: "digital_marketing_enabled",
    browserTitle: "Marketing",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Marketing", icon: AnalyticsIcon },
      admin: { name: "Marketing", icon: AnalyticsIcon },
    },
  },
  // ORGANIZATION
  {
    path: `${adminPath}/organization`,
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/company-info`,
    component: SellerAdminOrganization,
    browserTitle: "Organization Company Info",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Company Info", icon: CompanyIcon },
      portal: { name: "Company Info", icon: CompanyIcon },
    },
  },
  {
    path: `${adminPath}/organization/teams`,
    component: SellerAdminTeamsListPageWrapper,
    browserTitle: "Teams",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Teams", icon: TeamsIcon },
      portal: { name: "Teams", icon: TeamsIcon },
    },
  },

  {
    path: `${adminPath}/organization/teams/:team_id`,
    component: SellerAdminTeamDetail,
    browserTitle: "Teams",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/roles`,
    component: SellerAdminRolesListingPage,
    browserTitle: "Organization Roles",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Roles", icon: RolesUserIcon },
      portal: { name: "Roles", icon: RolesUserIcon },
    },
  },
  {
    path: `${adminPath}/organization/users`,
    component: SellerAdminOrganization,
    browserTitle: "Organization Users",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Users", icon: UsersIcon },
      portal: { name: "Users", icon: UsersIcon },
    },
  },

  {
    path: `${adminPath}/organization/roles/:role_id`,
    component: SellerAdminRoleDetail,
    browserTitle: "Role Detail",
    exact: true,
    layout: LayoutPortal,
  },

  {
    path: `${adminPath}/manage`,
    // This route just redirects to `/organization`.
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: adminPath,
    // This route just redirects to `/organization`, the default landing page
    // for seller admins. Its forseeable that someone might go back to "home" by
    // typing in /store/{slug}/admin, which would 404 without this route.
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
  },
  // TRANSACTIONS
  {
    path: `${accountPath}/orders`,
    component: pageForEdition({
      DefaultPage: SellerOrdersList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Orders",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Orders", icon: OrderIcon },
      admin: { name: "Orders", icon: OrderIcon },
    },
  },
  {
    path: `${accountPath}/samples`,
    component: pageForEdition({
      DefaultPage: SellerSampleRequests,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Requests",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      admin: { name: "Samples", icon: LabFlaskIcon },
      portal: { name: "Samples", icon: LabFlaskIcon },
    },
  },
  {
    path: `${accountPath}/quotes`,
    component: pageForEdition({
      DefaultPage: SellerQuotesList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Quotes",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Quotes", icon: QuoteIcon },
      admin: { name: "Quotes", icon: QuoteIcon },
    },
  },
  {
    path: `${adminPath}/settings`,
    component: SellerAdminSettings,
    browserTitle: "Seller Admin Settings",
    exact: true,
    layout: LayoutPortal,
  },

  // SELLER ADMIN NON-NAV LINK ROUTES - In alphabetical order by "path".

  {
    path: `${adminPath}/leads/:lead_id`,
    component: pageForEdition({
      DefaultPage: SellerAdminLeadDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Admin Lead Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/sample-requests/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: SellerAdminSampleRequestLeadDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Admin Sample Request Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/orders/:order_id`,
    component: pageForEdition({
      DefaultPage: SellerOrderDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Order Detail",
    exact: true,
    layout: LayoutPortal,
  },
  // User preferences will likely eventually exist for
  // every user type but its not clear whether they can share
  // a component. So for now this is here.
  {
    path: `${adminPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Seller Admin Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  // The below only exists temporarily until seller and seller standard route
  // trees diverge.
  {
    path: `${accountPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Seller Standard Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/prospects/list/:list_id`,
    component: pageForEdition({
      DefaultPage: ProspectsListDetails,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Prospects List Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/registration/:lead_id`,
    component: pageForEdition({
      DefaultPage: LeadDetailPage,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Registration Lead",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/contact-us/:lead_id`,
    component: pageForEdition({
      DefaultPage: LeadDetailPage,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Contact us lead",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/quotes/:quote_id`,
    component: pageForEdition({
      DefaultPage: SellerQuoteDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Quote Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/samples/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: SellerSampleRequestDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Request",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/tenants/:tenant_id`,
    component: SellerAdminCustomerDetail,
    browserTitle: "Customer Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/distributors/:distributor_id`,
    component: SellerAdminDistributorDetail,
    browserTitle: "Distributor Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/users/:user_id`,
    component: SellerAdminUserDetail,
    browserTitle: "Seller Admin User Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPortal,
  },
];

/**
 * Routes for standard sellers, they cannot CRUD users/roles
 */
export const SellerRoutes: RouteType[] = [
  // SELLER ADMIN NAV LINK ROUTES - In the order they appear in the nav bars.
  // Dashboard
  // PIM
  // CHANNEL MANAGEMENT
  {
    path: `${adminPath}/tenants`,
    component: SellerAdminCustomersList,
    browserTitle: "Customers",
    exact: true,
    layout: LayoutPortal,
    parentNav: "CHANNEL MANAGEMENT",
    nav: {
      admin: { name: "Customers", icon: UsersIcon },
      portal: { name: "Customers", icon: UsersIcon },
    },
  },
  {
    path: `${adminPath}/distributors`,
    component: DistributorsList,
    browserTitle: "Distributors",
    exact: true,
    layout: LayoutPortal,
    parentNav: "CHANNEL MANAGEMENT",
    nav: {
      admin: { name: "Distributors", icon: DistributorsIcon },
      portal: { name: "Distributors", icon: DistributorsIcon },
    },
  },
  // MARKETING
  {
    path: `${adminPath}/leads`,
    component: pageForEdition({
      DefaultPage: SellerAdminLeadsList,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Leads",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MARKETING",
    nav: {
      admin: { name: "Leads", icon: LeadsIcon },
      portal: { name: "Leads", icon: LeadsIcon },
    },
  },
  {
    path: `${adminPath}/prospects`,
    component: pageForEdition({
      DefaultPage: ProspectsListPage,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
      BusinessEditionPage: NotSupportedForEdition,
    }),
    metaName: "digital_marketing_enabled",
    browserTitle: "Prospects",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Prospects", icon: OpportunitiesIcon },
      admin: { name: "Prospects", icon: OpportunitiesIcon },
    },
  },
  {
    path: `${adminPath}/marketing`,
    component: pageForEdition({
      DefaultPage: MarketingListPage,
      StarterEditionPage: NotSupportedForEdition,
    }),
    metaName: "digital_marketing_enabled",
    browserTitle: "Marketing",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Marketing", icon: AnalyticsIcon },
      admin: { name: "Marketing", icon: AnalyticsIcon },
    },
  },
  // ORGANIZATION
  {
    path: `${adminPath}/organization`,
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/company-info`,
    component: SellerAdminOrganization,
    browserTitle: "Organization Company Info",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Company Info", icon: CompanyIcon },
      portal: { name: "Company Info", icon: CompanyIcon },
    },
  },
  {
    path: `${adminPath}/organization/teams`,
    component: SellerAdminTeamsListPageWrapper,
    browserTitle: "Teams",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
    nav: {
      admin: { name: "Teams", icon: TeamsIcon },
      portal: { name: "Teams", icon: TeamsIcon },
    },
  },
  {
    path: `${adminPath}/organization/teams/:team_id`,
    component: SellerAdminTeamDetail,
    browserTitle: "Teams",
    parentNav: "MY ORGANIZATION",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/manage`,
    // This route just redirects to `/organization`.
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: adminPath,
    // This route just redirects to `/organization`, the default landing page
    // for seller admins. Its forseeable that someone might go back to "home" by
    // typing in /store/{slug}/admin, which would 404 without this route.
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
  },
  // TRANSACTIONS
  {
    path: `${accountPath}/orders`,
    component: pageForEdition({
      DefaultPage: SellerOrdersList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Orders",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Orders", icon: OrderIcon },
      admin: { name: "Orders", icon: OrderIcon },
    },
  },
  {
    path: `${accountPath}/samples`,
    component: pageForEdition({
      DefaultPage: SellerSampleRequests,
      PIMBasicEditionPage: NotSupportedForEdition,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Requests",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      admin: { name: "Samples", icon: LabFlaskIcon },
      portal: { name: "Samples", icon: LabFlaskIcon },
    },
  },
  {
    path: `${accountPath}/quotes`,
    component: pageForEdition({
      DefaultPage: SellerQuotesList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Quotes",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Quotes", icon: QuoteIcon },
      admin: { name: "Quotes", icon: QuoteIcon },
    },
  },
  {
    path: `${adminPath}/settings`,
    component: SellerAdminSettings,
    browserTitle: "Seller Admin Settings",
    exact: true,
    layout: LayoutPortal,
  },

  // SELLER ADMIN NON-NAV LINK ROUTES - In alphabetical order by "path".

  {
    path: `${adminPath}/leads/:lead_id`,
    component: pageForEdition({
      DefaultPage: SellerAdminLeadDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Admin Lead Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/sample-requests/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: SellerAdminSampleRequestLeadDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Seller Admin Sample Request Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/orders/:order_id`,
    component: pageForEdition({
      DefaultPage: SellerOrderDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Order Detail",
    exact: true,
    layout: LayoutPortal,
  },
  // User preferences will likely eventually exist for
  // every user type but its not clear whether they can share
  // a component. So for now this is here.
  {
    path: `${adminPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Seller Admin Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  // The below only exists temporarily until seller and seller standard route
  // trees diverge.
  {
    path: `${accountPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Seller Standard Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/prospects/list/:list_id`,
    component: pageForEdition({
      DefaultPage: ProspectsListDetails,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
      BusinessEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Prospects List Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/registration/:lead_id`,
    component: pageForEdition({
      DefaultPage: LeadDetailPage,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Registration Lead",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/leads/contact-us/:lead_id`,
    component: pageForEdition({
      DefaultPage: LeadDetailPage,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Contact us lead",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/quotes/:quote_id`,
    component: pageForEdition({
      DefaultPage: SellerQuoteDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Quote Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/samples/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: SellerSampleRequestDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Request",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/tenants/:tenant_id`,
    component: SellerAdminCustomerDetail,
    browserTitle: "Customer Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/distributors/:distributor_id`,
    component: SellerAdminDistributorDetail,
    browserTitle: "Distributor Details",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPortal,
  },
];

export const BuyerUnactivatedRoutes: RouteType[] = [
  {
    path: `${adminPath}/organization`,
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/company-info`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization Info",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Company Info", icon: CompanyIcon },
      portal: { name: "Company Info", icon: CompanyIcon },
    },
  },
  {
    path: `${adminPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Buyer Admin Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: "/*",
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
];

export const BuyerAdminPIMRoutes: RouteType[] = [
  {
    path: `${storePath}/home`,
    component: Homepage,
    exact: true,
    layout: LayoutPublic,
    nav: {
      public: { name: "Home" },
    },
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${storePath}/portfolio`,
    component: PublicIndexWrapper,
    exact: true,
    layout: LayoutPortal,
    nav: {
      public: { name: "Portfolio" },
    },
  },
  {
    path: `${adminPath}/pim/products`,
    component: BuyerProductsList,
    browserTitle: "Products",
    exact: false,
    permission: "view_products",
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Products", icon: ProductsV2Icon },
      portal: { name: "Products", icon: ProductsV2Icon },
    },
  },
  {
    path: `${adminPath}/pim/products/:productSlug`,
    component: BuyerProductsList,
    browserTitle: "Products",
    exact: false,
    // permission: "view_products",
    layout: LayoutPortal,
    parentNav: "PIM",
  },
  {
    path: `${storePath}/product/:productSlug`,
    component: BuyerProductDetailPortfolio,
    exact: true,
    permission: "view_products",
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization`,
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/company-info`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization Info",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Company Info", icon: CompanyIcon },
      portal: { name: "Company Info", icon: CompanyIcon },
    },
  },
  {
    path: `${adminPath}/organization/users`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization Users",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Users", icon: UsersIcon },
      portal: { name: "Users", icon: UsersIcon },
    },
  },
  {
    path: `${adminPath}/organization/settings`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization settings",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Settings", icon: SettingsIcon },
      portal: { name: "Settings", icon: SettingsIcon },
    },
  },
  {
    path: `${adminPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Buyer Admin Preferences",
    exact: true,
    layout: LayoutPortal,
  },
];

export const BuyerAdminRoutes: RouteType[] = [
  {
    path: `${storePath}/home`,
    component: Homepage,
    exact: true,
    layout: LayoutPublic,
    // nav: {
    //   public: { name: "Home" },
    //   portal: { name: "Home" },
    // },
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${storePath}/portfolio`,
    component: PublicIndexWrapper,
    exact: true,
    layout: LayoutPortal,
    nav: {
      public: { name: "Portfolio" },
    },
  },
  {
    path: `${adminPath}/pim/products`,
    component: BuyerProductsList,
    browserTitle: "Products",
    exact: true,
    permission: "view_products",
    layout: LayoutPortal,
    parentNav: "PIM",
    nav: {
      admin: { name: "Products", icon: ProductsV2Icon },
      portal: { name: "Products", icon: ProductsV2Icon },
    },
  },
  {
    path: `${adminPath}/pim/products/:productSlug`,
    component: BuyerProductsList,
    browserTitle: "Products",
    exact: false,
    // permission: "view_products",
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/product/:productSlug`,
    component: BuyerProductDetailPortfolio,
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/cart`,
    component: CartSwitcherBuyer,
    exact: false,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/manage`,
    // This route just redirects to `/organization`.
    component: () => (
      <RedirectPage routePathKey={"adminPath"} path={"/organization"} />
    ),
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/my-products`,
    component: BuyerMyProducts,
    browserTitle: "My Products",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      admin: { name: "My Products", icon: PersonalCollectionIcon },
      portal: { name: "My Products", icon: PersonalCollectionIcon },
    },
  },
  {
    path: `${accountPath}/my-products/tiers/:product_id`,
    component: BuyerMyProductsPriceTiers,
    browserTitle: "My Products detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/orders/:orderId`,
    component: pageForEdition({
      DefaultPage: BuyerOrderDetailWrapper,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Order Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/orders`,
    component: pageForEdition({
      DefaultPage: BuyerOrders,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Orders",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Orders", icon: OrderIcon },
      admin: { name: "Orders", icon: OrderIcon },
    },
  },
  {
    path: `${accountPath}/orders/:orderId`,
    component: pageForEdition({
      DefaultPage: BuyerOrderDetailWrapper,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Order Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization`,
    component: () => (
      <RedirectPage
        routePathKey={"adminPath"}
        path={"/organization/company-info"}
      />
    ),
    browserTitle: "Company Info",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/organization/company-info`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization Info",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Company Info", icon: CompanyIcon },
      portal: { name: "Company Info", icon: CompanyIcon },
    },
  },
  {
    path: `${adminPath}/organization/users`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization Users",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Users", icon: UsersIcon },
      portal: { name: "Users", icon: UsersIcon },
    },
  },
  {
    path: `${adminPath}/organization/settings`,
    component: BuyerAdminOrganization,
    browserTitle: "Organization settings",
    exact: true,
    layout: LayoutPortal,
    parentNav: "MY ORGANIZATION",
    nav: {
      admin: { name: "Settings", icon: SettingsIcon },
      portal: { name: "Settings", icon: SettingsIcon },
    },
  },
  {
    path: `${accountPath}/quotes`,
    component: pageForEdition({
      DefaultPage: BuyerQuotesList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Quotes",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Quotes", icon: QuoteIcon },
      admin: { name: "Quotes", icon: QuoteIcon },
    },
  },
  {
    path: `${accountPath}/samples`,
    component: pageForEdition({
      DefaultPage: BuyerSampleRequests,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Requests",
    exact: true,
    layout: LayoutPortal,
    parentNav: "TRANSACTIONS",
    nav: {
      portal: { name: "Samples", icon: LabFlaskIcon },
      admin: { name: "Samples", icon: LabFlaskIcon },
    },
  },
  {
    path: `${accountPath}/samples/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: BuyerSampleRequestDetail,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Request",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/quotes/:quoteId`,
    component: pageForEdition({
      DefaultPage: BuyerQuoteDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Quote Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${adminPath}/preferences`,
    component: UserPreferences,
    browserTitle: "Buyer Admin Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPortal,
  },
];

export const BuyerRoutes: RouteType[] = [
  {
    path: `${storePath}/home`,
    component: Homepage,
    exact: true,
    layout: LayoutPublic,
    nav: {
      public: { name: "Home" },
      portal: { name: "Home", icon: HomeIcon },
    },
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${accountPath}/preferences`,
    // We don't have a another RouteType array for all logged in users because
    // this component has a different path depending on role
    component: UserPreferences,
    browserTitle: "Buyer Preferences",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/my-products`,
    component: BuyerMyProducts,
    browserTitle: "My Products",
    exact: true,
    layout: LayoutPortal,
    nav: {
      // TODO: add correct icon
      admin: { name: "My Products", icon: SoldByIcon },
      portal: { name: "My Products", icon: SoldByIcon },
    },
  },
  {
    path: `${accountPath}/my-products/tiers/:product_id`,
    component: BuyerMyProductsPriceTiers,
    browserTitle: "My Products detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/portfolio`,
    component: PublicIndexWrapper,
    exact: true,
    layout: LayoutPortal,
    nav: {
      public: { name: "Portfolio" },
      portal: { name: "Portfolio", icon: StackIcon },
    },
  },
  {
    path: `${storePath}/product/:productSlug`,
    component: BuyerProductDetailPortfolio,
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/cart`,
    component: CartSwitcherBuyer,
    exact: false,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/orders`,
    component: pageForEdition({
      DefaultPage: BuyerOrders,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Orders",
    exact: true,
    layout: LayoutPortal,
    nav: { portal: { name: "Orders", icon: OrderIcon } },
  },
  {
    path: `${accountPath}/orders/:orderId`,
    component: pageForEdition({
      DefaultPage: BuyerOrderDetailWrapper,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Order Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/organization`,
    component: BuyerOrganization,
    browserTitle: "Organization",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Organization", icon: CompanyIcon },
    },
  },

  {
    path: `${accountPath}/quotes`,
    component: pageForEdition({
      DefaultPage: BuyerQuotesList,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Quotes",
    exact: true,
    layout: LayoutPortal,
    nav: { portal: { name: "Quotes", icon: QuoteIcon } },
  },
  {
    path: `${accountPath}/quotes/:quoteId`,
    component: pageForEdition({
      DefaultPage: BuyerQuoteDetail,
      StarterEditionPage: NotSupportedForEdition,
      PIMBasicEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Buyer Quote Detail",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${accountPath}/samples`,
    component: pageForEdition({
      DefaultPage: BuyerSampleRequests,
      PIMBasicEditionPage: NotSupportedForEdition,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Requests",
    exact: true,
    layout: LayoutPortal,
    nav: {
      portal: { name: "Samples", icon: LabFlaskIcon },
      admin: { name: "Samples", icon: LabFlaskIcon },
    },
  },
  {
    path: `${accountPath}/samples/:sample_request_id`,
    component: pageForEdition({
      DefaultPage: BuyerSampleRequestDetail,
      PIMBasicEditionPage: NotSupportedForEdition,
      StarterEditionPage: NotSupportedForEdition,
    }),
    browserTitle: "Sample Request",
    exact: true,
    layout: LayoutPortal,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPortal,
  },
];

/**
 * For react specific reasons the translation for public nav route text is in layout/shared/Nav.tsx.
 * See https://gitlab.agilis.dev/agilis/agilis3-frontend/merge_requests/808
 */
export const PublicRoutes: RouteType[] = [
  {
    path: `${storePath}/home`,
    component: Homepage,
    exact: true,
    layout: LayoutPublic,
    nav: {
      public: { name: "Home" },
    },
    internalName: "home",
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${storePath}/join/:invite_id`,
    component: SetUpCompany,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/portfolio`,
    component: PublicIndexWrapper,
    exact: true,
    layout: LayoutPublic,
    internalName: "portfolio",
    nav: {
      public: { name: "Portfolio" },
    },
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${storePath}/product/:productSlug`,
    component: PublicIndexWrapper,
    internalName: "portfolio",
    exact: true,
    layout: LayoutPublic,
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    path: `${storePath}/contact-us`,
    component: ContactUsPage,
    exact: true,
    layout: LayoutPublic,
    internalName: "contact_us",
    nav: { public: { name: "Contact Us" } },
  },
  {
    path: `${storePath}/contact-us/thank-you`,
    component: ThankYouPage,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/support`,
    component: SupportPage,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/register`,
    component: Registration,
    exact: true,
    internalName: "register",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/register/thank-you`,
    component: ThankYouPage,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/login`,
    component: Login,
    exact: true,
    browserTitle: "Login",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/manual_login`,
    component: Login,
    exact: true,
    browserTitle: "Manual Login",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/two-factor-auth-login`,
    component: TwoFAPage,
    exact: true,
    browserTitle: "2FA Login",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/forgot-password`,
    component: ForgotPassword,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/forgot-username`,
    component: ForgotUserName,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/activate-account/:token`,
    component: ActivateAccount,
    exact: true,
    browserTitle: "Account activation",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/reset-password/:token`,
    component: ResetPassword,
    exact: true,
    browserTitle: "Reset Password",
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/cart`,
    component: CartSwitcherGuest,
    internalName: "portfolio",
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/cart/thank-you-quote`,
    component: ThankYouPage,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/cart/thank-you-sample`,
    component: ThankYouPage,
    exact: true,
    layout: LayoutPublic,
  },
  {
    path: `${storePath}/404`,
    component: NotFoundPage,
    exact: true,
    layout: LayoutPublic,
  },
];

export const Routes = memo(function Routes() {
  return <PageProvider AllowedRoutes={AllowedRoutes} />;
});
