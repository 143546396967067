import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconContainer, ListItemNameSchemaFn } from "./ListHome";
import { ListItemName } from "./ListItem";
import { EditableTitle } from "../../../../../components/EditableTitle/EditableTitle";
import {
  ArchiveButton,
  ButtonWithConfirmDialog,
  EditButton,
} from "../../../../../components/Buttons/Buttons";
import ReactTooltip from "react-tooltip";
import { ItemDetailsWrapper } from "./ListDetails";
import type { ListItemSchema } from "../../../../../types/types.PIM";
import { checkTextOverflow } from "../../../../../util/util";
import { useAuthContext } from "../../../../../components/Auth";
import { SystemIconWrapper } from "../components/PIM.components.util";
import { SystemDefaultIcon } from "../../../../../components/Icons/Icons";

export const ListItemDetails = ({
  item: { name, id, is_frozen },
  active,
  selectListItem,
  handleDeleteListItem,
  handleEditListItem,
}: {
  item: ListItemSchema;
  active: boolean;
  selectListItem: () => void;
  handleDeleteListItem: () => void;
  handleEditListItem: (formValue: { name: string }) => void;
}) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const editNameUseForm = useForm({
    resolver: zodResolver(ListItemNameSchemaFn(t, 50)),
  });
  const nameRef = useRef<HTMLHeadingElement>(null);
  const editNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void }>(null);

  const { hasPermission } = useAuthContext();

  const handleEditClick = () => {
    setEditMode(true);
    setTimeout(() => editNameRef.current?.setEditMode(true));
  };

  const onEdit = ({ name: newName }: { name: string }) => {
    if (newName.trim() !== name.trim()) {
      handleEditListItem({ name: newName.trim() });
    }
  };

  const isTextOverflow = () =>
    nameRef.current ? checkTextOverflow(nameRef.current).widthOverflow : false;

  useEffect(() => {
    if (!active && editMode) {
      setEditMode(false);
    }
  }, [active, editMode]);
  return (
    <ItemDetailsWrapper active={active} onClick={selectListItem}>
      {editMode ? (
        <form
          id="edit-list-item-form"
          noValidate
          onSubmit={editNameUseForm.handleSubmit(onEdit)}
        >
          <EditableTitle
            title={name}
            formId="edit-list-item-form"
            name="name"
            methodsOfUseForm={editNameUseForm}
            ref={editNameRef}
            onClose={() => setEditMode(false)}
            fontSize="small"
            fontWeight="regular"
          />
        </form>
      ) : (
        <>
          <ListItemName
            ref={nameRef}
            data-for={`list-item-details-name-${name}-tooltip`}
            data-tip={isTextOverflow() ? name : ""}
          >
            {name}
          </ListItemName>
          <ReactTooltip id={`list-item-details-name-${name}-tooltip`} />
        </>
      )}
      <SystemIconWrapper
        style={{
          padding: 0,
          position: "relative",
          top: "2px",
        }}
        data-for={`system${id}`}
        data-tip={t("System Default")}
      >
        {is_frozen && <SystemDefaultIcon width={18} height={18} />}
      </SystemIconWrapper>
      {is_frozen && <ReactTooltip id={`system${id}`} />}
      <IconContainer>
        {!is_frozen && hasPermission("delete_lists") && (
          <>
            <ButtonWithConfirmDialog
              Button={(props) => (
                <>
                  <ArchiveButton {...props} width={24} height={24} />
                </>
              )}
              testid={`list-item-${name}`}
              handleConfirm={handleDeleteListItem}
              confirmMessage={t(
                "Are you sure you want to archive this list item, {{itemName}}?",
                { itemName: name }
              )}
            />
          </>
        )}
        {hasPermission("modify_lists") && (
          <EditButton onClick={handleEditClick} width={24} height={24} />
        )}
      </IconContainer>
    </ItemDetailsWrapper>
  );
};
