import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import { TertiaryButtonSmall } from "../../../../../../components/Buttons/Buttons";
import type { ChipType } from "../../../../../../components/Chips/Chips";
import { ChipContainer } from "../../../../../../components/Chips/Chips";
import { Modal } from "../../../../../../components/Modal/Modal";
import { PrefilledSelectAllChips } from "../../../../../../components/PrefilledSelectAllChips/PrefilledSelectAllChips";
import type { MethodsOfUseForm } from "../../../../../../types/types";
import type { AttributeSchema } from "../../../../../../types/types.PIM";
import { useOnClickOutside } from "../../../../../../util/hooks";
import { useMediaQueries } from "../../../../../../util/util";
import { showErrors } from "../../../../../../util/util-components";
import { useTranslation } from "react-i18next";

const DropDownWrapper = styled.div<{ error?: boolean }>`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  max-width: 100%;
  button {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  button[id~="tertiary_btn_small"] {
    color: ${({ error, theme }) =>
      (error && `${theme.errorColor}`) || theme.secondaryTextColor};
  }
  border-radius: 4px;
  border: ${({ error, theme }) =>
    (error && `2px solid ${theme.errorColor}`) || "none"};
`;
const DropDownModalWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 400px;
  max-width: 400px;
  background: ${({ theme }) => theme.primaryBG};
  padding: 0px 0 2px 8px;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadowColor};
  border-radius: 2px;
  z-index: 900 !important;
`;

const ScrollArea = styled.div`
  padding-top: 5px;
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;
  display: block;
  ${ChipContainer} {
    display: flex;
    flex-wrap: wrap;
    button {
      padding: 8px 10px;
      font-size: 12px;
    }
  }
`;

interface IMultiSelectCell {
  attribute: AttributeSchema;
  values?: { name: string }[];
  methodsOfUseForm: MethodsOfUseForm;
}

export const MultiSelectCell = ({
  attribute,
  values,
  methodsOfUseForm,
}: IMultiSelectCell) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [options, setOptions] = useState<ChipType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<ChipType[]>([]);
  const { register, setValue, errors } = methodsOfUseForm;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const wrapperRef = useRef(null);

  const chipsToStringArray = (chips: ChipType[]) => {
    return chips.map((chip) => chip.name);
  };

  const getButtonText = useCallback(() => {
    let optionsList = chipsToStringArray(selectedOptions);
    if (optionsList.length === 0) {
      return `Select ${
        attribute.display_name ? t([attribute.display_name]) : attribute.name
      }`;
    } else if (optionsList.length < 3) {
      return optionsList.join(", ");
    } else {
      return `${optionsList[0]}, ${optionsList[1]} and ${
        optionsList.length - 2
      } more`;
    }
  }, [selectedOptions, attribute.display_name, attribute.name, t]);

  const tooltipList = () => {
    let optionsList = chipsToStringArray(selectedOptions);

    return optionsList.length > 1 ? (
      <ul>
        {optionsList.map((chip) => {
          return <li key={chip}>{chip}</li>;
        })}
      </ul>
    ) : null;
  };

  const [buttonText, setButtonText] = useState(getButtonText);

  const handleFilterClick = (activeChips: ChipType[]) => {
    setSelectedOptions([...activeChips]);
  };

  useEffect(() => {
    setValue(attribute.id, selectedOptions, { shouldValidate: true });
  }, [selectedOptions, attribute, setValue]);

  const handleClickOutside = () => {
    setButtonText(getButtonText());
    setShowDropDown(false);
  };

  const convertToChipArray = (values: unknown): ChipType[] | null => {
    if (values === null) return null;
    if (values === undefined) return null;
    if (
      Array.isArray(values) &&
      values.every((item: unknown) => typeof item === "string")
    ) {
      return values.map((value: string) => ({ name: value }));
    } else return null;
  };

  useEffect(() => {
    setButtonText(getButtonText());
  }, [selectedOptions, getButtonText]);

  useEffect(() => {
    register({ name: attribute.id });
    setSelectedOptions(values ?? []);
  }, [attribute, register, values]);

  useEffect(() => {
    if (attribute.choices?.length) {
      setOptions(convertToChipArray(attribute.choices) || []);
    }
  }, [attribute]);

  useOnClickOutside(wrapperRef, () => handleClickOutside());

  const { isMediumScreen } = useMediaQueries();
  const theme = useTheme();
  const cellID = Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
  if (!isMediumScreen) {
    return (
      <div>
        <DropDownWrapper
          data-for={`row-tooltip-${attribute.id}${cellID}`}
          data-tip={""}
          error={errors[attribute.id]}
        >
          <TertiaryButtonSmall
            id="tertiary_btn_small"
            active={selectedOptions.length > 0}
            onClick={() => {
              setShowDropDown(!showDropDown);
            }}
            style={{ fontSize: theme.fontSizes.xs }}
          >
            {buttonText}
          </TertiaryButtonSmall>
          {tooltipList() !== null && !showDropDown && (
            <ReactTooltip
              id={`row-tooltip-${attribute.id}${cellID}`}
              place="top"
              data-html={true}
              effect="solid"
              backgroundColor="#60676f"
              multiline={true}
            >
              {tooltipList()}
            </ReactTooltip>
          )}
          {showDropDown && (
            <DropDownModalWrapper ref={wrapperRef}>
              <ScrollArea>
                <PrefilledSelectAllChips
                  allChips={options}
                  handleClick={handleFilterClick}
                  header={""}
                  withSelectAll={true}
                  selectedChips={selectedOptions}
                  ToggleLabel={
                    attribute.display_name
                      ? t([attribute.display_name])
                      : attribute.name
                  }
                />
              </ScrollArea>
            </DropDownModalWrapper>
          )}
          {showErrors({ errors, name: attribute.id, t })}
        </DropDownWrapper>
      </div>
    );
  } else {
    return (
      <DropDownWrapper error={errors[attribute.id]}>
        <TertiaryButtonSmall
          id="tertiary_btn_small_1"
          active={selectedOptions.length > 0}
          onClick={() => {
            setShowModal(true);
          }}
          style={{ marginBottom: "8px" }}
        >
          {buttonText}
        </TertiaryButtonSmall>
        <Modal show={showModal} closeModal={() => setShowModal(false)}>
          <PrefilledSelectAllChips
            allChips={options}
            handleClick={handleFilterClick}
            header={""}
            withSelectAll={true}
            selectedChips={selectedOptions}
            ToggleLabel={
              attribute.display_name
                ? t([attribute.display_name])
                : attribute.name
            }
          />
        </Modal>
        {showErrors({ errors, name: attribute.id, t })}
      </DropDownWrapper>
    );
  }
};
