import { useTranslation } from "react-i18next";
import type {
  UploadAction,
  UploadState,
} from "./createProductFromUploads.util";
import {
  DownloadTemplateContainer,
  RegularTextBlock,
  TemplateButtonContainer,
} from "./createProductFromUploads.util";
import {
  H3,
  H6Bold,
  RegularParagraph,
  SoftHeaderMediumDarkText,
} from "../../../../../../components/Typography/Typography";
import {
  PrimaryButtonMedium,
  SecondaryButtonMedium,
} from "../../../../../../components/Buttons/Buttons";
import styled, { useTheme } from "styled-components";
import {
  CSVIcon,
  DocumentIcon,
  DownloadIcon,
  LoadingIcon,
} from "../../../../../../components/Icons/Icons";
import useSWR from "swr";
import type {
  AttributeTemplateSchema,
  ProductExportStatusSchema,
} from "../../../../../../types/types.PIM";
import type { AxiosError, AxiosResponse } from "axios";
import { toTitleCase, useStoreState } from "../../../../../../util/util";
import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { endpoints } from "../../../../../../endpoints";
import type { OptionType, UUID } from "../../../../../../types/types";
import axios from "axios";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { ConfirmationSpacer } from "../../../../../public/SampleRequestCart/SampleRequestCart";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../../../../components/CheckBoxes/CheckBoxes";
import { GenericDialogBody } from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import { DelayedSpinner } from "../../../../../../components/DelayedSpinner/DelayedSpinner";

const POLL_TIMEOUT = 3000;

const file_date = (() => {
  const date = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }); // MM/DD/YYYY
  const date_tuple = date.split("/"); // [MM, DD, YYYY]
  return `${date_tuple[2]}-${date_tuple[0]}-${date_tuple[1]}`;
})();

const PrimaryButtonFlex = styled(PrimaryButtonMedium)`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  width: fit-content;
`;

const DocumentDownloadCard = styled.a`
  text-decoration: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 11px 15px;
  width: fit-content;
  color: ${({ theme }) => theme.primaryTextColor};
  :hover,
  :focus {
    border: 2px solid ${({ theme }) => theme.primaryBorder};
  }
`;

const convertArrayBufferToZipUrl = (data: Blob) => URL.createObjectURL(data);

const DocumentDownloadContainer = ({
  link,
  documentName,
  type,
}: {
  link: string;
  documentName: string;
  type: "application/zip" | "text/csv";
}) => {
  const updatedDocumentName = (() => {
    const ext = documentName.split(".").pop();
    const possible_extensions = ["csv", "zip"];
    return possible_extensions.some((possible_ext) => possible_ext === ext)
      ? documentName
      : `${documentName}.${type.includes("csv") ? "csv" : "zip"}`;
  })();
  return (
    <DocumentDownloadCard
      href={link}
      download={updatedDocumentName}
      target="_blank"
      rel="noopener noreferrer"
      type={type}
    >
      {type === "application/zip" ? (
        <DocumentIcon width={16} height={16} />
      ) : (
        <CSVIcon width={16} height={16} />
      )}
      {updatedDocumentName}
      <DownloadIcon width={16} height={16} />
    </DocumentDownloadCard>
  );
};

export const DownloadTemplateFiles = ({
  template,
  dispatch,
  export_id: download_export_id,
}: {
  template: { label: string; value: string };
  dispatch: Dispatch<UploadAction>;
  export_id?: string;
}) => {
  const [include_existing_product_data, set_include_existing_product_data] =
    useState(false);
  const [poll_timeout_id, set_poll_timeout_id] = useState<NodeJS.Timeout>();
  const [export_id, set_export_id] =
    useState<UUID | undefined>(download_export_id);
  const { t } = useTranslation();
  const { notifyError } = useNotifications();
  const { storefront_id, tenant_id } = useStoreState();

  const { data: template_schema, error: template_schema_error } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/templates/${template.value}`);

  const onCheckBoxChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.currentTarget.checked;
    set_include_existing_product_data(val);
    if (val && !export_id) {
      try {
        const defaultParams = {
          template: template.label,
          show_inactive: "true",
          limit: "5000",
        };
        const params = new URLSearchParams(defaultParams);
        params.append("status", "published");
        params.append("status", "unpublished");
        params.append("status", "draft");
        params.append("status", "staged");
        params.append("status", "unpublished_staged");

        const {
          data: { id },
        } = await axios.post<ProductExportStatusSchema>(
          endpoints.v2_storefronts_id_pim_products_export_async(storefront_id),
          {},
          { params }
        );
        set_export_id(id);
        dispatch({
          type: "set_export_id",
          payload: { export_id: id } as UploadState,
        });
      } catch (error) {
        const data = (error as AxiosError)?.response?.data;
        const errorCode = data?.status_code;
        const errorMessage = data?.message;
        if (errorCode === "404" && errorMessage === "No products found") {
          notifyError(t("There are no existing products for this template"));
          set_include_existing_product_data(false);
        } else {
          notifyError(t("Could not fetch existing product data."));
        }
      }
    }
    if (!val) {
      dispatch({
        type: "set_export_id",
        payload: { export_id: undefined } as UploadState,
      });
      clearTimeout(poll_timeout_id);
    }
  };

  useEffect(() => {
    if (download_export_id) {
      set_export_id(download_export_id);
      set_include_existing_product_data(true);
    }
  }, [download_export_id]);

  const onAsyncStatusFailed = useCallback(() => {
    notifyError(
      t("Could not fetch existing product data. Please try again later.")
    );
    dispatch({
      type: "set_export_id",
      payload: { export_id: undefined } as UploadState,
    });
    set_include_existing_product_data(false);
  }, [dispatch, notifyError, t]);

  const isLoading = !template_schema && !template_schema_error;

  return isLoading ? (
    <DelayedSpinner />
  ) : (
    <DownloadTemplateContainer>
      <H3 style={{ margin: "0 0 4px" }}>{t("Download Template files")}</H3>
      <RegularParagraph>
        {t(
          "The following CSV files contain product data for all groups & collections used in the selected template. Download these files to update existing product data or to create new products."
        )}
      </RegularParagraph>
      <RegularParagraph>
        {t(
          "Please do not modify the first rows containing attribute details. We also recommend keeping the same file name."
        )}
      </RegularParagraph>
      <ConfirmationSpacer style={{ marginTop: "15px" }}>
        <CheckBoxContainer style={{ marginBottom: "15px" }}>
          <div style={{ width: "22px", marginRight: "15px" }}>
            <CheckBoxNoLabel
              name="existing_product_data"
              id="existing_product_data"
              checked={include_existing_product_data}
              onChange={onCheckBoxChange}
            />
          </div>
          <CheckBoxFinePrintLabel htmlFor="existing_product_data">
            <span>{t("Include existing product data")}</span>
          </CheckBoxFinePrintLabel>
        </CheckBoxContainer>
      </ConfirmationSpacer>
      {template_schema ? (
        <>
          {include_existing_product_data ? (
            <AsyncDownloadTemplateFiles
              update_poll_timeout={set_poll_timeout_id}
              dispatch={dispatch}
              export_id={export_id}
              template={template_schema}
              on_failed={onAsyncStatusFailed}
            />
          ) : (
            <SyncDownloadTemplateFiles
              template={template_schema}
              dispatch={dispatch}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </DownloadTemplateContainer>
  );
};

const SyncDownloadTemplateFiles = ({
  template,
  dispatch,
}: {
  template: AttributeTemplateSchema;
  dispatch: Dispatch<UploadAction>;
}) => {
  const [allFileUrl, setAllFileUrl] = useState<OptionType<string>>();
  const [groupFileUrl, setGroupFileUrl] = useState<OptionType<string>>();
  const [collectionFilesUrl, setCollectionFilesUrl] =
    useState<OptionType<{ id: string; link: string }>[]>();

  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifyError } = useNotifications();

  const modifiedTemplate = useMemo(
    () => template.template_name.toLowerCase(),
    [template.template_name]
  );

  useSWR<Blob, AxiosError>(
    [
      endpoints.v2_tenants_tenant_id_templates_template_id_download(
        tenant_id,
        template.template_id
      ),
      useMemo(() => ({ params: { type: "all" }, responseType: "blob" }), []),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: (data) =>
        setAllFileUrl({
          label: modifiedTemplate,
          value: convertArrayBufferToZipUrl(data),
        }),
      onError: (error) => {
        setAllFileUrl(undefined);
        notifyError(
          t(
            "Could not fetch files to be downloaded. Please refresh the page to try again."
          ),
          { error }
        );
      },
    }
  );

  useSWR<string, AxiosError>(
    [
      endpoints.v2_tenants_tenant_id_templates_template_id_download(
        tenant_id,
        template.template_id
      ),
      useMemo(() => ({ params: { type: "group" }, responseType: "text" }), []),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: (data) =>
        setGroupFileUrl({
          label: `${modifiedTemplate}_groups_${file_date}`,
          value: convertArrayBufferToZipUrl(
            new Blob([String.fromCharCode(0xfeff), data], {
              type: "text/csv",
            })
          ),
        }),
      onError: (error) => {
        setGroupFileUrl(undefined);
        notifyError(
          t(
            "Could not fetch template group csv. Please refresh the page to try again."
          ),
          { error }
        );
      },
    }
  );

  const isContinueButtonDisabled = !(
    (allFileUrl || groupFileUrl) &&
    Boolean(collectionFilesUrl)
  );

  useEffect(() => {
    const getCollectionFiles = async (
      collections: { id: string; name: string }[]
    ) => {
      if (collections.length > 0) {
        try {
          const promises: Promise<AxiosResponse<string>>[] = collections.map(
            ({ id }) =>
              axios.get<string>(
                endpoints.v2_tenants_tenant_id_templates_template_id_download(
                  tenant_id,
                  template.template_id
                ),
                { params: { collection_id: id } }
              )
          );
          const collectionCSVs = await Promise.all(promises);
          setCollectionFilesUrl(
            collectionCSVs.map(({ data }, index) => ({
              label: `${template.template_name.toLowerCase()}_${collections[
                index
              ].name.toLowerCase()}_${file_date}`,
              value: {
                id: collections[index].id,
                link: convertArrayBufferToZipUrl(
                  new Blob([String.fromCharCode(0xfeff), data], {
                    type: "text/csv",
                  })
                ),
              },
            }))
          );
        } catch (error) {
          setCollectionFilesUrl(undefined);
          notifyError(
            t(
              "Could not fetch template collections csv. Please refresh the page to try again."
            ),
            { error }
          );
        }
      } else {
        setCollectionFilesUrl([]);
      }
    };
    getCollectionFiles(
      template.collections.map(({ id, name }) => ({ id, name }))
    );
  }, [
    notifyError,
    t,
    template.collections,
    template.template_id,
    template.template_name,
    tenant_id,
  ]);

  return (
    <>
      {allFileUrl && (
        <a
          href={allFileUrl.value}
          type="application/zip"
          download={allFileUrl.label}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", width: "fit-content" }}
        >
          <PrimaryButtonFlex>
            <DownloadIcon width={16} height={16} />
            {t("Download all files")}
          </PrimaryButtonFlex>
        </a>
      )}
      {groupFileUrl && (
        <>
          <H6Bold style={{ margin: 0 }}>{t("Groups")}</H6Bold>
          <RegularTextBlock>
            {t("This file is required to create product profiles")}
          </RegularTextBlock>
          <div style={{ margin: "8px 0 24px" }}>
            <DocumentDownloadContainer
              type="text/csv"
              link={groupFileUrl.value}
              documentName={groupFileUrl.label}
            />
          </div>
        </>
      )}
      {collectionFilesUrl && collectionFilesUrl.length > 0 && (
        <>
          <H6Bold style={{ margin: 0 }}>{t("Collections")}</H6Bold>
          <RegularTextBlock style={{ marginBottom: "8px" }}>
            {t(
              "These files are used to update existing products with collection data"
            )}
          </RegularTextBlock>
          {collectionFilesUrl.map(({ label, value: { link } }, index) => (
            <div
              key={label + link}
              style={{
                marginBottom:
                  index === collectionFilesUrl.length - 1 ? "24px" : "8px",
              }}
            >
              <DocumentDownloadContainer
                link={link}
                documentName={label}
                type="text/csv"
              />
            </div>
          ))}
        </>
      )}
      <TemplateButtonContainer>
        <SecondaryButtonMedium
          onClick={() =>
            dispatch({
              type: "move_back",
              payload: { uploadStage: "download" } as UploadState,
            })
          }
        >
          {t("Back")}
        </SecondaryButtonMedium>
        <PrimaryButtonMedium
          disabled={isContinueButtonDisabled}
          onClick={() =>
            dispatch({
              type: "continue_from_download",
              payload: {
                collectionFileNames: collectionFilesUrl!.reduce<
                  Record<string, string>
                >((acc, { label, value: { id } }) => {
                  acc[label] = id;
                  return acc;
                }, {}),
              } as UploadState,
            })
          }
        >
          {t("Continue")}
        </PrimaryButtonMedium>
      </TemplateButtonContainer>
    </>
  );
};

const AsyncDownloadTemplateFiles = ({
  dispatch,
  export_id,
  update_poll_timeout,
  template,
  on_failed,
}: {
  template: AttributeTemplateSchema;
  dispatch: Dispatch<UploadAction>;
  export_id?: UUID;
  update_poll_timeout: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>;
  on_failed: () => void;
}) => {
  const [is_preparing_files, set_is_preparing_files] = useState(false);
  const [document_urls, set_document_urls] = useState<
    { id: string; name: string; url: string }[]
  >([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const { storefront_id } = useStoreState();

  useEffect(() => {
    let timeout_id: NodeJS.Timeout;
    const pollFn = (export_id: UUID) => {
      timeout_id = setTimeout(() => {
        const polling = async () => {
          try {
            const {
              data: { status, documents },
            } = await axios.get<ProductExportStatusSchema>(
              endpoints.v2_storefronts_id_pim_products_export_job_id(
                storefront_id,
                export_id
              )
            );
            switch (status) {
              case "in_progress":
                timeout_id = setTimeout(polling, POLL_TIMEOUT);
                update_poll_timeout(timeout_id);
                break;
              case "failed":
                clearTimeout(timeout_id);
                set_document_urls([]);
                set_is_preparing_files(false);
                on_failed();
                break;
              case "successful":
                clearTimeout(timeout_id);
                set_document_urls(
                  documents.map(({ signed_url, name, id }) => ({
                    name,
                    url: signed_url,
                    id,
                  }))
                );
                set_is_preparing_files(false);
                break;
            }
          } catch (error) {
            clearTimeout(timeout_id);
            set_document_urls([]);
            set_is_preparing_files(false);
          }
        };
        polling();
      }, POLL_TIMEOUT);
      update_poll_timeout(timeout_id);
    };
    if (export_id) {
      set_is_preparing_files(true);
      pollFn(export_id);
    }
    return () => {
      clearTimeout(timeout_id);
    };
  }, [export_id, on_failed, storefront_id, update_poll_timeout]);

  const isContinueButtonDisabled = !document_urls.length;

  return (
    <>
      {document_urls.map((doc) => (
        <Fragment key={doc.id}>
          <H6Bold style={{ margin: 0 }}>{toTitleCase(doc.name)}</H6Bold>
          <div style={{ margin: "8px 0 24px" }}>
            <DocumentDownloadContainer
              type="application/zip"
              link={doc.url}
              documentName={doc.name}
            />
          </div>
        </Fragment>
      ))}
      <TemplateButtonContainer>
        <SecondaryButtonMedium
          onClick={() =>
            dispatch({
              type: "move_back",
              payload: { uploadStage: "download" } as UploadState,
            })
          }
        >
          {t("Back")}
        </SecondaryButtonMedium>
        <PrimaryButtonMedium
          disabled={isContinueButtonDisabled}
          onClick={() =>
            dispatch({
              type: "continue_from_download",
              payload: {
                collectionFileNames: (() => {
                  const collections_obj: Record<string, string> = {};
                  template.collections.forEach(({ id, name }) => {
                    const updated_name = `${template.template_name.toLocaleLowerCase()}_${name.toLocaleLowerCase()}_${file_date}`;
                    collections_obj[updated_name] = id;
                  });
                  return collections_obj;
                })(),
              } as UploadState,
            })
          }
        >
          {t("Continue")}
        </PrimaryButtonMedium>
      </TemplateButtonContainer>
      <GenericDialogBody
        show={is_preparing_files}
        closeDialog={() => set_is_preparing_files(false)}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <LoadingIcon
            width={15}
            height={15}
            fill={theme.primaryButtonTextColor}
          />
          <SoftHeaderMediumDarkText>
            {t(
              "Please wait, your product data is being prepared for download..."
            )}
          </SoftHeaderMediumDarkText>
        </div>
      </GenericDialogBody>
    </>
  );
};
