import React, { useState, useRef, useEffect } from "react";
import styled, { useTheme } from "styled-components/macro";
import { TertiaryButtonMedium } from "../Buttons/Buttons";
import { DropDownModal } from "../DropDownModal/DropDownModal";
import { CaretDownIcon } from "../Icons/Icons";

interface IDropDownButtonProps {
  hideBorder?: boolean | undefined;
}

interface IDropDownProps {
  className?: string | undefined;
  activeItem: string | number;
  clickHandler: (val: any) => void;
  direction: "right" | "left";
  textLeft?: string | undefined;
  textRight?: string | undefined;
  items: number[] | string[] | JSX.Element[];
  showIcon?: boolean | undefined;
  hideBorder?: boolean | undefined;
  placeholder?: string | JSX.Element | undefined;
}

const DropDownWrapper = styled.div<IDropDownButtonProps>`
  display: inline-block;
  position: relative;
  button {
    cursor: pointer;
    color: ${({ theme }) => theme.secondaryTextColor};
    font-size: ${({ theme }) => theme.fontSizes.small};
    border-width: ${({ hideBorder }) => (hideBorder ? "0px" : "1px")};
    &:hover,
    &:focus,
    &:active {
      border-width: ${({ hideBorder }) => (hideBorder ? "0px" : "1px")};
    }
  }
`;
const DropDownModalWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
`;

const FlexTertiaryButtonMedium = styled(TertiaryButtonMedium)`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 3px;
  }
  & > span:last-child {
    margin-right: 0;
  }
`;

export const DropDown = ({
  hideBorder = true,
  direction = "right",
  showIcon = true,
  ...props
}: IDropDownProps) => {
  const [activeItem, setActive] = useState(props.activeItem);
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef(document.createElement("div"));
  const theme = useTheme();
  const handleClick = (e: any) => {
    e.preventDefault();
    setActive(
      Number(e.currentTarget.dataset.name)
        ? Number(e.currentTarget.dataset.name)
        : e.currentTarget.dataset.name
    );
    setShowDropDown(false);
    props.clickHandler(
      Number(e.currentTarget.dataset.name)
        ? Number(e.currentTarget.dataset.name)
        : e.currentTarget.dataset.name
    );
  };

  useEffect(() => {
    setActive(Number(activeItem) ? Number(activeItem) : activeItem);
    setShowDropDown(false);
    props.clickHandler(Number(activeItem) ? Number(activeItem) : activeItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  return (
    <DropDownWrapper
      ref={wrapperRef}
      hideBorder={hideBorder}
      className={props.className}
      style={{
        marginRight: direction === "right" ? "0 !important" : "",
        marginLeft: direction === "right" ? "auto" : "0",
      }}
    >
      <FlexTertiaryButtonMedium onClick={() => setShowDropDown(!showDropDown)}>
        {!!!props.placeholder && (
          <>
            <span style={{ color: theme.secondaryTextColor }}>
              {props.textLeft}{" "}
            </span>
            <span style={{ color: theme.primaryTextColor }}>{activeItem}</span>
            <span style={{ color: theme.primaryTextColor }}>
              {props.textRight}
            </span>
          </>
        )}
        {!!props.placeholder && <span>{props.placeholder}</span>}
        {showIcon && (
          <CaretDownIcon
            fill={theme.secondaryTextColor}
            width={16}
            height={16}
          />
        )}
      </FlexTertiaryButtonMedium>
      {showDropDown && (
        <DropDownModalWrapper>
          <DropDownModal
            items={props.items}
            activeItem={props.activeItem}
            clickHandler={handleClick}
          />
        </DropDownModalWrapper>
      )}
    </DropDownWrapper>
  );
};
