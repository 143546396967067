import { SectionTitle } from "../../../../../components/Form/Form";
import { strings } from "../../../../../util/strings";
import type { DataMutate, IAddress, Tenant } from "../../../../../types/types";
import { SubmitButtonContainer } from "../../../../../layout/FormLayout";
import type { FunctionComponent } from "react";
import React, { useContext, useState } from "react";
import { PrimaryButtonMedium } from "../../../../../components/Buttons/Buttons";
import {
  Notifications,
  useNotifications,
} from "../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { useValidateEmail } from "../../../../../util/useValidateEmail";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Title } from "../../../../../components/Typography/Typography";
import { LocationsList } from "../../../../../components/LocationsList/LocationsList";
import type { LocationInputs } from "./LocationForm";
import { LocationForm } from "./LocationForm";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../components/Modal/Modal";
import styled from "styled-components";

interface LocationData extends LocationInputs {
  type: "Warehouse";
}

export interface IAddressDetails {
  has_transaction: boolean;
  has_price_tiers: boolean;
}

interface EditLocationsProps {
  addresses: IAddress[];
  tenantId: string;
  onSaveChangesClick: () => void;
  mutateTenant: DataMutate<Tenant>;
}

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;

/**
 * A hook to add location.
 * It returns a function, when called, returns details of new address added
 * @param {inputs: LocationInputs; pocType: "existing" | "manual"}
 * @returns
 */
export const useAddLocation = ({
  buyer_tenant_id,
}: {
  buyer_tenant_id: string;
}) => {
  const { validateEmail } = useValidateEmail();
  const { t } = useTranslation();
  const { notifyError } = useNotifications();

  const addLocation = async ({
    inputs,
    pocType,
  }: {
    inputs: LocationInputs;
    pocType: "existing" | "manual";
  }) => {
    if (pocType === "manual") {
      const validEmail =
        inputs.email_address &&
        (await validateEmail({
          email: inputs.email_address,
          source: "manage company info locations edit",
        }));

      if (validEmail) {
        const newAddress: LocationData = {
          ...inputs,
          type: "Warehouse",
          state: inputs.state.value,
          phone_number: `${inputs.country_code?.value}${inputs.phone_number}`,
          country: inputs.country.value,
          is_active: inputs.is_active,
          point_of_contact_id: undefined,
        };

        try {
          const { data } = await Axios.post<IAddress>(
            `/v1/tenants/${buyer_tenant_id}/addresses`,
            newAddress
          );
          return data;
        } catch (error) {
          notifyError(strings(t).submitError, { error });
        }
      }
    } else {
      const newAddress: LocationData = {
        ...inputs,
        type: "Warehouse",
        state: inputs.state.value,
        point_of_contact_id: inputs.point_of_contact_id?.value,
        country: inputs.country.value,
        is_active: inputs.is_active,
        email_address: undefined,
        contact_first_name: undefined,
        contact_last_name: undefined,
        phone_number: undefined,
        country_code: undefined,
      };

      try {
        const { data } = await Axios.post<IAddress>(
          `/v1/tenants/${buyer_tenant_id}/addresses`,
          newAddress
        );
        // Refresh tenant data to refresh the locations table.
        // mutateTenant();
        return data;
      } catch (error) {
        notifyError(strings(t).submitError, { error });
      }
    }
  };
  return addLocation;
};

export const EditLocations: FunctionComponent<EditLocationsProps> = ({
  addresses,
  tenantId,
  onSaveChangesClick,
  mutateTenant,
}) => {
  const { t } = useTranslation();
  const { validateEmail } = useValidateEmail();
  const addLocation = useAddLocation({ buyer_tenant_id: tenantId });
  const { notifyError, notifySuccess } = useContext(Notifications);

  const [addressToEdit, setAddressToEdit] = useState<IAddress>();
  const [editTaxCRM, setEditTaxCRM] = useState<boolean>(false);
  const [addressDetailsToEdit, setAddressDetailsToEdit] =
    useState<IAddressDetails>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const onAddLocation = async (
    inputs: LocationInputs,
    pointOfContactType: string
  ) => {
    const newLocation = await addLocation({
      inputs,
      pocType: pointOfContactType as "existing" | "manual",
    });
    if (newLocation) {
      await mutateTenant();
      return true;
    }
    return false;
  };

  const onEditAddress = async (address: IAddress) => {
    try {
      const addressDetails = await Axios.get<IAddressDetails>(
        `/v1/tenants/${tenantId}/addresses/${address.id}/details`
      );
      setAddressDetailsToEdit(addressDetails.data);
      if (addressDetails.data.has_transaction) {
        if (
          !address.tax_id ||
          address.tax_id === "" ||
          !address.crm_id ||
          address.crm_id === ""
        ) {
          setEditTaxCRM(true);
          setAddressToEdit(address);
        } else {
          setEditTaxCRM(false);
          setShowConfirmationModal(true);
        }
      } else {
        setEditTaxCRM(false);
        setAddressToEdit(address);
      }
    } catch (error) {
      notifyError(t("An error occurred"), { error });
    }
  };

  const onEditAddressSave = async (
    inputs: LocationInputs,
    pointOfContactType: string
  ) => {
    if (pointOfContactType === "manual") {
      const validEmail =
        inputs.email_address &&
        (await validateEmail({
          email: inputs.email_address,
          source: "manage company info locations edit",
        }));

      if (validEmail && addressToEdit) {
        const newAddress = {
          ...inputs,
          phone_number: `${inputs.country_code?.value}${inputs.phone_number}`,
          state: inputs.state.value,
          country: inputs.country.value,
          point_of_contact_id: undefined,
        };
        try {
          await Axios.patch(
            `/v1/tenants/${tenantId}/addresses/${addressToEdit.id}`,
            newAddress
          );
          setAddressToEdit(undefined);
          // Refresh tenant data to refresh the locations table.
          await mutateTenant();
          notifySuccess(t("Address has been updated successfully"));
          return true;
        } catch (error) {
          notifyError(strings(t).submitError, { error });
        }
      }
    } else {
      if (addressToEdit) {
        const newAddress = {
          ...inputs,
          state: inputs.state.value,
          country: inputs.country.value,
          point_of_contact_id: inputs.point_of_contact_id?.value,
          contact_first_name: undefined,
          contact_last_name: undefined,
          email_address: undefined,
          phone_number: undefined,
          country_code: undefined,
        };
        try {
          await Axios.patch(
            `/v1/tenants/${tenantId}/addresses/${addressToEdit.id}`,
            newAddress
          );
          setAddressToEdit(undefined);
          // Refresh tenant data to refresh the locations table.
          await mutateTenant();
          notifySuccess(t("Address has been updated successfully"));
          return true;
        } catch (error) {
          notifyError(strings(t).submitError, { error });
        }
      }
    }
    return false;
  };

  const onDeleteAddress = async (id: string) => {
    try {
      await Axios.delete(`/v1/tenants/${tenantId}/addresses/${id}`);
      // Refresh tenant data to refresh the locations table.
      await mutateTenant();
      notifySuccess(t("Address has been deleted successfully"));
    } catch (error) {
      notifyError(strings(t).submitError, { error });
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <div style={{ width: "478px" }}>
        <LocationForm
          onSubmitFn={onAddLocation}
          submitButtonText={"Add"}
          tenantId={tenantId}
        />
      </div>
      <SectionTitle>{t("Active Locations")}</SectionTitle>
      <LocationsList
        addresses={addresses.filter((address) => address.is_active)}
        onDeleteAddress={onDeleteAddress}
        onEditAddress={onEditAddress}
      />
      {addresses.filter((address) => !address.is_active).length > 0 && (
        <>
          <SectionTitle>{t("Inactive Locations")}</SectionTitle>
          <LocationsList
            addresses={addresses.filter((address) => !address.is_active)}
            onDeleteAddress={onDeleteAddress}
            onEditAddress={onEditAddress}
          />
        </>
      )}
      <SubmitButtonContainer style={{ width: "267px", marginTop: "20px" }}>
        <PrimaryButtonMedium onClick={onSaveChangesClick}>
          {t("Save your changes")}
        </PrimaryButtonMedium>
      </SubmitButtonContainer>

      <SlideOut
        show={!!addressToEdit}
        closeFlyout={() => setAddressToEdit(undefined)}
      >
        <Title>{t("Edit Location")}</Title>
        <LocationForm
          onSubmitFn={onEditAddressSave}
          submitButtonText={t("Save")}
          address={addressToEdit}
          editTaxCRM={editTaxCRM}
          addressDetails={addressDetailsToEdit}
          tenantId={tenantId}
        />
      </SlideOut>
      <Modal
        overlay
        show={showConfirmationModal}
        closeModal={closeConfirmationModal}
        modalWidth={"680px"}
      >
        <ModalWrapper>
          <WarningTitle>
            {t(
              "This Shipping Location is currently used in an active transaction or sample request"
            )}
          </WarningTitle>
          <div>
            {t(
              "This location cannot be updated because it is currently in use on an active transaction or sample request. "
            )}
          </div>
          <div>
            {t(
              "You can edit this location after the transaction or sample request has been completed, or contact Support if you would like to edit this location now."
            )}
          </div>
          <PrimaryButtonMedium
            onClick={closeConfirmationModal}
            style={{ marginTop: "30px" }}
          >
            {t("Cancel")}
          </PrimaryButtonMedium>
        </ModalWrapper>
      </Modal>
    </>
  );
};
