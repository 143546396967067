import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ChipType } from "../Chips/Chips";
import { Chip, ChipContainer } from "../Chips/Chips";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import { SoftHeader } from "../Typography/Typography";
import styled from "styled-components";
import { PrimaryButtonSmall, TextButton } from "../Buttons/Buttons";
import { useMediaQueries } from "../../util/util";

interface IPrefilledChipsProps {
  selectedChips: ChipType[];
  numSelectable?: 1 | "many";
  allChips: any;
  header: string;
  handleClick: any;
  withSelectAll?: boolean;
  ToggleLabel?: string;
  handleApply?: () => void;
  handleClear?: () => void;
  scroll?: boolean;
  // selectedChip?: any;
}

interface IScrollAreaProps {
  scroll: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  button:nth-child(2) {
    margin-left: 20px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const ScrollArea = styled.div<IScrollAreaProps>`
  max-height: ${({ scroll }) => (scroll ? "240px" : "auto")};
  overflow-y: auto;
  display: block;
`;

export const PrefilledSelectAllChips = ({
  numSelectable,
  allChips,
  header,
  handleClick,
  withSelectAll,
  selectedChips,
  handleApply,
  handleClear,
  ToggleLabel = "regions",
  scroll = false,
}: IPrefilledChipsProps): JSX.Element => {
  const [activeChipArray, setActiveChipArray] =
    useState<ChipType[]>(selectedChips);
  const [checked, setChecked] = useState(false);
  const [isDefaultChecked, setIsDefaultChecked] = useState<any>(false);
  const [checkBoxClicked, setCheckBoxClicked] = useState<boolean>(false);

  const { isMediumScreen } = useMediaQueries();
  const { t } = useTranslation();

  useEffect(() => {
    if (allChips.length > 0) {
      if (selectedChips.length === allChips.length) {
        setIsDefaultChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChips]);

  useEffect(() => {
    setChecked(isDefaultChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultChecked]);

  const handleMultiSelectClick = (e: any) => {
    if (
      // if clicked chip is inside activeChipArray
      activeChipArray.some(
        (obj: any) => obj.name === JSON.parse(e.currentTarget.dataset.chip).name
      )
    ) {
      setActiveChipArray(
        activeChipArray.filter(
          (obj: any) =>
            obj.name !== JSON.parse(e.currentTarget.dataset.chip).name
        )
      );
    } else {
      setActiveChipArray([
        ...activeChipArray,
        JSON.parse(e.currentTarget.dataset.chip),
      ]);
    }
  };
  useEffect(() => {
    if (numSelectable === "many" || withSelectAll) {
      handleClick(
        activeChipArray.map((item: any) => {
          return item;
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChipArray]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  });

  const handleCheckBoxClick = () => {
    if (!checkBoxClicked) {
      setChecked(!isDefaultChecked);
      setCheckBoxClicked(true);
      if (!isDefaultChecked === false) {
        setActiveChipArray([]);
        handleClick([]);
      } else {
        setActiveChipArray(allChips);
        handleClick(allChips);
      }
    } else {
      setChecked(!checked);
      if (checked === false) {
        setActiveChipArray(allChips);
        handleClick(allChips);
      } else if (checked === true) {
        setActiveChipArray([]);
        handleClick([]);
      }
      setCheckBoxClicked(true);
    }
  };

  return (
    <>
      <SoftHeader
        style={{
          marginBottom: isMediumScreen ? "20px" : "5px",
          marginTop: isMediumScreen ? "8px" : "0",
        }}
      >
        {header}
      </SoftHeader>
      <TopWrapper>
        {withSelectAll && (
          <div style={{ display: "flex" }}>
            <ToggleSwitch
              onClick={handleCheckBoxClick}
              label={t("Select all ") + ToggleLabel}
              isChecked={isDefaultChecked}
              name="select_all"
            ></ToggleSwitch>
          </div>
        )}
        <ButtonContainer>
          {handleClear && (
            <TextButton onClick={handleClear}>{t("Clear")}</TextButton>
          )}
          {handleApply && (
            <PrimaryButtonSmall onClick={handleApply}>
              {t("Apply")}
            </PrimaryButtonSmall>
          )}
        </ButtonContainer>
      </TopWrapper>
      <ScrollArea scroll={scroll}>
        <ChipContainer>
          {allChips.map((chip: any) => {
            if (activeChipArray.some((obj: any) => obj.name === chip.name)) {
              return (
                <Chip
                  name={chip.name}
                  data-chip={JSON.stringify(chip)}
                  key={chip.name}
                  active={true}
                  onClick={handleMultiSelectClick}
                >
                  {chip.name}
                </Chip>
              );
            } else {
              return (
                <Chip
                  name={chip.name}
                  data-chip={JSON.stringify(chip)}
                  key={chip.name}
                  active={false}
                  onClick={handleMultiSelectClick}
                >
                  {chip.name}
                </Chip>
              );
            }
          })}
        </ChipContainer>
      </ScrollArea>
    </>
  );
};
