import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation, useParams } from "react-router-dom";
import type { DefaultTheme } from "styled-components";
import { ThemeContext } from "styled-components";
import { Auth } from "../../components/Auth";
import {
  PrimaryButtonFitContainer,
  TertiaryButtonLargePrimaryBG,
} from "../../components/Buttons/Buttons";
import { LinkUnstyled } from "../../components/LinkUnstyled/LinkUnstyled";
import { Notifications } from "../../components/Notifications/NotificationsContext";
import {
  PasswordToggleTextField,
  TextField,
} from "../../components/TextFields/TextFields";
import {
  H3DarkText,
  H6,
  SoftHeader2,
} from "../../components/Typography/Typography";
import { Form } from "../../layout/FormLayout";
import { LogoWrapper } from "../../layout/publicLayout";
import {
  AuthContainer,
  AuthForgotContainer,
  AuthLogo,
  AuthPage,
  GetStartedContainer,
  SectionDivider,
} from "../../layout/publicPageLayout";
import type { IRoutePaths, TOTPSchema, User } from "../../types/types";
import { useRoutePath } from "../../util/Routing";
import {
  useFormWrapper,
  useMediaQueries,
  useStoreState,
} from "../../util/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { zodRequiredString } from "../../util/zod.util";
import { StringParam, useQueryParams } from "use-query-params";

export type LoginCredentials = {
  username: string;
  password: string;
};

type RedirectByRoleProps = {
  user: User;
  routePaths: IRoutePaths;
};

export function redirectByRole({
  user,
  routePaths,
}: RedirectByRoleProps): JSX.Element {
  const { adminPath, storePath } = routePaths;
  // TODO: make this exhaustive when we have screens for every role.
  switch (user.rbac_role) {
    case "buyer_standard":
    case "distributor":
      return <Redirect to={`${storePath}/portfolio`} />;
    case "seller_admin":
      return <Redirect to={`${adminPath}/dashboard`} />;
    case "seller_standard":
      return <Redirect to={`${adminPath}/dashboard`} />;
    case "buyer_admin":
    case "distributor_admin":
      return <Redirect to={`${adminPath}/pim/products`} />;
    default:
      // This is a noop so that we don't have to update the type in
      // Routes.tsx to JSX.Element | undefined,
      // future exhaustive pattern matching should remove this.
      return <Redirect to={storePath} />;
  }
}

export const AuthLogoWrapper = () => {
  const { isMediumScreen } = useMediaQueries();
  const theme: DefaultTheme = useContext(ThemeContext);
  return (
    <>
      {!isMediumScreen && (
        <LogoWrapper>
          {theme.portalLogoUrl && <AuthLogo src={theme.portalLogoUrl} />}
        </LogoWrapper>
      )}
    </>
  );
};

export function Login() {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, errors } = useFormWrapper({
    resolver: zodResolver(
      z.object({
        username: zodRequiredString(t),
        password: zodRequiredString(t),
      })
    ),
  });
  const { initiateLogin, user } = useContext(Auth);
  const [loading, setLoading] = useState(false);
  const [totp_details, set_totp_details] = useState<TOTPSchema>();
  const { notifyError } = useContext(Notifications);
  const { tenantSlug } = useParams<{ tenantSlug: string }>();
  const routePaths = useRoutePath();
  const { storePath } = routePaths;
  const location = useLocation();

  const [query] = useQueryParams({
    redirect_url: StringParam,
  });

  const onSubmit = async (values: LoginCredentials) => {
    setLoading(true);
    const { loginError, unauthenticated_user } = await initiateLogin(
      values,
      query?.redirect_url ? query.redirect_url : undefined
    );

    if (loginError) {
      notifyError("There was an error logging in, please try again");
      setLoading(false);
    }
    if (unauthenticated_user) {
      setLoading(false);
      set_totp_details(unauthenticated_user);
    }
  };

  const { storefront_metadata } = useStoreState();

  const { sso_only } = storefront_metadata;

  const handleSSOClick = async (provider: string) => {
    try {
      let redirect_uri = `${window.location.origin}${storePath}/login`;
      const oldPath = sessionStorage.getItem("initialPath");
      if (oldPath) {
        sessionStorage.removeItem("initialPath");
        redirect_uri = `${window.location.origin}${oldPath}`;
      }
      const query = new URLSearchParams({
        redirect_uri,
        storefront_id: storefront_metadata.id,
        provider,
      });

      const auth_url = `/api/v1/sso/auth_url?${query.toString()}`;

      window.location.href = auth_url;
    } catch (error) {
      console.error(error);
      notifyError(
        "There was an error logging in via SSO, please contact the administrator"
      );
    }
  };

  const oldPath = sessionStorage.getItem("initialPath");
  if (user && oldPath) {
    // ensures that item is removed from local storage AFTER redirect happens
    // setTimeout garbage is collected  on next reload, which is likely after redirect
    // hence, no need to clear timeout here.
    setTimeout(() => sessionStorage.removeItem("initialPath"));
    return <Redirect to={oldPath} />;
  }
  if (user && tenantSlug) {
    return redirectByRole({ user, routePaths: routePaths });
  }

  const isManualLoginPage = location.pathname.includes("manual_login");

  if (sso_only && storefront_metadata.sso_provider && !isManualLoginPage) {
    handleSSOClick(storefront_metadata.sso_provider);
  }

  if (totp_details) {
    return (
      <Redirect
        to={{
          pathname: `${storePath}/two-factor-auth-login`,
          search: query?.redirect_url
            ? `?redirect_url=${query.redirect_url}`
            : "",
          state: { ...totp_details },
        }}
      />
    );
  }

  return (
    <AuthPage>
      <AuthContainer>
        <AuthLogoWrapper />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <H3DarkText>{t("Sign In")}</H3DarkText>
          <TextField
            name="username"
            label={t("User Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <PasswordToggleTextField
            name="password"
            autoComplete="current-password"
            label={t("Password")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
          />
          <PrimaryButtonFitContainer
            style={{ marginTop: 0 }}
            type="submit"
            loading={loading}
          >
            {t("Sign In")}
          </PrimaryButtonFitContainer>
          <AuthForgotContainer>
            <LinkUnstyled to={`${storePath}/forgot-password`}>
              <span>{t("Forgot Password?")}</span>
            </LinkUnstyled>
          </AuthForgotContainer>
          {storefront_metadata.sso_provider && (
            <GetStartedContainer>
              <SectionDivider>
                <H6>{t("OR")}</H6>
              </SectionDivider>
              <TertiaryButtonLargePrimaryBG
                style={{ marginTop: "15px", padding: "17px 33px" }}
                as="a"
                onClick={() =>
                  handleSSOClick(storefront_metadata.sso_provider!)
                }
              >
                {t("Sign in with SSO")}
              </TertiaryButtonLargePrimaryBG>
            </GetStartedContainer>
          )}
          <GetStartedContainer>
            <SectionDivider>
              <H6>{t("OR")}</H6>
            </SectionDivider>
            <SoftHeader2>{t(`Don't have an account yet?`)}</SoftHeader2>
            <TertiaryButtonLargePrimaryBG
              style={{ marginTop: "15px", padding: "17px 33px" }}
              as="a"
              href={`${storePath}/register`}
            >
              {t("Get Started")}
            </TertiaryButtonLargePrimaryBG>
          </GetStartedContainer>
        </Form>
      </AuthContainer>
    </AuthPage>
  );
}
