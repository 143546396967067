import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonLarge,
} from "../../../../components/Buttons/Buttons";
import {
  LocationIcon,
  UserIcon,
  WarningIcon,
} from "../../../../components/Icons/Icons";
import {
  InfoBlockRow,
  InfoBlockSmall,
} from "../../../../components/InfoBlocks/InfoBlocks";
import { H3 } from "../../../../components/Typography/Typography";
import type { IAddress, Tenant, User } from "../../../../types/types";
import {
  ContentWrapper,
  PageWrapper,
  SettingsCard,
} from "../../../../layout/portalPageLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useSWR from "swr";
import { DelayedSpinner } from "../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../components/Error";
import {
  AddressBlock,
  ContactBlock,
  LocationsList,
  MultiLineBlock,
} from "../../../../components/LocationsList/LocationsList";
import { endpoints } from "../../../../endpoints";
import {
  makeUrlWithParams,
  useFormWrapper,
  useStoreState,
} from "../../../../util/util";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  SectionTitle,
  WarningMessageBox,
} from "../../../../components/Form/Form";
import { EditAddressForm } from "./Edit/EditAddressForm";
import { EditGeneralInfo } from "./Edit/EditGeneralInfo";
import { EditLocations } from "./Edit/EditLocations";
import { EditHQAddressForm } from "./Edit/EditHQAddressForm";
import { formateInternationalPhoneNumber } from "../../../../util/phone";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import type { AxiosError } from "axios";
import axios from "axios";
import { useAuthContext } from "../../../../components/Auth";
import { Form } from "../../../../layout/FormLayout";
import { ConfirmationSpacer } from "../../../public/SampleRequestCart/SampleRequestCart";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { ColoredTextOnError } from "../../../../util/util-components";
import { strings } from "../../../../util/strings";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InfoBlockInnerRow = styled.div`
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

/**
 * The Manage > CompanyInfo page used by these kinds of users:
 *   - Seller Admin
 *   - Buyer Admin
 */
export function CompanyInfo({ user }: { user: User }) {
  const {
    storefront_id,
    storefront_metadata: { edition },
  } = useStoreState();
  const [editGeneralSection, setEditGeneralSection] = useState<boolean>(false);

  const [editLocations, setEditLocations] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const [editHQAddress, setEditHQAddress] = useState(false);
  const [isActivatingBuyerLoading, setIsActivatingBuyerLoading] =
    useState(false);
  const { t } = useTranslation();
  const { roleIsSomeKindOfBuyer, updateUser } = useAuthContext();
  const { notifyError, notifySuccess } = useNotifications();

  const {
    register: activateFormRegister,
    errors: activateFormError,
    handleSubmit: handleActivateFormSubmit,
  } = useFormWrapper();

  const {
    data: tenant,
    error: tenantError,
    mutate: mutateTenant,
  } = useSWR<Tenant>(
    makeUrlWithParams(
      endpoints.v1_storefronts_id_tenants_id(storefront_id, user.tenant_id),
      {
        show_inactive_address: "true",
      }
    )
  );

  const closeEditGeneralInfo = async () => {
    await mutateTenant();
    setEditGeneralSection(false);
  };

  const is_tenant_info_complete = (tenant: Tenant) =>
    Boolean(tenant.name) && Boolean(tenant.doing_business_as);

  const is_address_info_complete = (address: IAddress) =>
    Boolean(address.address1) &&
    Boolean(address.city) &&
    Boolean(address.country) &&
    Boolean(address.state) &&
    (Boolean(address.point_of_contact_id) || Boolean(address.contact_name));

  if (tenantError) {
    return <ErrorPlaceholder message="Failed to fetch tenant" />;
  } else if (!tenant) {
    // Show loader while awaiting response to tenant request.
    return <DelayedSpinner />;
  } else {
    const locationsAddresses = tenant.addresses.filter(
      (address) => address.type === "Warehouse"
    );

    const billingAddress = tenant.addresses.find(
      (address) => address.type === "Sold To Address"
    );

    const hqAddress = tenant.addresses.find(
      (address) => address.type === "Headquarters Address"
    );

    const activateBuyer = async () => {
      const incomplete_sections: string[] = [];
      const active_location_addresses = locationsAddresses.filter(
        (addr) => addr.is_active
      );
      if (!is_tenant_info_complete(tenant)) {
        incomplete_sections.push("General");
      }
      if (hqAddress && !is_address_info_complete(hqAddress)) {
        incomplete_sections.push("Headquarter");
      }
      if (billingAddress && !is_address_info_complete(billingAddress)) {
        incomplete_sections.push("Billing Address");
      }
      if (
        active_location_addresses.some(
          (address) => !is_address_info_complete(address)
        )
      ) {
        incomplete_sections.push("Shipping Address");
      }
      const inactive_shipping_addresses_msg =
        edition === "pim"
          ? ""
          : locationsAddresses.every((addrs) => !addrs.is_active)
          ? t("Activate one or more shipping addresses")
          : "";
      const unverified_sections_str = [...incomplete_sections]
        .filter((section) => section !== "General")
        .join(",");
      const unverified_sections_msg = (() => {
        if (incomplete_sections.length === 0) {
          return "";
        }
        if (
          incomplete_sections.length === 1 &&
          incomplete_sections[0] === "General"
        ) {
          return t("Please enter the doing business name (DBA).");
        }
        if (incomplete_sections.includes("General")) {
          return t([
            `Please enter the doing business name (DBA) and verify your ${unverified_sections_str} information`,
          ]);
        }
        return t([`Please verify your ${unverified_sections_str} information`]);
      })();
      if (
        Boolean(unverified_sections_msg) ||
        Boolean(inactive_shipping_addresses_msg)
      ) {
        Boolean(unverified_sections_msg) &&
          notifyError(unverified_sections_msg);
        Boolean(inactive_shipping_addresses_msg) &&
          notifyError(inactive_shipping_addresses_msg);
      } else {
        setIsActivatingBuyerLoading(true);
        try {
          await axios.patch<Tenant>(
            endpoints.v1_storefronts_id_tenants_id(
              storefront_id,
              user.tenant_id
            ),
            { is_buyer_activated: true }
          );
          updateUser({
            ...user,
            is_buyer_activated: true,
          });
          notifySuccess(t("Buyer activated successfully"));
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Buyer could not be activated. Please try again later."),
            {
              error,
            }
          );
        } finally {
          setIsActivatingBuyerLoading(false);
        }
      }
    };

    const activateAccountRow = () =>
      user.is_buyer_activated ? (
        <></>
      ) : (
        <Form noValidate onSubmit={handleActivateFormSubmit(activateBuyer)}>
          <ConfirmationSpacer>
            <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
              <div style={{ width: "22px", marginRight: "15px" }}>
                <CheckBoxNoLabel
                  name="confirmation"
                  id="confirmation"
                  ref={activateFormRegister({
                    required: strings(t).thisIsARequiredField,
                    validate: (val: boolean) => val,
                  })}
                  error={!!activateFormError.confirmation?.message}
                />
              </div>
              <CheckBoxFinePrintLabel htmlFor="confirmation">
                <ColoredTextOnError
                  isError={!!activateFormError.confirmation?.message}
                >
                  {t("I confirm all the information I provided is accurate.")}
                </ColoredTextOnError>
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>
          </ConfirmationSpacer>
          <PrimaryButtonLarge loading={isActivatingBuyerLoading} type="submit">
            {t("Activate Account")}
          </PrimaryButtonLarge>
        </Form>
      );

    return (
      <PageWrapper narrow>
        <ContentWrapper>
          {!user.is_buyer_activated ? (
            <WarningMessageBox>
              <WarningIcon width={18} />
              {t(
                "Please verify your general information, shipping and billing addresses"
              )}
            </WarningMessageBox>
          ) : (
            <></>
          )}
          <SettingsCard>
            <Row>
              <H3>{t("General")}</H3>
              {!editGeneralSection && (
                <EditButton
                  testid={"edit-general-section"}
                  onClick={() => setEditGeneralSection(true)}
                  title={t("Edit General Company Info")}
                />
              )}
              {editGeneralSection && (
                <CancelButtonSmall
                  onClick={() => setEditGeneralSection(false)}
                />
              )}
            </Row>
            {!editGeneralSection && (
              <>
                <InfoBlockRow>
                  <div>
                    <InfoBlockInnerRow>
                      <InfoBlockSmall
                        header={t("Company Name")}
                        content={tenant.name}
                      />
                    </InfoBlockInnerRow>
                    <InfoBlockInnerRow>
                      <InfoBlockSmall
                        header={t("Doing Business As")}
                        content={tenant.doing_business_as}
                      />
                    </InfoBlockInnerRow>
                  </div>
                  <div>
                    {!roleIsSomeKindOfBuyer &&
                      tenant.crm_id &&
                      tenant.crm_id !== "" && (
                        <InfoBlockInnerRow>
                          <InfoBlockSmall
                            header={t("CRM ID")}
                            content={tenant.crm_id ?? "--"}
                          />
                        </InfoBlockInnerRow>
                      )}
                    <InfoBlockInnerRow>
                      <InfoBlockSmall
                        header={t("Primary Contact")}
                        content={
                          <MultiLineBlock>
                            <div>
                              {tenant.primary_contact &&
                                `${tenant.primary_contact?.first_name} ${tenant.primary_contact?.last_name}`}
                            </div>
                            <div>{tenant.primary_contact?.email_address}</div>
                            <div>
                              <a
                                href={`tel:${tenant.primary_contact.phone_number}`}
                              >
                                {formateInternationalPhoneNumber(
                                  tenant.primary_contact.phone_number
                                )}
                              </a>
                            </div>
                          </MultiLineBlock>
                        }
                        icon={<UserIcon width={16} />}
                      />
                    </InfoBlockInnerRow>
                  </div>
                </InfoBlockRow>
              </>
            )}
            {editGeneralSection && (
              <EditGeneralInfo
                tenant={tenant}
                closeEditGeneralSection={closeEditGeneralInfo}
                is_buyer_activated={user.is_buyer_activated}
              />
            )}
          </SettingsCard>
          <SettingsCard>
            <Row>
              <H3>{t("Headquarter")}</H3>
              {!editHQAddress && hqAddress && (
                <EditButton
                  testid={"edit-headquarter-address"}
                  onClick={() => setEditHQAddress(true)}
                  title={t("Edit HeadQuarter")}
                />
              )}
              {editHQAddress && hqAddress && (
                <CancelButtonSmall onClick={() => setEditHQAddress(false)} />
              )}
            </Row>
            {editHQAddress && hqAddress && (
              <EditHQAddressForm
                address={hqAddress}
                setEdit={setEditHQAddress}
                tenantId={tenant.id}
                mutateTenant={mutateTenant}
              />
            )}
            <InfoBlockRow>
              {!editHQAddress && hqAddress && (
                <>
                  <InfoBlockSmall
                    header={t("Address")}
                    content={<AddressBlock address={hqAddress} />}
                    icon={<LocationIcon width={16} />}
                  />
                  <InfoBlockSmall
                    header={t("Point of Contact")}
                    content={<ContactBlock address={hqAddress} />}
                    icon={<UserIcon width={16} />}
                  />
                </>
              )}
            </InfoBlockRow>
          </SettingsCard>
          {edition !== "pim" ? (
            <>
              <SettingsCard>
                <Row>
                  <H3>{t("Billing")}</H3>
                  {!editBilling && billingAddress && (
                    <EditButton
                      testid={"edit-billing-address"}
                      onClick={() => setEditBilling(true)}
                      title={t("Edit Billing")}
                    />
                  )}
                  {editBilling && billingAddress && (
                    <CancelButtonSmall onClick={() => setEditBilling(false)} />
                  )}
                </Row>
                {editBilling && billingAddress && (
                  <EditAddressForm
                    address={billingAddress}
                    setEdit={setEditBilling}
                    tenantId={tenant.id}
                    mutateTenant={mutateTenant}
                  />
                )}
                <InfoBlockRow>
                  {!editBilling && billingAddress && (
                    <>
                      <InfoBlockSmall
                        header={t("Address")}
                        content={<AddressBlock address={billingAddress} />}
                        icon={<LocationIcon width={16} />}
                      />
                      <InfoBlockSmall
                        header={t("Point of Contact")}
                        content={<ContactBlock address={billingAddress} />}
                        icon={<UserIcon width={16} />}
                      />
                    </>
                  )}
                </InfoBlockRow>
              </SettingsCard>
              <SettingsCard>
                <Row>
                  <H3>{t("Shipping Locations")}</H3>
                  {!editLocations && (
                    <EditButton
                      testid={"edit-locations"}
                      title={"Edit Locations"}
                      onClick={() => setEditLocations(true)}
                    />
                  )}
                  {editLocations && (
                    <CancelButtonSmall
                      onClick={() => setEditLocations(false)}
                    />
                  )}
                </Row>
                {editLocations ? (
                  <EditLocations
                    addresses={locationsAddresses}
                    tenantId={user.tenant_id}
                    onSaveChangesClick={() => setEditLocations(false)}
                    mutateTenant={mutateTenant}
                  />
                ) : (
                  <>
                    <SectionTitle>{t("Active Locations")}</SectionTitle>
                    <LocationsList
                      addresses={locationsAddresses.filter(
                        (address) => address.is_active
                      )}
                    />
                    {locationsAddresses.filter((address) => !address.is_active)
                      .length > 0 && (
                      <>
                        <SectionTitle>{t("Inactive Locations")}</SectionTitle>
                        <LocationsList
                          addresses={locationsAddresses.filter(
                            (address) => !address.is_active
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              </SettingsCard>
              {activateAccountRow()}
            </>
          ) : (
            activateAccountRow()
          )}
        </ContentWrapper>
      </PageWrapper>
    );
  }
}
