import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../../../../util/Routing";
import type { UUID } from "../../../../../types/types";
import { NavBarTabs } from "../../../../../layout/shared/Nav/NavBar";

enum PIMTemplateClassification {
  GROUPS,
  COLLECTIONS,
  ASSETS,
  ACTIVITY,
}

/**
 * Potential Use case:
 * <PageWrapper>
      <AttributesNav tabIndex={0} />
      {other components come here}
    </PageWrapper>
 * @param param0 
 * @returns 
 */

export const TemplatesNav = ({
  tabIndex,
  templateID,
  pageTitle,
}: {
  tabIndex: PIMTemplateClassification;
  templateID: UUID;
  pageTitle: string;
}) => {
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();
  const history = useHistory();
  const handleTabsChange = (index: PIMTemplateClassification) => {
    // TODO: reflect the correct path
    history.push(
      `${adminPath}/pim/templates/${templateID}/${PIMTemplateClassification[
        index
      ].toLowerCase()}`
    );
  };
  const tabs = [t("Groups"), t("Collections"), t("Assets"), t("Activity")];
  return (
    <NavBarTabs
      pageTitle={pageTitle}
      handleTabsChange={handleTabsChange}
      tabs={tabs}
      tabIndex={tabIndex}
    />
  );
};
