import type {
  ExternalRouteType,
  INavLayout,
  NavType,
  RouteType,
  User,
} from "../../../types/types";
import type { INavStyles } from "../../shared";
import { Badge, NavItemWrapper, NavWrapper } from "../../shared";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import type { DefaultTheme } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";
import { Auth } from "../../../components/Auth";
import type { IIconProps } from "../../../components/Icons/Icons";
import { CaretDownIcon } from "../../../components/Icons/Icons";
import { Store } from "../../../Store";
import { useRoutePath } from "../../../util/Routing";
import { useInAppNotifications, useStoreState } from "../../../util/util";
import { IntersectionObserverWrapper } from "../../../util/TopNav";
import { t } from "i18next";
import { TrimmedSpan } from "../../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";

const NAV_NAME_LIMIT = 19;
const NAV_BADGE_NAME_LIMIT = 16;

type NavItemProps = {
  Icon?: ({ fill, width, height }: IIconProps) => JSX.Element;
  index: string | number | any;
  name: string;
  path: string;
  fill: string;
  navType?: NavType;
  collapsedSideNav?: boolean;
  navLayout?: string;
  badge?: string;
};

type IAccordionHeaderProps = {
  isOpened?: boolean;
  collapsedSideNav?: boolean;
};

const AccordionItemWrapper = styled.div`
  position: relative;
`;

const AccordionItemHeader = styled.div<IAccordionHeaderProps>`
  color: ${({ theme }) => theme.navLinkColor};
  width: 100%;
  transition: all 0.2 ease-out;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding: ${({ collapsedSideNav }) =>
    collapsedSideNav ? "5px 2px 5px 4px" : "5px 0px"};
  cursor: pointer;
  span {
    text-indent: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "-999999px" : "none"};
    white-space: nowrap;
    transition: all 0.2 ease-out;
    display: block;
    margin: 0;
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    padding: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "6px 10px" : "6px 20px"};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
  svg:nth-child(2) {
    transition: all 0.2s ease-out;
    transform: ${({ isOpened }) => `rotate(${isOpened ? "0deg" : "-90deg"})`};
    margin-right: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "3px" : "12px"};
  }
`;

const AccordionItemContent = styled.div<IAccordionHeaderProps>`
  transition: all 0.2s ease-out;
  padding-left: 5px;
  background: ${({ theme }) => theme.navBG};
  position: ${({ collapsedSideNav }) =>
    collapsedSideNav ? "absolute" : "relative"};
  top: ${({ collapsedSideNav }) => (collapsedSideNav ? "0" : "none")};
  left: ${({ collapsedSideNav }) => (collapsedSideNav ? "100%" : "none")};
  box-shadow: ${({ collapsedSideNav }) =>
    collapsedSideNav ? "7px 3px 14px -4px rgba(0, 0, 0, 0.27)" : "none"};
  cursor: pointer;
  a {
    svg {
      margin-right: 10px;
    }
    span {
      text-indent: 0;
    }
  }
`;

const isTextOverflow = ({ badge, name }: { badge: number; name: string }) =>
  badge > 0 ? name.length > NAV_BADGE_NAME_LIMIT : name.length > NAV_NAME_LIMIT;

const NavItem = ({
  Icon,
  index,
  name,
  path,
  fill,
  navType,
  collapsedSideNav,
  navLayout,
  badge,
}: NavItemProps) => {
  const location = useLocation();
  const { tenantSlug } = useParams<{ tenantSlug: string }>();
  const { accountPath, storePath } = useRoutePath();
  const tenantPath = path.replace(/:tenantSlug/, tenantSlug);
  const theme: DefaultTheme = useContext(ThemeContext);

  const {
    storeState: { storefront_metadata },
  } = useContext(Store);

  const isProductDetailPage = (name: string) => {
    return (
      name === t("Portfolio") &&
      (location.pathname.indexOf(`${accountPath}/product/`) === 0 ||
        location.pathname.indexOf(`${storePath}/product/`) === 0)
    );
  };
  const isActive = (name: string) => {
    return (
      isProductDetailPage(name) ||
      (location.pathname === tenantPath &&
        !isProductDetailPage(name) &&
        tenantPath !== storePath)
    );
  };

  const getCustomNavStyle = (navName: string): INavStyles | undefined => {
    if (storefront_metadata.homepage_settings?.nav_settings) {
      switch (navName) {
        case "Home":
          return storefront_metadata.homepage_settings.nav_settings.home;
        case "Portfolio":
          return storefront_metadata.homepage_settings.nav_settings.portfolio;
        case "Contact Us":
          return storefront_metadata.homepage_settings.nav_settings.contact_us;
        default:
          return undefined;
      }
    }
    return undefined;
  };

  return (
    <NavItemWrapper
      collapsedSideNav={collapsedSideNav}
      navStyles={navLayout === "public" ? getCustomNavStyle(name) : undefined}
      data-targetid={navLayout === "public" ? name : undefined}
    >
      {navType === "external" ? (
        <a href={path}>
          <TrimmedSpan data-tip={name} data-for={`public_${name}_tooltip`}>
            {name}
          </TrimmedSpan>
          {isTextOverflow({
            name,
            badge: 0,
          }) && (
            <ReactTooltip
              id={`public_${name}_tooltip`}
              place="right"
              data-html={true}
              effect="solid"
              offset={{ left: 6 }}
              backgroundColor={theme.tooltipBG}
            />
          )}
        </a>
      ) : (
        <NavLink
          key={index}
          to={tenantPath}
          exact
          isActive={() => isActive(name)}
          data-tip={name}
          data-for={`collapsed_${name}_tooltip`}
        >
          {Icon && (
            <Icon
              fill={isActive(name) ? theme.navIconActive : fill}
              width={25}
              height={25}
            />
          )}
          <TrimmedSpan style={{ width: "fit-content" }}>{name}</TrimmedSpan>
          {badge && badge !== "0" && <Badge>{badge}</Badge>}
          {isTextOverflow({ name, badge: Number(badge) }) ||
          collapsedSideNav ? (
            <ReactTooltip
              id={`collapsed_${name}_tooltip`}
              place="right"
              data-html={true}
              effect="solid"
              offset={{ left: 6 }}
              backgroundColor={theme.tooltipBG}
            />
          ) : (
            <></>
          )}
        </NavLink>
      )}
    </NavItemWrapper>
  );
};

const AccordionNav = ({
  parent,
  navRoutes,
  translationGuard,
  collapsedSideNav,
  getNavBadge,
  navLayout,
  AccordionIcon,
}: {
  parent: string;
  navRoutes: (RouteType | ExternalRouteType)[];
  navLayout: INavLayout;
  translationGuard: (maybeString: string | undefined) => string | undefined;
  collapsedSideNav?: boolean;
  getNavBadge: (navName: string | undefined) => string | undefined;
  AccordionIcon?:
    | (({ fill, width, height }: IIconProps) => JSX.Element)
    | undefined;
}) => {
  const theme: DefaultTheme = useContext(ThemeContext);
  const wrapperRef = useRef(document.createElement("div"));
  const [navStatus, setNavStatus] = useState(useNavSessionStorage());
  const [isOpened, setIsOpened] = useState(
    navStatus.openedParents.includes(parent)
  );

  // Calculate total notifications for top-level item
  const totalNotifications = navRoutes.reduce(
    (acc, route) =>
      acc + parseInt(getNavBadge(route.nav?.[navLayout]?.name) || "0", 10),
    0
  );
  const updateNavParentStatus = (navParent: string) => {
    if (navStatus?.openedParents.includes(navParent)) {
      let newNavStatus: NavStatus = JSON.parse(
        sessionStorage.getItem("navStatus") ?? ""
      );
      newNavStatus.openedParents = newNavStatus.openedParents.filter(
        (parent) => parent !== navParent
      );
      sessionStorage.setItem("navStatus", JSON.stringify(newNavStatus));
      setNavStatus(newNavStatus);
      setIsOpened(false);
    } else {
      let newNavStatus: NavStatus = JSON.parse(
        sessionStorage.getItem("navStatus") ?? ""
      );
      newNavStatus.openedParents = [...newNavStatus.openedParents, navParent];
      sessionStorage.setItem("navStatus", JSON.stringify(newNavStatus));
      setNavStatus(newNavStatus);
      setIsOpened(true);
    }
  };

  const toggleHandler = () => updateNavParentStatus(parent);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        let newNavStatus: NavStatus = JSON.parse(
          sessionStorage.getItem("navStatus") ?? ""
        );
        newNavStatus.openedParents = newNavStatus.openedParents.filter(
          (navParent) => navParent !== parent
        );
        sessionStorage.setItem("navStatus", JSON.stringify(newNavStatus));
        setNavStatus(newNavStatus);
        setIsOpened(false);
      }
    };

    if (collapsedSideNav) {
      document.addEventListener("click", handleClickOutside, false);
      let newNavStatus: NavStatus = JSON.parse(
        sessionStorage.getItem("navStatus") ?? ""
      );
      newNavStatus.openedParents = newNavStatus.openedParents.filter(
        (navParent) => navParent !== parent
      );
      sessionStorage.setItem("navStatus", JSON.stringify(newNavStatus));
      setNavStatus(newNavStatus);
      setIsOpened(false);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [parent, collapsedSideNav]);

  return (
    <AccordionItemWrapper ref={wrapperRef}>
      <AccordionItemHeader
        onClick={toggleHandler}
        isOpened={isOpened}
        collapsedSideNav={collapsedSideNav}
        data-tip={translationGuard(parent)}
        data-for={`accordion_${parent}_tooltip`}
      >
        {collapsedSideNav && AccordionIcon ? (
          <AccordionIcon fill="#000000" width={22} height={22} />
        ) : (
          <div style={{ display: "flex", width: "85%" }}>
            <TrimmedSpan>{translationGuard(parent)}</TrimmedSpan>
            {!isOpened && totalNotifications > 0 && (
              <div>
                <Badge>{totalNotifications}</Badge>
              </div>
            )}
          </div>
        )}
        <CaretDownIcon
          width={collapsedSideNav ? 14 : 18}
          height={collapsedSideNav ? 14 : 18}
        />
        {(collapsedSideNav && !isOpened) ||
        (!collapsedSideNav &&
          isTextOverflow({
            name: translationGuard(parent) ?? "",
            badge: totalNotifications,
          })) ? (
          <ReactTooltip
            id={`accordion_${parent}_tooltip`}
            place="right"
            data-html={true}
            effect="solid"
            offset={{ left: 6 }}
            backgroundColor={theme.tooltipBG}
          />
        ) : (
          <></>
        )}
      </AccordionItemHeader>
      {isOpened && (
        <AccordionItemContent collapsedSideNav={collapsedSideNav}>
          {navRoutes.map((route, index) => {
            return (
              <NavItem
                index={index}
                key={index}
                path={route.path}
                name={translationGuard(route.nav?.[navLayout]?.name) ?? ""}
                fill={theme.navLinkColor}
                Icon={route.nav?.[navLayout]?.icon}
                navType={route.navType}
                collapsedSideNav={collapsedSideNav}
                navLayout={navLayout}
                badge={getNavBadge(route.nav?.[navLayout]?.name)}
              />
            );
          })}
        </AccordionItemContent>
      )}
    </AccordionItemWrapper>
  );
};

type NavProps = {
  navRoutes: (RouteType | ExternalRouteType)[];
  navLayout: INavLayout;
  collapsedSideNav?: boolean;
};

type AccordionNavRoutes = {
  navRoutes: (RouteType | ExternalRouteType)[];
  navParent?: string;
};

type NavStatus = {
  collapsed: boolean;
  openedParents: string[];
};

function getNavSessionStorage(): NavStatus {
  const stored = sessionStorage.getItem("navStatus");
  if (!stored) {
    return {
      collapsed: false,
      openedParents: [],
    };
  }
  return JSON.parse(stored);
}

function useNavSessionStorage() {
  const [value] = useState(getNavSessionStorage());

  useEffect(() => {
    sessionStorage.setItem("navStatus", JSON.stringify(value));
  }, [value]);

  return value;
}

export const Nav = memo(function Nav({
  navRoutes,
  navLayout,
  collapsedSideNav,
}: NavProps) {
  const theme: DefaultTheme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { storefront_id, slug } = useStoreState();
  const { user } = useContext(Auth);
  const { notifications } = useInAppNotifications(storefront_id, user as User);
  const [accordionNavRoutes, setAccordionNavRoutes] = useState<
    AccordionNavRoutes[]
  >([]);
  const translationGuard = (maybeString: string | undefined) => {
    // This exists to avoid the "Key is not a string literal" warning.
    // i18next-parser doesn't run the code, so some times we have to be creative
    // with how things are passed in.
    switch (maybeString) {
      case "Dashboard":
        return t("Dashboard");
      case "Home":
        return t("Home");
      case "Portfolio":
        return t("Portfolio");
      case "Contact Us":
        return t("Contact Us");
      case "My Products":
        return t("My Products");
      case "Users":
        return t("Users");
      case "Organization":
        return t("Organization");
      case "Orders":
        return t("Orders");
      case "Products":
        return t("Products");
      case "Quotes":
        return t("Quotes");
      case "Samples":
        return t("Samples");
      case "Leads":
        return t("Leads");
      case "Customers":
        return t("Customers");
      case "Distributors":
        return t("Distributors");
      case "Assets":
        return t("Assets");
      case "Templates":
        return t("Templates");
      case "Attributes":
        return t("Attributes");
      case "Lists":
        return t("Lists");
      case "Marketing":
        return t("Marketing");
      case "Prospects":
        return t("Prospects");
      case "Company Info":
        return t("Company Info");
      case "Roles":
        return t("Roles");
      case "CHANNEL MANAGEMENT":
        return t("CHANNEL MANAGEMENT");
      case "MARKETING":
        return t("MARKETING");
      case "MY ORGANIZATION":
        return t("MY ORGANIZATION");
      case "TRANSACTIONS":
        return t("TRANSACTIONS");
      default:
        return maybeString;
    }
  };

  const getNavBadge = (navName: string | undefined) => {
    if (notifications) {
      switch (navName) {
        case "Orders":
          return notifications.orders.total;
        case "Quotes":
          return notifications.quotes.total;
        case "Samples":
          return notifications.samples.total;
        case "Leads":
          return notifications.leads.total;
        default:
          return undefined;
      }
    }
  };

  const mapNavItem: () => JSX.Element[] = () => {
    return accordionNavRoutes.map((route, index) => {
      if (route.navParent) {
        return (
          <AccordionNav
            parent={route.navParent}
            navRoutes={route.navRoutes}
            navLayout={navLayout}
            key={index}
            translationGuard={translationGuard}
            getNavBadge={getNavBadge}
            collapsedSideNav={collapsedSideNav}
            AccordionIcon={route.navRoutes[0].nav?.[navLayout]?.icon}
          />
        );
      } else {
        return (
          <NavItem
            index={index}
            key={index}
            path={route.navRoutes[0].path}
            name={
              translationGuard(route.navRoutes[0].nav?.[navLayout]?.name) ?? ""
            }
            fill={theme.navLinkColor}
            Icon={route.navRoutes[0].nav?.[navLayout]?.icon}
            navType={route.navRoutes[0].navType}
            collapsedSideNav={collapsedSideNav}
            navLayout={navLayout}
            badge={getNavBadge(route.navRoutes[0].nav?.[navLayout]?.name)}
          />
        );
      }
    });
  };

  useEffect(() => {
    //indiebeautyboutique storefront header nav reorder
    const faqItem = navRoutes.find(
      (item) => item?.nav?.public?.name === "FAQs"
    );
    const contactUsItem = navRoutes.find(
      (item) => item?.nav?.public?.name === "Contact Us"
    );
    const TrendsItem = navRoutes.find(
      (item) => item?.nav?.public?.name === "Trends"
    );

    const indiebeautyboutiqueNavRoutes = navRoutes.filter(
      (item) =>
        item.nav?.public?.name !== "FAQs" &&
        item.nav?.public?.name !== "Contact Us" &&
        item.nav?.public?.name !== "Trends"
    );
    if (TrendsItem) {
      indiebeautyboutiqueNavRoutes.push(TrendsItem);
    }
    if (contactUsItem) {
      indiebeautyboutiqueNavRoutes.push(contactUsItem);
    }
    if (faqItem) {
      indiebeautyboutiqueNavRoutes.push(faqItem);
    }
    const newNavRoutes =
      slug === "indiebeautyboutique" ? indiebeautyboutiqueNavRoutes : navRoutes;

    // create an array of AccordionNavRoutes for Accordion Nav component/
    if (newNavRoutes) {
      let parents: string[] = [];
      let accordionRoutes: AccordionNavRoutes[] = [];
      newNavRoutes.forEach((route) => {
        if (!route.parentNav) {
          accordionRoutes.push({ navRoutes: [route] });
        } else {
          if (route.parentNav && !parents.includes(route.parentNav)) {
            parents.push(route.parentNav);
            accordionRoutes.push({
              navParent: route.parentNav,
              navRoutes: [route],
            });
          } else {
            const parentIndex = accordionRoutes.findIndex(
              (accordionRoute) =>
                accordionRoute.navParent &&
                accordionRoute.navParent === route.parentNav
            );
            accordionRoutes[parentIndex].navRoutes.push(route);
          }
        }
      });
      setAccordionNavRoutes(accordionRoutes);
    }
  }, [navRoutes, slug]);

  return (
    <NavWrapper isCollapsed={collapsedSideNav ?? false}>
      {accordionNavRoutes.length > 0 &&
        ((navLayout as string) === "public" ? (
          <IntersectionObserverWrapper>
            {mapNavItem()}
          </IntersectionObserverWrapper>
        ) : (
          <>{mapNavItem()}</>
        ))}
    </NavWrapper>
  );
});
