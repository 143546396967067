import React from "react";
import type { DataMutate } from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { MatchingCollectionsContainer } from "../Collections/MatchingCollectionsContainer";
import { ProductGroupCard } from "./ProductGroupCard";

interface IBasicsProps {
  product: PIMProduct;
  isPortfolio?: boolean;
  mutateProduct: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
}

/**
 * The overview for a product, displays all groups except SEO plus some of the
 * collections.
 */
export const ProductOverview = ({
  product,
  mutateProduct,
  replaceProductId,
  isPortfolio = false,
}: IBasicsProps) => {
  const productGroups = !isPortfolio
    ? product.product_schema.groups
    : product.product_schema.groups.filter(({ attributes }) =>
        attributes.some(
          (attr) => attr?.values?.length > 0 && !!attr.values[0].value
        )
      );
  const filteredCollections = ["properties", "specification", "skus"];
  const collections = product.product_schema.collections.filter((collection) =>
    filteredCollections.every(
      (name) => !collection.name.toLowerCase().includes(name)
    )
  );

  return (
    <>
      {productGroups.reduce<JSX.Element[]>((acc, group) => {
        if ((!isPortfolio || !group.is_restricted) && group.name !== "SEO") {
          acc.push(
            <ProductGroupCard
              product={product}
              group={group}
              key={group.id}
              mutateProduct={mutateProduct}
              isPortfolio={isPortfolio}
              replaceProductId={replaceProductId}
            />
          );
        }
        return acc;
      }, [])}
      <div style={{ marginTop: "44px" }}>
        <MatchingCollectionsContainer
          collections={collections}
          product={product}
          fetchProductData={mutateProduct}
          isPortfolio={isPortfolio}
          replaceProductId={replaceProductId}
        />
      </div>
    </>
  );
};
