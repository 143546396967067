import { CaretDownIcon } from "../../../components/Icons/Icons";
import type { DefaultTheme } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";
import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../components/Auth";
import {
  getUserName,
  useHasMultipleLanguages,
  useMediaQueries,
  useStoreState,
  useSupportedLanguages,
} from "../../../util/util";
import { LinkUnstyled } from "../../../components/LinkUnstyled/LinkUnstyled";
import {
  PrimaryButtonSmall,
  TextButton,
} from "../../../components/Buttons/Buttons";
import { screenSize } from "../../../theme";
import { useRoutePath } from "../../../util/Routing";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: right;
  white-space: nowrap;
`;
const UserWrapper = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 11px;
  color: #0f1d2b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  .sb-avatar__text {
    border: 1px solid ${(props) => props.theme.primaryButtonBorder};
    & > * {
      font-size: ${(props) => props.theme.fontSizes.medium} !important;
    }
  }
  &::hover {
    .sb-avatar {
      box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
    }
  }
  @media ${screenSize.medium} {
    margin-left: 5px;
  }
`;
const CaretContainer = styled.div`
  transition: all 0.2s ease-out;
`;

export const GuestUserWrapper = styled.div`
  border-left: 1px solid ${(props) => props.theme.primaryBorder};
  margin-left: 15px;
  @media ${screenSize.mLarge} {
    margin-left: 8px;
  }
`;

export const LoginButton = styled(Link)`
  background: #fff;
  padding: 10px 20px;
  border: 1px solid #fff;
  margin: 0 10px;
  text-decoration: none;
  text-wrap: nowrap;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.primaryTextColor};
  @media ${screenSize.mLarge} {
    padding: 5px;
    margin: 0 8px;
  }
`;

const RegisterButton = styled(PrimaryButtonSmall)`
  border: 2px solid ${(props) => props.theme.selectedBorder};
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #00000040;
  text-decoration: none;
  @media ${screenSize.mLarge} {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    padding: 5px;
  }
`;

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 180px;
  padding: 5px;
  border: 1px solid #e7e8ea;
  background: #fff;
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
  z-index: 1;
  button {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    &:hover {
      background: #f5f7f8;
    }
  }
`;

// the below commented out code causes TS errors?
// const LanguageDropdownWrapper = styled(UserWrapper);
const LanguageDropdownWrapper = styled.div`
  position: relative;
  cursor: pointer;
  font-size: 11px;
  color: #0f1d2b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  @media ${screenSize.medium} {
    margin: 0 5px;
  }
  &::hover {
    .sb-avatar {
      box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
    }
  }
  button {
    @media ${screenSize.medium} {
      font-size: ${({ theme }) => theme.fontSizes.small};
      padding-right: 0;
    }
  }
`;

// TODO: remove this and make dynamic when the API returns the "full" name of the language
// in addition to its abbreviation.

export const UserArea = () => {
  const { storefront_metadata, slug } = useStoreState();
  const { route_configuration } = storefront_metadata;
  const [showDropDown, setShowDropDown] = useState(false);
  const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
  const wrapperRef = useRef(document.createElement("div"));
  const languageWrapperRef = useRef(document.createElement("div"));
  const {
    user,
    logout,
    roleIsSellerStandard,
    roleIsBuyerAdmin,
    role,
    roleIsDistributorAdmin,
  } = useAuthContext();
  const theme: DefaultTheme = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const { notifyError } = useContext(Notifications);
  const { isMediumScreen } = useMediaQueries();
  const { adminPath, accountPath, storePath } = useRoutePath();
  const { t, i18n } = useTranslation();
  // Don't ask me why this library uses an array.
  const [, setCookie] = useCookies([`preferred-language-${slug}`]);
  const { supported_languages: languages, getLanguageLabel } =
    useSupportedLanguages();
  const hasMultipleLanguages = useHasMultipleLanguages();
  const currentLanguage = languages[i18n.language as keyof typeof languages];
  const selectableLanguages = Object.fromEntries(
    Object.entries(languages).filter(([, lang]) => lang !== currentLanguage)
  );

  const isBuyerOrDistributorAdmin = roleIsBuyerAdmin || roleIsDistributorAdmin;

  const isBuyerAdminPortfolio =
    location.pathname.indexOf(storePath + "/product") === 0 &&
    isBuyerOrDistributorAdmin;

  const portalPages =
    location.pathname.indexOf(accountPath) === 0 ||
    location.pathname.indexOf(adminPath) === 0;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropDown(false);
    }

    if (
      languageWrapperRef.current &&
      !languageWrapperRef.current.contains(event.target)
    ) {
      setShowLanguageDropDown(false);
    }
  };

  const handleLogout = () => {
    logout()
      .then(() =>
        window.location.replace(
          `${storePath}${user?.is_sso_login ? "/manual_login" : ""}`
        )
      )
      .catch(() => {
        notifyError(t("There was an error logging out, please try again"));
      });
  };

  const userIsAdmin =
    role === "seller_admin" ||
    role === "buyer_admin" ||
    role === "distributor_admin";

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setCookie(`preferred-language-${slug}`, lang, { path: "/" });
  };

  const lang = currentLanguage ?? getLanguageLabel("en");

  return (
    <>
      {hasMultipleLanguages && (
        <Wrapper>
          <LanguageDropdownWrapper
            ref={languageWrapperRef}
            onClick={() =>
              currentLanguage
                ? setShowLanguageDropDown(!showLanguageDropDown)
                : {}
            }
          >
            <TextButton disabled={!currentLanguage}>
              {isMediumScreen ? `(${i18n.language.toLocaleUpperCase()})` : lang}
            </TextButton>
            {showLanguageDropDown && (
              <DropDown>
                {Object.entries(selectableLanguages).map(([abbr, full]) => (
                  <button key={abbr} onClick={() => handleChangeLanguage(abbr)}>
                    {full}
                  </button>
                ))}
              </DropDown>
            )}
          </LanguageDropdownWrapper>
        </Wrapper>
      )}
      <Wrapper>
        {/* TODO move to routing utils, so redirects are in one place? */}
        {user && (
          <UserWrapper
            ref={wrapperRef}
            onClick={() => setShowDropDown(!showDropDown)}
          >
            {showDropDown && (
              <DropDown>
                {/* Don't show admin link for non admin users. */}
                {location.pathname.indexOf("/dashboard") < 0 &&
                  role === "seller_admin" && (
                    <button
                      onClick={() =>
                        window.location.replace(`${adminPath}/dashboard`)
                      }
                    >
                      {t("Dashboard")}
                    </button>
                  )}
                {user && (
                  <LinkUnstyled
                    to={`${userIsAdmin ? adminPath : accountPath}/preferences`}
                  >
                    <button>{t("Preferences")}</button>
                  </LinkUnstyled>
                )}

                {/* Don't show admin link for non admin users. */}
                {!portalPages &&
                  (userIsAdmin && !isBuyerAdminPortfolio ? (
                    <button
                      onClick={() =>
                        window.location.replace(`${adminPath}/organization`)
                      }
                    >
                      {t("Admin")}
                    </button>
                  ) : roleIsSellerStandard ? (
                    <button
                      onClick={() => history.push(`${accountPath}/orders`)}
                    >
                      {t("Account")}
                    </button>
                  ) : null)}
                <button onClick={handleLogout}>{t("Logout")}</button>
              </DropDown>
            )}
            <Avatar
              name={getUserName(user?.firstname, user?.lastname)}
              size={isMediumScreen ? "28" : "36"}
              color={theme.primaryButtonBG}
              round="20px"
              fgColor={theme.primaryTextColor}
              style={{ marginRight: "5px" }}
            />
            <CaretContainer
              style={{
                transform: `rotate(${showDropDown ? "180deg" : "0deg"})`,
              }}
            >
              <CaretDownIcon width={20} height={20} />
            </CaretContainer>
          </UserWrapper>
        )}
        {!user && !route_configuration.registration && !isMediumScreen && (
          <GuestUserWrapper>
            <LoginButton to={`${storePath}/login`}>{t("Sign In")}</LoginButton>
          </GuestUserWrapper>
        )}
        {!user && route_configuration.registration && !isMediumScreen && (
          <GuestUserWrapper>
            <LoginButton to={`${storePath}/login`}>{t("Sign In")}</LoginButton>
            <RegisterButton as="a" href={`${storePath}/register`}>
              {t("Register")}
            </RegisterButton>
          </GuestUserWrapper>
        )}
      </Wrapper>
    </>
  );
};
