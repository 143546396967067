import type { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  marginRight?: number;
  fontSize?: string;
  color?: string;
  handleChange?: (e: any) => void;
  optionTitle?: string;
  theref?: any;
  inputRef?: any;
}

const RadioLabel = styled.label<{
  color?: string;
  font_size: string;
  is_disabled: boolean;
}>`
  display: flex;
  gap: 0.5em;
  align-items: center;
  cursor: ${({ is_disabled }) => (is_disabled ? "not-allowed" : "pointer")};
  color: ${({ color, is_disabled }) =>
    is_disabled ? "#808080" : color ?? "unset"};
  font-size: ${({ font_size }) => font_size};
`;

const RadioInput = styled.input.attrs(() => ({ type: "radio" }))`
  --active: ${({ theme }) => theme.brandColor};
  --disabled: ${({ theme }) => theme.disabledButtonBG};
  --border: ${({ theme }) => theme.primaryBorder};
  appearance: none;
  height: 1.15em;
  width: 1.15em;
  border: 0.15em solid var(--border);
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
  transition: border-color 0.3s;
  display: grid;
  place-content: center;
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--active);
  }
  &:checked {
    border-color: var(--active);
    &::before {
      transform: scale(1);
    }
  }
  :disabled {
    cursor: not-allowed;
    border-color: var(--disabled);
    &:checked::before {
      box-shadow: inset 1em 1em var(--disabled);
    }
  }
`;

export const RadioButton = ({
  name,
  theref,
  value,
  checked,
  handleChange,
  disabled,
  optionTitle,
  fontSize = "13px",
  color,
}: RadioButtonProps) => {
  return (
    <RadioLabel
      color={color}
      font_size={fontSize}
      htmlFor={Array.isArray(value) ? value.join(" ") : String(value)}
      is_disabled={disabled ?? false}
    >
      <RadioInput
        id={Array.isArray(value) ? value.join(" ") : String(value)}
        name={name}
        ref={theref}
        defaultValue={value}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      {optionTitle}
    </RadioLabel>
  );
};

export const RadioButtonWithController = (props: RadioButtonProps) => {
  const { optionTitle, fontSize = "13px", color, ...rest } = props;

  return (
    <RadioLabel
      color={color}
      font_size={fontSize}
      is_disabled={rest.disabled ?? false}
    >
      <RadioInput {...rest} type="radio" />
      {optionTitle}
    </RadioLabel>
  );
};
