import styled from "styled-components";
import { screenSize } from "../theme";
interface PageWrapperProps {
  customFont?: string;
  center?: boolean | undefined;
}
interface PublicPageProps {
  mxWidth?: string | undefined;
}

export const AppWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  flex-direction: column;
  background: ${({ theme }) => theme.primaryBG};
  & * {
    box-sizing: border-box;
    font-family: ${({ customFont }) =>
      customFont ? `'${customFont}', 'Inter'` : "Inter"} !important;
  }
  @media screen and (min-width: 800px) {
    align-items: ${({ center }) => (center ? "center" : "stretch")};
  }
`;

export const PublicHeader = styled.div<PublicPageProps>`
  padding: 10px 0 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.primaryBG};
  @media ${screenSize.medium} {
    flex-wrap: no-wrap;
    padding: 10px 0 10px;
  }
  @media ${screenSize.medium} {
    padding: 5px 0 0;
  }
  @media screen and (min-width: 800px) {
    width: ${({ mxWidth }) => mxWidth || "inherit"};
  }
`;
export const LogoWrapper = styled.div`
  min-height: 50px;
  min-width: 190px;
  display: flex;
  align-items: center;
  a {
    display: block;
  }
  @media ${screenSize.medium} {
    min-width: 150px;
  }
`;

export const Logo = styled.img<PublicPageProps>`
  max-width: 150px;
  max-height: 80px;
  margin: 0px 20px;
  @media ${screenSize.large} {
    max-width: 120px;
    max-height: 50px;
    margin: 0px 10px;
  }
  @media screen and (min-width: 800px) {
    margin: ${({ mxWidth }) => (mxWidth ? "0" : "0px 20px")};
  }
`;

export const NavPanel = styled.div`
  width: 100%;
  background: transparent;
  color: ${({ theme }) => theme.primaryTextColor};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily};
  overflow-x: auto;
  nav {
    flex-direction: row;
    white-space: nowrap;
    a {
      color: ${({ theme }) => theme.primaryTextColor};
      font-weight: normal;
      border-bottom: 1px solid ${({ theme }) => theme.publicNavBorder};
      &.active {
        border-bottom: 3px solid ${({ theme }) => theme.publicNavBorderActive};
        border-right: 0;
        background: ${({ theme }) => theme.primaryBG};
      }
      &:hover {
        color: ${({ theme }) => theme.brandColor};
        background: ${({ theme }) => theme.primaryBG};
      }
    }
  }

  @media ${screenSize.medium} {
    // order: 100;
    width: 100%;
    overflow-x: auto;
    min-width: 40px;
  }
`;

export const UserAreaContainer = styled.div<PublicPageProps>`
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-right: 15px;
  align-items: center;
  @media ${screenSize.medium} {
    margin-right: 5px;
}
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryLinkColor};
    border-right: 1px solid ${({ theme }) => theme.primaryBorder};
    padding-right: 15px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    @media ${screenSize.small} {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
  }
 
  & > button {
    @media ${screenSize.small} {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
    
`;

export const MainWrapper = styled.div<PublicPageProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.primaryBG};
  overflow-y: auto;
  height: 100%;
  @media screen and (min-width: 800px) {
    width: ${({ mxWidth }) => mxWidth || "100%"};
  }
`;

interface PageWrapperProps {
  fullWidth?: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  flex: 1;
  padding: ${({ fullWidth }) => (fullWidth ? "0 0 25px" : "15px 100px 25px")};
  @media ${screenSize.large} {
    padding: ${({ fullWidth }) => (fullWidth ? "0 0 25px" : "10px 15px 25px")};
  }
  @media ${screenSize.small} {
    padding: ${({ fullWidth }) => (fullWidth ? "0 0 25px" : "10px 8px 15px")};
  }
`;
