import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderLeft } from "../../../../../components/Layout/Layout";
import { Form, RadioButtonContainer } from "../../../../../layout/FormLayout";
import {
  removeUnderscore,
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { z } from "zod";
import {
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../util/zod.util";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import type { DataMutate, OptionType } from "../../../../../types/types";
import type {
  AttributeArgSchema,
  AttributeSchema,
  ListItemSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { TextAreaCounter } from "../../../../../components/TextAreaCounter/TextAreaCounter";
import { H6Bold } from "../../../../../components/Typography/Typography";
import styled from "styled-components";
import { SectionTitle } from "../../../../../components/Form/Form";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { DeletableChips } from "../../../../../components/DeletableChips/DeletableChips";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";

const CreateAttributeSchema = z.object({
  name: z.string(),
  display_name: z.string().optional(),
  description: z.string().optional(),
  input_type: zodSelectBoxType,
  list_id: zodSelectBoxType.optional(),
  choices: zodSelectBoxType.optional(),
});

export const CreateAttributeSchemaFn = (
  t: (s: string) => string,
  shouldHaveChoices?: boolean
) =>
  z
    .object({
      name: zodRequiredString(t),
      display_name: z.string().optional(),
      description: z.string().max(200, t("Exceeded maximum limit")).optional(),
      input_type: zodSelectBoxDefault(t),
      list_id: zodSelectBoxDefault(t).optional(),
      choices: zodSelectBoxDefault(t).optional(),
    })
    .superRefine(({ input_type, list_id }, ctx) => {
      if (shouldHaveChoices === false) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("Select at least one list item"),
          path: ["choices"],
        });
      }
      if (
        (input_type.value === "single_select" ||
          input_type.value === "multi_select") &&
        !list_id?.value
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("This is a required field"),
          path: ["list_id"],
        });
      }
    });

export const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

export const MarginBottomH6 = styled(H6Bold)`
  margin-bottom: 16px;
`;

export type FormValues = z.infer<typeof CreateAttributeSchema>;

export const listToOptions = ({
  id,
  display_name,
  name,
}: ShortListSchema): OptionType<string> => ({
  label: toTitleCase(display_name ? display_name : name),
  value: id,
});

export const shouldUpdateDisplayName = (
  name: string,
  displayName: string | undefined,
  isDisplayNameDirty: boolean
) => {
  return name !== displayName && !isDisplayNameDirty;
};

export const CreateAttribute = ({
  refreshAttributesList,
  onComplete,
}: {
  refreshAttributesList: DataMutate<AttributeSchema[]>;
  onComplete: () => void;
}) => {
  const [choices, setChoices] = useState<Set<string>>();
  const [selectChoices, setSelectChoices] = useState(false);
  const [productReferenceType, setProductReferenceType] =
    useState<string>("single");
  const [submitting, setSubmitting] = useState(false);
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      CreateAttributeSchemaFn(
        t,
        selectChoices ? (choices?.size ?? 0) > 0 : undefined
      )
    ),
    defaultValues: {
      input_type: { label: undefined, value: undefined },
    },
  });
  const {
    handleSubmit,
    register,
    formState,
    control,
    errors,
    watch,
    setValue,
  } = methodsOfUseForm;
  const { data: typesResponse } = useSWR<{ data: string[] }>(
    endpoints.v2_pim_types()
  );
  const {
    name: nameValue,
    display_name: displayNameValue,
    input_type: inputValue,
    list_id: listValue,
  } = watch(["name", "display_name", "input_type", "list_id"]);
  if (
    shouldUpdateDisplayName(
      nameValue,
      displayNameValue,
      !!formState.dirtyFields["display_name"]
    )
  ) {
    setValue("display_name", nameValue);
  }
  const showList = () =>
    inputValue.value === "single_select" || inputValue.value === "multi_select";
  const onSubmit = async ({
    name,
    display_name,
    description,
    input_type,
    list_id,
  }: FormValues): Promise<void> => {
    setSubmitting(true);
    name = name.trim();
    if (display_name) {
      display_name = display_name.trim();
    }
    try {
      await Axios.post<AttributeArgSchema, AttributeSchema>(
        endpoints.v2_tenants_id_pim_attributes(tenant_id),
        {
          name,
          display_name,
          description,
          input_type: input_type.value,
          is_multiple_choice:
            input_type.value === "single_select" ||
            input_type.value === "multi_select" ||
            (input_type.value === "product_reference" &&
              productReferenceType === "multi"),
          choices: selectChoices ? Array.from(choices ?? []) : undefined,
          list_id: list_id?.value,
        }
      );
      await refreshAttributesList();
      notifySuccess(t("Attribute successfully created"));
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not create attribute. Something went wrong."),
        {
          error,
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const changeProductReferenceType = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    if (e.currentTarget.value) {
      setProductReferenceType(e.currentTarget.value);
    }
  };

  const removeChoice = (name: string) =>
    setChoices((prev) => {
      const newSet = new Set(prev);
      newSet.delete(name);
      return newSet;
    });

  const addToChoices = ({ value, label }: OptionType<string>) =>
    setChoices((prev) => {
      if (prev) {
        const newSet = new Set(prev);
        newSet.add(label);
        return newSet;
      } else {
        return new Set([label]);
      }
    });

  useEffect(() => {
    setChoices(new Set([]));
  }, [listValue]);

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Create Attribute")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Attribute Definition")}</MarginBottomH6>
        <TextField
          name="name"
          label={t("Attribute Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="display_name"
          label={t("Display Name")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextAreaCounter
          name="description"
          label={t("Short Description")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          watch={watch}
        />
        <MarginBottomH6>{t("Attribute Type")}</MarginBottomH6>
        {typesResponse && (
          <>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="input_type"
              autoComplete={"input_type"}
              placeholder={t("Attribute Type")}
              options={typesResponse.data.map((type) => ({
                label: toTitleCase(removeUnderscore(type)),
                value: type,
              }))}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
            {inputValue.value === "product_reference" && (
              <RadioButtonContainer>
                <RadioButton
                  name="product_reference_type"
                  value="single"
                  checked={productReferenceType === "single"}
                  optionTitle="Single Reference"
                  handleChange={changeProductReferenceType}
                />
                <RadioButton
                  name="product_reference_type"
                  value="multi"
                  checked={productReferenceType === "multi"}
                  optionTitle="Multi Reference"
                  handleChange={changeProductReferenceType}
                />
              </RadioButtonContainer>
            )}
          </>
        )}
        {showList() && (
          <>
            <Controller
              as={SearchSelectInfiniteScroll}
              control={control}
              name="list_id"
              autoComplete="on"
              baseUrl={endpoints.v2_tenants_id_or_slug_pim_lists(tenant_id)}
              params={(() => {
                const params = new URLSearchParams();
                params.append("status", "active");
                return params;
              })()}
              getOptions={(response: ShortListSchema[]) =>
                response.reduce(
                  (
                    prev: { label: string; value: string }[],
                    cur: ShortListSchema
                  ) => {
                    if (!Boolean(cur.parent)) {
                      prev.push(listToOptions(cur));
                    }
                    return prev;
                  },
                  []
                )
              }
              placeholder={t("Choose list")}
              testid="list_id_search"
              defaultOptions
              errors={errors}
              formState={formState}
            />
            {listValue && (
              <>
                <ToggleSwitch
                  label={t("Use only specific values")}
                  theref={null}
                  onClick={() => setSelectChoices((prev) => !prev)}
                  name="specific_values"
                  isChecked={selectChoices}
                />
                {selectChoices && (
                  <>
                    <SearchSelectInfiniteScroll
                      name="choices"
                      params={(() => {
                        const params = new URLSearchParams();
                        params.append("status", "active");
                        return params;
                      })()}
                      baseUrl={endpoints.v2_tenants_id_or_slug_pim_lists_id_items(
                        tenant_id,
                        listValue!.value
                      )}
                      getOptions={(response: ListItemSchema[]) =>
                        response.reduce(
                          (
                            prev: { label: string; value: string }[],
                            { name, id }: ListItemSchema
                          ) => [
                            ...prev,
                            { label: toTitleCase(name), value: id },
                          ],
                          []
                        )
                      }
                      onChange={addToChoices}
                      errors={errors}
                      formState={formState}
                      placeholder={t("Search list items from {{listName}}", {
                        listName: listValue?.label,
                      })}
                      // This prevents a value from ever appearing in the input,
                      // instead the value is added to the chips.
                      value={null}
                      testid="list_item_id_search"
                      autoComplete="on"
                    />
                    <DeletableChips
                      chips={
                        Array.from(choices ?? [])?.map?.((choice) => ({
                          name: choice,
                        })) ?? []
                      }
                      noneSelectedName="choices"
                      handleClick={removeChoice}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Create")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
