import { Tab as ReachTab } from "@reach/tabs";
import styled from "styled-components/macro";
import { Badge } from "../../layout/shared";

/**
 * Documentation: https://reach.tech/tabs/#tab
 * When an svg is a child of this component it will fill the svg itself of the
 * path with the `primaryButtonBG` color.
 */
export const Tab = styled(ReachTab)`
  &[data-selected] {
    background-color: ${({ theme }) => theme.primaryBG};
    border-bottom: 4px solid ${({ theme }) => theme.selectedBorder};
    padding-bottom: 12px;
    svg {
      fill: ${(props) => props.theme.primaryIconColor};
    }
    path,
    circle {
      stroke: ${(props) => props.theme.primaryIconColor};
    }
  }

  svg {
    position: relative;
    top: 3px;
    left: 0px;
    width: 15px;
    height: 15px;
    margin-right: 11px;
    fill: ${(props) => props.theme.primaryIconColor};
    path,
    circle {
      stroke: ${(props) => props.theme.primaryIconColor};
    }
  }

  :hover {
    color: ${(props) => props.theme.primaryLinkHover};
    svg {
      fill: ${(props) => props.theme.primaryLinkHover};
    }
    path,
    circle {
      stroke: ${(props) => props.theme.primaryLinkHover};
    }
  }

  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  // There are no vertical tabs in the app.. yet..
  margin-right: 48px;

  display: inline-block;
  border: none;
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid transparent;
  background: none;
  font: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  ${Badge} {
    height: 17px;
    min-width: 17px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    padding: 2px 3px;
  }
`;
