import styled from "styled-components";
import type { ITableProps } from "./utils";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import { Table } from "./Table";
import type { Row } from "react-table";
import { useTranslation } from "react-i18next";
import { SoftHeader2 } from "../Typography/Typography";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
`;

export const TableWithBulkAction = <TableData extends object>(
  props: ITableProps<TableData> & {
    bulkActionElement: <
      ElementProps extends {
        rows: TableData[];
        setResetSelectedRows: Dispatch<SetStateAction<boolean>>;
      }
    >(
      props: ElementProps
    ) => JSX.Element;
  }
) => {
  const { handleSelectRows, bulkActionElement } = props;
  const [rowsSelected, setRowsSelected] = useState<Row<TableData>[]>([]);
  const [resetSelectedRows, setResetSelectedRows] = useState(false);

  const { t } = useTranslation();

  const onSelectRows = (rows: Row<TableData>[]) => {
    setRowsSelected(rows);
    if (handleSelectRows) {
      handleSelectRows(rows);
    }
  };
  return (
    <TableWrapper>
      {rowsSelected.length > 0 ? (
        <BulkActionWrapper>
          <SoftHeader2>
            {rowsSelected.length} {t("selected")}
          </SoftHeader2>
          {bulkActionElement({
            rows: rowsSelected.map((row) => row.original),
            setResetSelectedRows,
          })}
        </BulkActionWrapper>
      ) : (
        <></>
      )}
      <Table
        {...props}
        handleSelectRows={onSelectRows}
        resetSelectedRows={resetSelectedRows}
      />
    </TableWrapper>
  );
};
