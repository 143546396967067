import React, { useState, useContext } from "react";
import styled from "styled-components/macro";
import { PageTitle } from "../../../../../layout/portalPageLayout";
import { HeaderLeft } from "../../../../../components/Layout/Layout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { TextArea } from "../../../../../components/TextArea/TextArea";
import { Form, SubmitButtonContainer } from "../../../../../layout/FormLayout";
import { ToggleGroup } from "../../../../../components/ToggleGroups/ToggleGroups";
import { PrimaryButtonLarge } from "../../../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../../../components/Form/Form";
import type { Product } from "../../../../../types/types";
import type { AxiosError } from "axios";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { useStoreState, useFormWrapper } from "../../../../../util/util";
import { useRoutePath } from "../../../../../util/Routing";
import { endpoints } from "../../../../../endpoints";
import * as zod from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { strings } from "../../../../../util/strings";
import { useTranslation } from "react-i18next";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
interface ICustomProduct {
  brand_name: string;
  cas_code: string | undefined;
  chemical_name: string | undefined;
  synonyms: string | undefined;
  inchi_key: string | undefined;
  inci_name: string | undefined;
  molecular_formula: string | undefined;
  ec_number: string | undefined;
}

type IToggleGroupTab = "Master Product" | "Existing Product" | "Custom";

const TOGGLE_GROUP_TABS: IToggleGroupTab[] = [
  "Master Product",
  "Existing Product",
  "Custom",
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.secondaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 6px;
  padding: 24px 24px 36px;
  margin: 15px 0px;
  & > * {
    margin-bottom: 20px;
  }
`;

export const CreateProductForm = () => {
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();

  const customCreateProductSchema = zod.object({
    brand_name: zod.string().min(1, strings(t).thisIsARequiredField),
    chemical_name: zod
      .string()
      .regex(new RegExp(/.*\S.*/), strings(t).thisIsARequiredField),
    ec_number: zod
      .string()
      .regex(new RegExp(/^$|[0-9]{3}-[0-9]{3}-[0-9]/), "ex: 0000-000-0")
      .nullable()
      .optional(),
    cas_code: zod
      .string()
      .regex(new RegExp(/^$|[0-9]{2,7}-[0-9]{2}-[0-9]/), "ex: 00000-00-0")
      .nullable()
      .optional(),
    inchi_key: zod
      .string()
      .regex(
        new RegExp(/^$|[A-Z]{14}-[A-Z]{10}-[A-Z]/),
        "ex: XXXXXXXXXXXXXX-YYYYYYYYFV-P"
      )
      .optional(),
    inci_name: zod.string().optional(),
    molecular_formula: zod.string().optional(),
    synonyms: zod.string().optional(),
  });
  type FormInputs = zod.infer<typeof customCreateProductSchema>;

  const [active, setActive] = useState<IToggleGroupTab>("Master Product");
  const [selectedItem, setSelectedItem] = useState({
    label: "",
    value: { id: "" },
  });
  const history = useHistory();
  const { handleSubmit, register, formState, errors, setValue, reset } =
    useFormWrapper<FormInputs>({
      resolver:
        active === "Custom"
          ? zodResolver(customCreateProductSchema)
          : undefined,
      mode: "onSubmit",
      reValidateMode: "onChange",
    });
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [loading, setLoading] = useState(false);

  const { adminPath } = useRoutePath();

  const changeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tab: IToggleGroupTab
  ) => {
    e.preventDefault();
    setActive(tab);
    reset();
  };

  const handleSearchSelected = (data: any) => {
    setValue("brand_name", data?.label);
    setSelectedItem(data);
  };

  const onSubmit = (data: ICustomProduct) => {
    if (active === "Custom") {
      setLoading(true);
      const url = endpoints.v1_storefronts_id_customProducts(storefront_id);
      // TODO: rewrite all of this to async/await.
      Axios.post(url, {
        name: data.brand_name,
        chemical_name: data.chemical_name,
        cas_code: data.cas_code || undefined,
        synonyms: data.synonyms || undefined,
        ec_number: data.ec_number || undefined,
        inchi_key: data.inchi_key || undefined,
        inci_name: data.inci_name || undefined,
        molecular_formula: data.molecular_formula || undefined,
      })
        .then((result: any) => {
          // TODO: add product id/name here
          notifySuccess(t("Product created successfully"));
          setLoading(false);

          history.push(
            `${adminPath}/pim/products/${
              result.data.product_number ?? result.data.id
            }?source=new`
          );
        })
        .catch((error: AxiosError) => {
          notifyError(
            error.response
              ? error.response.data.message
              : t(`There was an error creating the product, please try again`),
            { error }
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      const url = endpoints.v1_storefronts_id_products(storefront_id);

      Axios.post(url, {
        product_id: selectedItem?.value?.id,
        brand_name: data.brand_name,
      })
        // TODO: add type for results.
        .then((result: any) => {
          setLoading(false);
          history.push(
            `${adminPath}/pim/products/${
              result.data.product_number ?? result.data.id
            }?source=new`
          );
        })
        .catch((error: AxiosError) => {
          notifyError(
            error.response
              ? error.response.data.message
              : t(`There was an error creating the product, please try again`),
            { error }
          );
          setLoading(false);
        });
    }
  };

  return (
    <Wrapper>
      <HeaderLeft>
        <PageTitle>{t("Create product")}</PageTitle>
      </HeaderLeft>
      <Container>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <ToggleGroup
              names={TOGGLE_GROUP_TABS}
              activeButton={active}
              clickHandler={changeTab}
            />

            {active === "Master Product" && (
              <SearchSelectInfiniteScroll
                name={"master_product_search"}
                errors={errors}
                formState={formState}
                placeholder={""}
                baseUrl="/v1/products"
                params={(() => {
                  const params = new URLSearchParams();
                  params.append("order_by", "asc");
                  return params;
                })()}
                getOptions={(response: Product[]) =>
                  response.map((product) => ({
                    label: product.name,
                    value: product,
                  }))
                }
                onChange={(data: any) => handleSearchSelected(data)}
                testid={"master-product-search"}
              />
            )}

            {active === "Existing Product" && (
              <SearchSelectInfiniteScroll
                name={"tenant_product_search"}
                errors={errors}
                formState={formState}
                placeholder={""}
                baseUrl={endpoints.v1_storefronts_id_products_summary(
                  storefront_id
                )}
                params={(() => {
                  const params = new URLSearchParams();
                  params.append("order_by", "asc");
                  return params;
                })()}
                getOptions={(response: Product[]) =>
                  response.map((product) => ({
                    label: product.name,
                    value: product,
                  }))
                }
                onChange={(data: any) => handleSearchSelected(data)}
                testid={"existing-product-search"}
              />
            )}

            <TextField
              name="brand_name"
              label={t("Product Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />

            {(active === "Master Product" || active === "Existing Product") && (
              <SubmitButtonContainer>
                <PrimaryButtonLarge
                  loading={loading}
                  style={{ minWidth: "450px" }}
                >
                  {t("Create")}
                </PrimaryButtonLarge>
              </SubmitButtonContainer>
            )}
          </InputWrapper>

          {active === "Custom" && (
            <>
              <>
                <SectionTitle>{t("Identifiers")}</SectionTitle>
                <TextField
                  name="chemical_name"
                  label={t("Chemical Name")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="cas_code"
                  label="CAS #"
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="ec_number"
                  label="EC #"
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="inchi_key"
                  label="InChI Key"
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="inci_name"
                  label="INCI Name"
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="molecular_formula"
                  label={t("Molecular Formula")}
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextArea
                  name="synonyms"
                  label={t("Synonyms")}
                  type="text"
                  required={false}
                  formState={formState}
                  theref={register({
                    required: false,
                  })}
                />
              </>
              <SubmitButtonContainer>
                <PrimaryButtonLarge
                  loading={loading}
                  style={{ minWidth: "450px" }}
                >
                  {t("Create")}
                </PrimaryButtonLarge>
              </SubmitButtonContainer>
            </>
          )}
        </Form>
      </Container>
    </Wrapper>
  );
};
