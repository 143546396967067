import { useTranslation } from "react-i18next";
import { H3, Title } from "../../../components/Typography/Typography";
import { PrimaryButtonMedium } from "../../../components/Buttons/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import { useRoutePath } from "../../../util/Routing";
import { CenterContainer } from "../../../layout/publicPageLayout";

export function ThankYouPage() {
  const { storePath } = useRoutePath();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const requestName = () =>
    location.pathname.includes("register/thank-you")
      ? t("registration")
      : location.pathname.includes("contact-us/thank-you")
      ? t("contact")
      : location.pathname.includes("cart/thank-you-quote")
      ? t("quote")
      : location.pathname.includes("cart/thank-you-sample")
      ? t("sample")
      : "";
  return (
    <CenterContainer>
      <Title>{t("Thank you!")}</Title>
      <H3>
        {t(
          "Your {{requestName}} request has been received & a member of our team will follow up shortly.",
          { requestName: requestName() }
        )}
      </H3>

      <PrimaryButtonMedium onClick={() => history.push(storePath)}>
        {t("Go to Homepage")}
      </PrimaryButtonMedium>
    </CenterContainer>
  );
}
