import React, { useState } from "react";
import { TextField } from "../TextFields/TextFields";
import { H3, Title } from "../Typography/Typography";
import { Form, SubmitButtonContainer } from "../../layout/FormLayout";
import styled from "styled-components/macro";
import type { OptionType, Product } from "../../types/types";
import { isDefined } from "../../types/types";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { SearchSelect } from "../SearchSelect/SearchSelect";
import { EditableListItem } from "../EditableListItem/EditableListItem";
import type { IFeaturedProductsData } from "./FeaturedProducts";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { useFormWrapper } from "../../util/util";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
`;

/**
 * The form for editing the "Featured Products" section in homepage templates.
 *
 * Note: productIds in the homepage data may contain IDs of products that are
 * no longer featured.  See docstring for FeaturedProducts component.
 */
export const FeaturedProductsEditForm = ({
  data: { title, productIds },
  maximumProducts,
  allFeaturedProducts,
  homePageData,
  handleEditSubmit,
}: {
  data: IFeaturedProductsData;
  maximumProducts: number;
  allFeaturedProducts: Product[];
  homePageData: IHomePageData;
  handleEditSubmit: () => void;
}) => {
  const { saveHomePage } = useSaveHomePage();
  const [loading, setLoading] = useState(false);

  const [visibleProductIds, setVisibleProductIds] =
    useState<string[]>(productIds);

  const productsForDisplay = visibleProductIds
    .map((id) => allFeaturedProducts.find((product) => product.id === id))
    .filter(isDefined);

  const productsForMenu = allFeaturedProducts.filter(
    (product) => !visibleProductIds.includes(product.id)
  );

  const menuOptions: OptionType<string>[] = productsForMenu.map((product) => ({
    label: product.name,
    value: product.id,
  }));

  const maxProductsReached = productsForDisplay.length === maximumProducts;

  const { handleSubmit, register, formState, errors } = useFormWrapper({
    defaultValues: {
      title,
      featured_products_search: undefined,
    },
  });

  const handleProductMenuSelection = (option: OptionType<string>) => {
    setVisibleProductIds((previous) => [...previous, option.value]);
  };

  const handleRemoveProduct = (productId: string) => {
    setVisibleProductIds((previous) =>
      previous.filter((id) => id !== productId)
    );
  };

  const onSubmit = async (formData: { title: string }) => {
    setLoading(true);

    const newData = {
      title: formData.title,
      productIds: visibleProductIds,
    };

    const component = homePageData.config.components.find(
      (component) => component.name === "featuredProducts"
    );

    if (component) {
      component.data = newData;
    } else {
      homePageData.config.components.push({
        name: "featuredProducts",
        data: newData,
      });
    }

    const success = await saveHomePage(homePageData);
    if (success) {
      handleEditSubmit();
    }
    setLoading(false);
  };

  return (
    <Container>
      <Title>Featured Products</Title>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="title"
          label="Section Title"
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />

        <div>
          <H3>Featured Products to Display</H3>
          <p>Up to {maximumProducts} products may be displayed.</p>

          {productsForDisplay.map((product) => {
            return (
              <EditableListItem
                key={product.id}
                title={product.name}
                deleteFunction={() => handleRemoveProduct(product.id)}
              />
            );
          })}
        </div>
        {!maxProductsReached && (
          <SearchSelect
            name={"featured_products_search"}
            options={menuOptions}
            errors={errors}
            formState={formState}
            placeholder={"Featured Products"}
            // Setting value to null prevents a product from appearing in the
            // search input. Instead the product is added to the list.
            value={null}
            onChange={handleProductMenuSelection}
            disabled={maxProductsReached}
          />
        )}
        <SubmitButtonContainer>
          <PrimaryButtonFitContainer loading={loading}>
            Save Changes
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </Form>
    </Container>
  );
};
