import axios from "axios";
import { useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { PrimaryButtonLarge } from "../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../components/Form/Form";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { SampleRequestItem } from "../../../components/TransactionItem/SampleRequestItem";
import { Title } from "../../../components/Typography/Typography";
import { calculateTotalQuantity } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { Form } from "../../../layout/FormLayout";
import { Card } from "../../../layout/portalPageLayout";
import type {
  CreateSampleRequest,
  OptionType,
  Product,
  ProductApplication,
  ProductSKU,
  SampleRequestDetail,
  Tenant,
  UUID,
} from "../../../types/types";
import { packagingTypeOrUnitToOption } from "../../../util/SkuUtils";
import {
  addressToOption,
  convertApplicationToOption,
  convertProductSKUToOption,
  isAxiosError,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import type { AddOrEditProductFormOutput } from "./AddOrEditSampleProductForm";
import { AddOrEditSampleProductForm } from "./AddOrEditSampleProductForm";
import type { CreateSampleRequestBottomFormOutput } from "./CreateSampleRequestBottomForm";
import { CreateSampleRequestBottomForm } from "./CreateSampleRequestBottomForm";
import type {
  CustomerDetailsFormOutput,
  CustomerDetailsPOCForm,
  CustomerDetailsPOCManualForm,
} from "./SampleRequestCustomerDetailsForm";
import { CustomerDetailsForm } from "./SampleRequestCustomerDetailsForm";
import { strings } from "../../../util/strings";
import { RequestAddress } from "../../public/RequestAddress/RequestAddress";
import { PointOfContact } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/PointOfContactForm";
import type { LocationInputs } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/LocationForm";
import { useAddLocation } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/EditLocations";
import { getUserDetailsFromTenant } from "../SellerCreation.constants";

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 36px;
`;
const CardNoMargin = styled(Card)`
  margin-top: 0;
`;

const FormSection = styled.section`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &:nth-child(0) {
    margin-bottom: 40px !important;
  }
  &:nth-child(1) {
    margin-bottom: 0 !important;
  }
  margin-bottom: 20px;
`;

const MainSection = styled.section`
  overflow-y: auto;
`;

const OrderItemArea = styled.div`
  margin-bottom: 40px;
`;

const AddressWrapper = styled(OrderItemArea)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
`;

const SectionTitleNoMargin = styled(SectionTitle)`
  margin-top: 0px;
`;

const TitleReset = styled(Title)`
  margin-inline-start: 0px;
  margin-block-start: 0;
  margin-inline-end: 0px;
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

export interface SampleItemForDisplay extends SampleRequestDetail {
  entire_product: Product;
  packaging_unit: string;
  sku: ProductSKU;
  purpose: string;
  product_id: string;
  applications: ProductApplication[];
}

export function SellerCreateSampleRequest({
  onSuccess,
}: {
  onSuccess: (sampleID: UUID) => void;
}) {
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const [selectedBuyer, setSelectedBuyer] = useState<Tenant | null>(null);
  const [sampleItems, setSampleItems] = useState<Array<SampleItemForDisplay>>(
    []
  );
  const [pointOfContactType, setPointOfContactType] =
    useState<"manual" | "existing">("existing");
  const customerDetailsIsDisabled = sampleItems.length > 0;
  const [product, setProduct] = useState<Product | null>(null);
  const [formData, setFormData] = useState<{
    customer: CustomerDetailsFormOutput | null;
    bottomForm: CreateSampleRequestBottomFormOutput | null;
  }>({ customer: null, bottomForm: null });
  const [selectedItemIndex, setSelectedItemIndex] =
    useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] =
    useState<OptionType<string> | null>(null);
  const isEditMode = selectedItemIndex !== null;

  const addLocation = useAddLocation({
    buyer_tenant_id: selectedBuyer?.id ?? "",
  });

  const customerDetailsMethods = useFormWrapper<CustomerDetailsFormOutput>();

  const productInfoMethods = useFormWrapper<AddOrEditProductFormOutput>({
    defaultValues: {
      packaging: null,
      product_id: undefined,
    },
  });
  const bottomFormMethods = useFormWrapper({
    defaultValues: {
      decision_timeline: { name: "" },
      confirmation: false,
      documents: [],
    },
  });

  const { getValues, setError } = bottomFormMethods;

  const { watch: customerDetailsWatch } = customerDetailsMethods;
  const { reset: productInfoReset, setValue: setProductValue } =
    productInfoMethods;

  const allCustomerDetailsValues = customerDetailsWatch();

  const { shipping_address_id: destinationOption, buyer_id: buyerOption } =
    allCustomerDetailsValues;

  const productFormIsDisabled = !(!!destinationOption && !!buyerOption);
  let selected_user_id: null | string = null;
  if (selectedUser) {
    selected_user_id = (selectedUser as OptionType<string>)?.value;
    console.info(selected_user_id);
  }

  const bottomFormOnSubmit = async (
    output: CreateSampleRequestBottomFormOutput
  ) => {
    if (!loading) {
      setLoading(true);
      setFormData((data) => ({ ...data, bottomForm: output }));
      let newAddress: OptionType<string> | null = null;
      if (destinationOption.value === "new_address" && formData.customer) {
        const customerDetails = formData.customer;
        const inputs: LocationInputs = {
          company_name: customerDetails?.company_name ?? "",
          external_id: customerDetails?.external_id ?? "",
          address1: customerDetails.address1,
          address2: customerDetails.address2,
          country: customerDetails.country,
          county: customerDetails.county,
          state: customerDetails.state,
          city: customerDetails.city,
          postal_code: customerDetails.postal_code,
          is_active: true,
          contact_first_name: (customerDetails as CustomerDetailsPOCManualForm)
            .contact_first_name,
          contact_last_name: (customerDetails as CustomerDetailsPOCManualForm)
            .contact_last_name,
          email_address: (customerDetails as CustomerDetailsPOCManualForm)
            .email_address,
          phone_number: (customerDetails as CustomerDetailsPOCManualForm)
            .phone_number,
          country_code: (customerDetails as CustomerDetailsPOCManualForm)
            .country_code,
          point_of_contact_id: (customerDetails as CustomerDetailsPOCForm)
            .point_of_contact_id,
        };
        const newLocation = await addLocation({
          inputs,
          pocType: pointOfContactType,
        });
        if (newLocation) {
          newAddress = addressToOption(newLocation);
        }
      }
      const hasAddress =
        destinationOption?.value === "new_address"
          ? Boolean(newAddress)
          : Boolean(destinationOption?.value);
      if (hasAddress) {
        try {
          if (formData.customer) {
            const object: CreateSampleRequest = {
              transaction_state: "sample_accepted",
              data: {
                buyer_id: formData.customer?.buyer_id.value,
                tenant_id: tenant_id,
                order_placed_via: formData.customer.sample_request_via?.value,
                shipping_address_id: newAddress
                  ? newAddress.value
                  : formData.customer?.shipping_address_id.value,
                is_decision_maker: output?.is_decision_maker.value,
                decision_timeline: output.decision_timeline.name,
                requested_documents: output.documents.map((doc) => doc.name),
                items: sampleItems.map((item) => ({
                  product_id: item.product_id,
                  purpose: item.purpose,
                  no_of_units: item.no_of_units,
                  total_quantity: item.total_quantity,
                  total_quantity_packaging_unit_id:
                    item.total_quantity_packaging_unit?.id,
                  applications: item.applications[0]?.id,
                  ...(item.custom_application
                    ? { custom_application: item.custom_application }
                    : {}),
                  sku: {
                    id: item.sku.id,
                  },
                })),
                buyer_user_id: selected_user_id,
              },
            };
            const sample = await axios.post(
              `/v1/storefronts/${storefront_id}/transactions/create-sample`,
              object
            );
            notifySuccess(t("Sample Request recorded successfully"));
            setLoading(false);
            onSuccess(sample.data.id);
          } else {
            throw Error(
              "Unexpectedly couldn't create sample request on frontend"
            );
          }
        } catch (error) {
          if (
            isAxiosError(error) &&
            error?.response?.data?.status_code === "403"
          ) {
            notifyError(error.response.data.message);
          } else {
            notifyError(t("There was an error creating the sample request"), {
              error,
            });
          }
          if (newAddress) {
            try {
              await axios.delete(
                `/v1/tenants/${selectedBuyer!.id}/addresses/${newAddress.value}`
              );
            } catch (_) {
              notifyError(t("There was an error cleaning up the address."));
            }
          }
          console.error(error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const customerDetailsOnSubmit = async (output: CustomerDetailsFormOutput) => {
    setFormData((data) => ({ ...data, customer: output }));
    bottomFormMethods.handleSubmit(bottomFormOnSubmit);
  };

  const productDetailsOnSubmit = async (input: AddOrEditProductFormOutput) => {
    const theProduct = product as Product;
    if (input.packaging) {
      let object: SampleItemForDisplay = {
        packaging_unit: input.packaging.value.packaging_unit.id,
        product: {
          name: theProduct.name,
          identifiers: theProduct?.identifiers,
          // included for compatibility reasons
          safety_datasheet: "",
          technical_datasheet: "",
        },
        documents: [],
        entire_product: theProduct,
        sku: input.packaging.value,
        purpose: input.purpose.label,
        product_id: theProduct.id,
        id: theProduct.id,
        no_of_units: input.no_of_units,
        total_quantity: input.total_annual_quantity,
        sample_quantity_packaging_unit: {
          name: input.total_quantity_unit.label,
          id: input.total_quantity_unit.value,
        },
        total_quantity_packaging_unit: {
          name: input.total_quantity_unit.label,
          id: input.total_quantity_unit.value,
        },
        sample_quantity: String(
          calculateTotalQuantity(
            input.packaging.value.package_volume,
            input.no_of_units
          )
        ),
        ...(input.application && input.application.value
          ? {
              applications: [
                {
                  name: input.application.label as string,
                  id: input.application.value as string,
                  image_url: null,
                },
              ],
            }
          : { applications: [] }),
        ...(input.custom_application
          ? { custom_application: input.custom_application }
          : {}),
      };

      // form works for edit and create
      const index = sampleItems.findIndex(
        (item) => item.product_id === input.product_id
      );

      if (index >= 0) {
        setSampleItems((items) =>
          items.map((item, idx) => {
            if (idx === index) {
              return object;
            } else return item;
          })
        );
      } else {
        setSampleItems((items) => [...items, object]);
      }

      clearProductForm();
      setSelectedItemIndex(null);

      clearProductForm();
    }

    return null;
  };

  const clearProductForm = (): void => {
    productInfoReset();
    setProductValue("packaging", { value: undefined, label: undefined });
    setProductValue("product_id", null);
    setProduct(null);
    setProductValue("application", null);
    setProductValue("total_quantity_unit", null);
    setProductValue("purpose", null);
  };

  /**
   * Prefill the product form in edit mode.
   * @param item
   */
  const prefillProductForm = (item: SampleItemForDisplay): void => {
    setProduct(item.entire_product);
    setProductValue("product_id", item.entire_product.id);
    setProductValue(
      "application",
      item.applications[0]
        ? convertApplicationToOption(item.applications[0])
        : null
    );
    setProductValue("packaging", convertProductSKUToOption(item.sku));

    setTimeout(() => {
      setProductValue("total_quantity", item.total_quantity);
      setProductValue("no_of_units", item.no_of_units);

      setProductValue(
        "total_quantity_unit",
        packagingTypeOrUnitToOption(item.sku.packaging_unit)
      );
      setProductValue("purpose", { value: item.purpose, label: item.purpose });
      setProductValue("total_annual_quantity", item.total_quantity); // probably correct??
    });
  };

  const onCreateSampleRequest = () => {
    if (!getValues("decision_timeline").name) {
      setError("decision_timeline", {
        type: "required",
        message: strings(t).thisIsARequiredField,
      });
    }
    customerDetailsMethods.handleSubmit(customerDetailsOnSubmit)();
  };
  return (
    <>
      {/* Title is larger than design but consistent with app. */}
      <TitleReset>{t("Record a Sample")}</TitleReset>
      <Container>
        <FormSection>
          <CardNoMargin>
            {/* "Create Order Form" */}
            <FormProvider {...customerDetailsMethods}>
              <Form
                noValidate
                onSubmit={customerDetailsMethods.handleSubmit(
                  customerDetailsOnSubmit
                )}
              >
                <SectionTitleNoMargin>
                  {t("Customer Details")}
                </SectionTitleNoMargin>
                <CustomerDetailsForm
                  setSelectedBuyer={setSelectedBuyer}
                  setSelectedUser={setSelectedUser}
                  isDisabled={customerDetailsIsDisabled}
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
          <CardNoMargin selectedBorder={isEditMode}>
            {/* "Create/Edit Product Form" */}
            <FormProvider {...productInfoMethods}>
              <Form
                onSubmit={productInfoMethods.handleSubmit(
                  productDetailsOnSubmit
                )}
                noValidate
              >
                <SectionTitleNoMargin>
                  {t("Choose Products")}
                </SectionTitleNoMargin>
                <AddOrEditSampleProductForm
                  isDisabled={productFormIsDisabled}
                  // fallback is displayed before a buyer is selected.
                  product={product}
                  setProduct={setProduct}
                  isEditMode={isEditMode}
                  onCancel={() => {
                    setSelectedItemIndex(null);
                    clearProductForm();
                  }}
                  // This will exist by the time this form is interactive.
                  buyer_tenant_id={selectedBuyer?.id as UUID}
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
        </FormSection>
        <MainSection>
          {sampleItems.length > 0 ? (
            <CardNoMargin>
              {/* Cart section */}
              {/* Manually add 15px because the Form component does this for customer details */}
              <SectionTitleNoMargin style={{ marginBottom: "15px" }}>
                {t("Sample Summary")}
              </SectionTitleNoMargin>
              <OrderItemArea>
                {sampleItems.map((item, index) => {
                  return (
                    <SampleRequestItem
                      item={item}
                      handleConfirmDelete={(index) => {
                        setSampleItems((items) => {
                          return items.filter((item, idx) => index !== idx);
                        });
                      }}
                      handleEdit={() => {
                        setSelectedItemIndex(index);
                        prefillProductForm(item);
                      }}
                      index={index}
                      key={index}
                    />
                  );
                })}
              </OrderItemArea>
              {Boolean(destinationOption) &&
              destinationOption.value === "new_address" &&
              Boolean(selectedBuyer) ? (
                <AddressWrapper>
                  <RequestAddress
                    methodsOfUseForm={customerDetailsMethods}
                    hideBillingAddress
                    showBillingAddressOption={false}
                    showLocationId={true}
                    show_tax_id={true}
                    formTitle={t("Add new address")}
                  />
                  <PointOfContact
                    methodsOfUseForm={customerDetailsMethods}
                    pointOfContactType={pointOfContactType}
                    setPointOfContactType={setPointOfContactType}
                    defaultExistingUser={getUserDetailsFromTenant(
                      selectedBuyer!
                    )}
                  />
                </AddressWrapper>
              ) : (
                <></>
              )}
              <FormProvider {...bottomFormMethods}>
                <SectionTitleNoMargin>{t("Details")}</SectionTitleNoMargin>
                <>
                  <Form
                    noValidate
                    onSubmit={bottomFormMethods.handleSubmit(
                      bottomFormOnSubmit
                    )}
                  >
                    <CreateSampleRequestBottomForm />
                    <PrimaryButtonLarge
                      loading={loading}
                      type="submit"
                      onClick={onCreateSampleRequest}
                    >
                      {t("Create Sample Request")}
                    </PrimaryButtonLarge>
                  </Form>
                </>
              </FormProvider>
              {/* Submit cart form. */}
            </CardNoMargin>
          ) : (
            // TODO correct font size for body when it exists
            <CardNoMargin style={{ lineHeight: "28px", fontSize: "19px" }}>
              <SectionTitleNoMargin>{t("Sample Summary")}</SectionTitleNoMargin>
              <p>{t("To record a sample follow these steps")}</p>
              <ol type="1">
                <li>{t("Choose your customer and review the details")}</li>
                <li>{t("Add products to the cart")}</li>
                <li>{t("Review the cart and create sample request")}</li>
              </ol>
            </CardNoMargin>
          )}
        </MainSection>
      </Container>
    </>
  );
}
