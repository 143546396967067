import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NavBarTabs } from "../../../../../layout/shared/Nav/NavBar";
import { PIMLists } from "../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../util/Routing";

export const ListsNav = ({ tabIndex }: { tabIndex: PIMLists }) => {
  const { t } = useTranslation();
  const { adminPath } = useRoutePath();
  const history = useHistory();
  const tabs = [t("Lists")];
  const handleTabsChange = (index: PIMLists) =>
    index === PIMLists.LISTS
      ? history.push(`${adminPath}/pim/lists`)
      : history.push(`${adminPath}/pim/lists/${PIMLists[index].toLowerCase()}`);
  return (
    <NavBarTabs
      handleTabsChange={handleTabsChange}
      pageTitle={t("Lists")}
      tabs={tabs}
      tabIndex={tabIndex}
    />
  );
};
