import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SectionTitle } from "../../../../../../components/Form/Form";
import { Form } from "../../../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { PrimaryButtonFitContainer } from "../../../../../../components/Buttons/Buttons";
import {
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../../util/zod.util";
import { z } from "zod";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import {
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../../util/util";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import type {
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../../../../types/types";
import type {
  SupportedAssetCategoryType,
  SupportedAssetType,
  UploadAssetsResponseSchema,
  UploadedAssetInProgressSchema,
} from "../../../../../../types/types.PIM";
import { zodResolver } from "@hookform/resolvers/zod";
import { strings } from "../../../../../../util/strings";
import type { SupportedFileType } from "../../../../../../components/FilePicker/FilePicker.constants";
import {
  MAXIMUM_FILE_SIZE,
  SUPPORTED_DOC_TYPES,
  SUPPORTED_IMG_TYPES,
  SUPPORTED_VIDEO_TYPES,
} from "../../../../../../components/FilePicker/FilePicker.constants";
import useSWR from "swr";
import type { AssetCategory, AssetType } from "../util/AssetsUtil";
import { endpoints } from "../../../../../../endpoints";
import { DelayedSpinner } from "../../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import { HelpDialog } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/HelpDialog";
import {
  H6Bold,
  SmallSectionHeaderRegular,
} from "../../../../../../components/Typography/Typography";
import { InfoIcon } from "../../../../../../components/Icons/Icons";
import { UploadPopups } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/UploadPopups";
import type { AxiosError } from "axios";
import axios from "axios";
import { ProcessUploadedAssets } from "./ProcessUploadedAssets";
import { StringParam, useQueryParams } from "use-query-params";

const AddFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  width: 100%;
  position: relative;
`;

const GuideSection = styled.section`
  position: absolute;
  right: 0;
  top: 89px;
`;

const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddAssetSchema = z.object({
  asset_name: z.string(),
  files: z.instanceof(File).array(),
  asset_type: zodSelectBoxType,
  category_id: zodSelectBoxType,
  language: zodSelectBoxType,
});

const guidelineOverview = (t: TFunction) => [
  t(
    "Select an Asset Type, Category, and Language to assign to your uploaded assets."
  ),
  {
    text: t(
      "Use the upload box to select the asset files you would like to upload from your local file browser."
    ),
    subList: [
      t(
        "Once your files have been uploaded, you will see a list of uploaded file names, along with the asset name, asset type, category, language, and size of each uploaded file."
      ),
      t(
        "We will automatically generate an asset name based on your uploaded file, but this can be edited within the list of uploaded files."
      ),
    ],
  },
  t("Please check for any errors that may be flagged on your uploaded files."),
  t(
    "Once you are satisfied with your file upload, click the Save button to save them to the system. Clicking Save will reset the upload page and will allow you to upload a new set of files with a different asset type & category."
  ),
];

const csvGuidelines = (t: TFunction) => [
  t("Each uploaded file must be less than 50 mb in size."),
  t("All files must have a unique file name."),
  t("You can only upload a document, image, or video file."),
];

type FormValues = z.infer<typeof AddAssetSchema>;

const AddAssetSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      asset_type: zodSelectBoxDefault(t),
      files: z.instanceof(File).array().min(1, strings(t).thisIsARequiredField),
      category_id: zodSelectBoxDefault(t),
      language: zodSelectBoxDefault(t),
    })
    .superRefine(({ files }, ctx) => {
      const filesAboveMaxSize: File[] = [];
      const uniqueFileNameHash: { [prop: string]: string } = {};
      for (const file of files) {
        if (uniqueFileNameHash[file.name]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("All files must have unique a unique file name."),
            path: ["files"],
          });
          break;
        } else {
          uniqueFileNameHash[file.name] = file.name;
        }
      }
      files.forEach((file) => {
        if (file.size > MAXIMUM_FILE_SIZE) {
          filesAboveMaxSize.push(file);
        }
      });
      const len = filesAboveMaxSize.length;
      if (len > 0) {
        const fileNames = filesAboveMaxSize.reduce(
          (acc, curr, idx) => (idx === 0 ? `${acc}` : `${acc}, ${curr.name}`),
          `${filesAboveMaxSize[0].name}`
        );
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${fileNames} ${
            len > 1
              ? t("are above the maximum file size")
              : t("is above the maximum file size")
          }`,
          path: ["files"],
        });
      }
    });

export const AddAssetFiles = ({
  setCanExit,
  onCompleteUpload,
}: {
  setCanExit: Dispatch<SetStateAction<boolean>>;
  onCompleteUpload: () => void;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useNotifications();
  const { tenant_id, storefront_id } = useStoreState();

  const [showDialog, setShowDialog] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [query, setQuery] = useQueryParams({
    uploadUUID: StringParam,
    uploadNumber: StringParam,
  });
  const [uploadUUID, setUploadUUID] = useState<string | undefined>(
    query?.uploadUUID ?? undefined
  );
  const [uploadNumber, setUploadNumber] = useState<string | undefined>(
    query?.uploadNumber ?? undefined
  );
  const [uploadedAssets, setUploadedAssets] = useState<
    UploadedAssetInProgressSchema[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] =
    useState<"successful" | "failed" | "in_progress">("in_progress");
  const [assetTypes, setAssetTypes] = useState<
    OptionType<SupportedAssetType>[]
  >([]);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);
  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddAssetSchemaFn(t)),
    defaultValues: {
      asset_type: {
        label: undefined,
        value: undefined,
      },
      category_id: { label: undefined, value: undefined },
      files: [],
      language: { label: undefined, value: undefined },
    },
  });

  const { handleSubmit, control, errors, formState, setValue, watch } =
    methodsOfUseForm;

  const assetType = watch("asset_type");

  const supportedFileTypes: SupportedFileType =
    assetType.value === "image"
      ? (SUPPORTED_IMG_TYPES as SupportedFileType)
      : assetType.value === "video"
      ? (SUPPORTED_VIDEO_TYPES as SupportedFileType)
      : (SUPPORTED_DOC_TYPES as SupportedFileType);

  const { data: assetTypeResponse, error: assetTypeError } = useSWR<AssetType>(
    endpoints.v2_storefronts_id_pim_asset_types()
  );

  const { data: uploadedAssetsResponse } = useSWR<UploadAssetsResponseSchema>(
    query.uploadUUID && !assetType.value
      ? endpoints.v2_tenants_tenant_id_pim_assets_uploads_upload_id(
          tenant_id,
          query.uploadUUID
        )
      : null
  );

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    assetType.value
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?asset_type=${assetType.value}`
      : null,
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        ),
    }
  );

  useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const onSubmit = async (values: FormValues) => {
    setStatus("in_progress");
    setDialogMessage(
      t(
        "Your files are currently being uploaded. Please do not leave this page until all files have been uploaded."
      )
    );
    setShowDialog(true);
    setIsSubmitting(true);
    try {
      const { asset_type, category_id, language, files } = values;
      const formData = new FormData();
      files.forEach((file) => formData.append(file.name, file));

      formData.append("asset_type", asset_type.value);
      formData.append("category_id", category_id.value);
      formData.append("language", language.value);
      if (!uploadUUID) {
        const {
          data: { data: responseData, id: responseId, number },
        } = await axios.post<UploadAssetsResponseSchema>(
          endpoints.v2_tenants_tenant_id_pim_assets_uploads(tenant_id),
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setUploadUUID(responseId);
        setUploadNumber(number);
        setQuery({ uploadUUID: responseId, uploadNumber: number });
        setUploadedAssets(responseData);
        setCanExit(false);
      } else {
        const {
          data: { data: responseData },
        } = await axios.patch<UploadAssetsResponseSchema>(
          endpoints.v2_tenants_tenant_id_pim_assets_uploads_upload_id(
            tenant_id,
            uploadUUID
          ),
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        setUploadedAssets(responseData);
      }
      setStatus("successful");
      setDialogMessage(
        t(
          "Your files have been uploaded successfully. Please review the list of uploaded files for any flagged errors and generated Asset Names."
        )
      );
      setValue("files", []);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error uploading your files."),
        {
          error,
        }
      );
      setShowDialog(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (assetTypeResponse) {
      const { asset_types } = assetTypeResponse;
      setAssetTypes(
        asset_types.map((asset_type) => ({
          label: toTitleCase(asset_type),
          value: asset_type,
        }))
      );
    }
  }, [assetTypeResponse]);

  useEffect(() => {
    if (uploadedAssetsResponse) {
      const { data, asset_type, category, language } = uploadedAssetsResponse;
      setValue("asset_type", {
        label: toTitleCase(asset_type),
        value: asset_type,
      });
      setValue("category_id", { label: category.name, value: category.id });
      setValue(
        "language",
        assetLanguages.find((lang) => lang.value === language)
      );
      setUploadedAssets(data);
    }
  }, [assetLanguages, setValue, uploadedAssetsResponse]);

  const isLoadingAssetTypes = !assetTypeError && !assetTypeResponse;

  if (isLoadingAssetTypes) {
    return <DelayedSpinner />;
  }

  if (assetTypeError || categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset types and categories. Please try again later"
        )}
      />
    );
  }

  return (
    <AddFilesContainer>
      <SectionTitle style={{ marginBottom: "18px" }}>
        {t("Add files below")}
      </SectionTitle>
      <Form style={{ maxWidth: "512px" }} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="asset_type"
          autoComplete={"asset_type"}
          placeholder={t("Asset Type")}
          rules={{
            required: true,
          }}
          onInputChange={() => {
            setValue("category_id", { label: undefined, value: undefined });
            setValue("files", []);
          }}
          errors={errors}
          formState={formState}
          defaultValue={{ label: undefined, value: undefined }}
          isDisabled={uploadUUID}
          options={assetTypes}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="category_id"
          autoComplete={"category_id"}
          placeholder={t("Category")}
          options={assetCategories}
          rules={{
            required: true,
          }}
          errors={errors}
          isDisabled={!assetType.value || uploadUUID}
          formState={formState}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="language"
          autoComplete={"language"}
          placeholder={t("Language")}
          options={assetLanguages}
          rules={{
            required: true,
          }}
          errors={errors}
          isDisabled={uploadUUID}
          formState={formState}
        />
        <FilePickerUncontrolled
          methodsOfUseForm={methodsOfUseForm}
          placeHolderText={t("Drag and drop files, OR")}
          multiple={true}
          name="files"
          accept={supportedFileTypes}
          description={t("Max file size: 50 mb / file")}
          required={false}
        />
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={isSubmitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
      <GuideSection>
        <GuideContainer style={{ alignSelf: "flex-start", gap: "4px" }}>
          <HelpDialog
            show={showGuide}
            setShow={setShowGuide}
            overview={guidelineOverview}
            guidelines={csvGuidelines}
            guidelineHeader={t("Guidelines for Updating Your Files")}
          />
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t("Need help getting started?")}
          </SmallSectionHeaderRegular>
          <div
            onClick={() => setShowGuide(true)}
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <InfoIcon />
            <H6Bold>{t("View Guide")}</H6Bold>
          </div>
        </GuideContainer>
      </GuideSection>
      {uploadUUID ? (
        <ProcessUploadedAssets
          uploadUUID={uploadUUID}
          uploadNumber={uploadNumber ?? ""}
          assets={uploadedAssets}
          onComplete={() => {
            setUploadUUID(undefined);
            setUploadNumber(undefined);
            onCompleteUpload();
          }}
        />
      ) : (
        <></>
      )}
      <UploadPopups
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        onComplete={() => setShowDialog(false)}
        message={dialogMessage}
        status={status}
        uploadId={uploadNumber ?? ""}
        hideCloseBtn={true}
      />
    </AddFilesContainer>
  );
};
