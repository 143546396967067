import * as React from "react";
import { useEffect, useState } from "react";
import type { ApplyFiltersToURL } from "../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { appendFiltersToUrl } from "../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import { PortfolioClassicView } from "./PortfolioLayouts/PortfolioClassicView";
import { PortfolioModernView } from "./PortfolioLayouts/PortfolioModernView";
import { useStoreState } from "../../util/util";
import type { PortfolioViewType } from "../../types/types";
import { useAuthContext } from "../../components/Auth";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../util/Routing";

export function applyFiltersToURL({
  active_tag_view,
  q,
  filtersFromUrl,
  params,
}: ApplyFiltersToURL): URLSearchParams {
  if (filtersFromUrl) {
    Object.entries(filtersFromUrl)?.forEach((filterList) => {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: filterList[1],
        key: filterList[0],
      });
    });
  }

  if (active_tag_view) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: [active_tag_view],
      key: "active_tag_view",
    });
  }

  if (q) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: [q],
      key: "q",
    });
  }

  return params;
}

export const PortfoliolayoutOptions = [
  { value: "classic", label: "Classic" },
  { value: "modern", label: "Modern" },
];

export const PublicIndexWrapper = () => {
  const { storefront_metadata } = useStoreState();
  const { roleIsSomeKindOfBuyer } = useAuthContext();
  const history = useHistory();
  const { adminPath } = useRoutePath();
  const { default_portfolio_view } = storefront_metadata;
  const [portfolioLayout, setPortfolioLayout] = useState<PortfolioViewType>(
    (sessionStorage.getItem("portfolioView") as PortfolioViewType) ||
      default_portfolio_view ||
      "modern"
  );

  useEffect(() => {
    if (roleIsSomeKindOfBuyer) {
      history.push(`${adminPath}/pim/products`);
    }
  }, [roleIsSomeKindOfBuyer, adminPath, history]);

  return portfolioLayout === "modern" ? (
    <PortfolioModernView setPortfolioLayout={setPortfolioLayout} isPublic />
  ) : (
    <PortfolioClassicView setPortfolioLayout={setPortfolioLayout} />
  );
};
