import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import type {
  AttributeGroupSummary,
  AttributeSchema,
  PIMProduct,
} from "../../../../types/types.PIM";
import { TwoColumGrid } from "../../../../layout/portalPageLayout";
import type { ProductGroupFormValues } from "../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import { AttributeGroupFormGenerator } from "../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import { useForm } from "react-hook-form";
import { useStoreState, useUpdateProductStatus } from "../../../../util/util";
import type { AxiosError } from "axios";
import axios from "axios";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { useAuthContext } from "../../../../components/Auth";
import moment from "moment";
import type { DataMutate, OptionType, UUID } from "../../../../types/types";
import { normalizeAttributeName } from "../../../admin/SellerAdmin/PIM/components/PIM.components.util";
import { useRoutePath } from "../../../../util/Routing";
import type { ChipType } from "../../../../components/Chips/Chips";
import ReactTooltip from "react-tooltip";
import { LinkAttributeValueSchema } from "../../../../util/zod.util";
import { Link } from "react-router-dom";
import { AccordionCardWithEditFeatures } from "../../../../components/AccordionCard/AccordionCardwithEditFeatures";
import { endpoints } from "../../../../endpoints";

interface IProductGroupCardProps {
  product: PIMProduct;
  group: AttributeGroupSummary;
  mutateProduct: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  key: string;
  isPortfolio?: boolean;
}
interface IProductGroupCardDetailsProps {
  group: AttributeGroupSummary;
  isPortfolio?: boolean;
}

const EditFormWrapper = styled.div`
  margin-top: 20px;
`;

const InnerTitleText = styled.p`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 10px 0 5px;
`;

const InnerContentText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 2px 0 7px;
`;

const UL = styled.ul`
  padding-left: 8px;
  margin: 5px 0 10px;
  margin-left: 13px;
  li {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 1.33;
    padding-bottom: 5px;
    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.regular};
    }
    span {
      margin-left: -2px;
      position: relative;
    }
  }
`;

const ProductGroupCardDetails = ({
  group,
  isPortfolio = false,
}: IProductGroupCardDetailsProps) => {
  const { t } = useTranslation();
  return (
    <TwoColumGrid>
      {group.attributes.reduce<JSX.Element[]>((acc, attribute) => {
        // if is portfolio and empty return nothing, not even the attribute name Play defense against bad
        // data
        if (isPortfolio) {
          if (!!attribute.values?.[0]?.value && !attribute.is_restricted) {
            acc.push(
              <div key={attribute.id}>
                <InnerTitleText>
                  {attribute.display_name
                    ? t([attribute.display_name])
                    : attribute.name}
                </InnerTitleText>
                <AttributeValueDisplay
                  attribute={attribute}
                  isPortfolio={isPortfolio}
                />
              </div>
            );
          }
        } else {
          acc.push(
            <div key={attribute.id}>
              <InnerTitleText>
                {attribute.display_name
                  ? t([attribute.display_name])
                  : attribute.name}
              </InnerTitleText>
              <AttributeValueDisplay
                attribute={attribute}
                isPortfolio={isPortfolio}
              />
            </div>
          );
        }
        return acc;
      }, [])}
    </TwoColumGrid>
  );
};

const AttributeValueDisplay = ({
  attribute,
  isPortfolio,
}: {
  attribute: AttributeSchema;
  isPortfolio: boolean;
}) => {
  const { storePath, adminPath } = useRoutePath();
  const { t } = useTranslation();
  const { roleIsSellerAdmin, user } = useAuthContext();

  switch (attribute.input_type) {
    case "single_select":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "numeric":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "date":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? moment(attribute.values[0].value as string)?.format("MM/DD/YYYY")
            : "--"}
        </InnerContentText>
      );
    case "form_field":
      return (
        <InnerContentText>
          {attribute.values?.length && attribute.values[0].value !== " "
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
    case "multiline_entry":
      return (
        <>
          {attribute.values?.length ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  `<div class="ql-editor">${
                    attribute.values[0].value as string
                  }</div>` || "--",
              }}
            />
          ) : (
            <InnerContentText>--</InnerContentText>
          )}
        </>
      );
    case "toggle":
      return (
        <>
          <ToggleSwitch
            label={attribute.display_name ? t([attribute.display_name]) : ""}
            name={attribute.name}
            isChecked={
              attribute.values?.length
                ? (attribute.values[0].value as boolean)
                : false
            }
            key={attribute.id}
            disabled={true}
          />
        </>
      );
    case "multi_select":
      return attribute.values?.length ? (
        <UL>
          {attribute.values.map((value) => {
            return (
              <li key={value.id}>
                <span>{value.enum_variant?.variant}</span>
              </li>
            );
          })}
        </UL>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    case "link":
      const parsed = LinkAttributeValueSchema.safeParse(
        attribute.values?.[0]?.value
      );

      if (parsed.success && parsed.data.url) {
        return (
          <InnerContentText>
            <a href={parsed.data.url} target={"_blank"} rel="noreferrer">
              {parsed.data.display_text}
            </a>
          </InnerContentText>
        );
      } else if (
        parsed.success &&
        !parsed.data.url &&
        parsed.data.display_text !== ""
      ) {
        return <span>{parsed.data.display_text}</span>;
      } else return <span>--</span>;
    case "product_reference":
      return attribute.values?.length ? (
        <UL>
          {attribute.values.map((value) => {
            const tenantHasAccess =
              value?.product_ref?.visibility_tenants?.find(
                (tenant) => tenant?.id === user?.tenant_id
              )?.id;
            return (
              <li key={value.id}>
                {(value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_visible_on_storefronts &&
                  (value?.product_ref?.is_accessible || tenantHasAccess)) ||
                roleIsSellerAdmin ||
                (value?.product_ref?.status === "published" &&
                  value?.product_ref?.is_accessible &&
                  user) ||
                (value?.product_ref?.status === "published" &&
                  tenantHasAccess) ? (
                  <Link
                    to={
                      isPortfolio
                        ? `${storePath}/product/${value?.product_ref?.slug}`
                        : `${adminPath}/pim/products/${
                            value?.product_ref?.product_number ??
                            value?.product_ref?.id
                          }`
                    }
                  >
                    {value.product_ref?.name}
                  </Link>
                ) : (
                  <>
                    <span
                      data-tip={
                        value?.product_ref?.status !== "published"
                          ? t("Product informations is unavailable to view")
                          : t("Product informations is not accessible")
                      }
                      data-for={value.id}
                    >
                      {value.product_ref?.name}
                    </span>
                    <ReactTooltip
                      delayHide={200}
                      id={value.id}
                      effect="solid"
                    />
                  </>
                )}
              </li>
            );
          })}
        </UL>
      ) : (
        <InnerContentText>--</InnerContentText>
      );
    default:
      return (
        <InnerContentText>
          {attribute.values?.length
            ? (attribute.values[0].value as string)
            : "--"}
        </InnerContentText>
      );
  }
};

export const ProductGroupCard = ({
  product,
  group,
  mutateProduct,
  isPortfolio = false,
  replaceProductId,
}: IProductGroupCardProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const updateProductStatus = useUpdateProductStatus({ product });
  const { hasPermission } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const methodsOfUseForm = useForm();

  const getGroupPatchData = (formValues: ProductGroupFormValues) => {
    const keys = Object.keys(formValues).filter(
      (key) =>
        formValues[key] !== undefined &&
        formValues[key] !== "" &&
        formValues[key] !== " " &&
        formValues[key] !== null &&
        // link fields output an extra field with an empty string as key
        // containing the url only in production builds.
        key !== "display_text" &&
        key !== ""
    );

    let valuesArray: {
      attribute_id: string | undefined;
      value:
        | string
        | number
        | boolean
        | ChipType
        | { display_text: string; url: string }
        | Record<string, string>;
    }[] = [];

    keys.forEach((key) => {
      if (Array.isArray(formValues[key])) {
        (
          formValues[key] as Array<string | boolean | number | ChipType>
        ).forEach((value: string | boolean | number | ChipType) => {
          valuesArray.push({
            attribute_id: group.attributes.find(
              (attr) =>
                normalizeAttributeName(attr.name) ===
                normalizeAttributeName(key)
            )?.id,
            value: (value as ChipType).id ?? value,
          });
        });
      } else {
        const attributeValue = () => {
          const attribute = group.attributes.find(
            (attr) => attr.name.replace(/ /g, "_") === key.replace(/ /g, "_")
          ) as AttributeSchema;

          if (attribute?.input_type === "date") {
            return moment(formValues[key] as string).format("YYYY-MM-DD");
          } else if (
            attribute?.input_type === "single_select" ||
            (attribute?.input_type === "product_reference" &&
              !attribute.is_multiple_choice)
          ) {
            return (formValues[key] as unknown as OptionType).value as string;
          } else if (attribute?.input_type === "link") {
            return formValues[key] as { display_text: string; url: string };
          } else if (attribute?.input_type === "toggle") {
            return JSON.stringify(formValues[key]);
          }
          return formValues[key] as string | boolean | number;
        };

        const value = attributeValue();
        if (value) {
          valuesArray.push({
            attribute_id: group.attributes.find(
              (attr) =>
                normalizeAttributeName(attr.name) ===
                normalizeAttributeName(key)
            )?.id,
            value: value,
          });
        }
      }
    });

    const valuesData = {
      values: valuesArray,
    };
    return valuesData;
  };
  const onSubmit = async (formValues: ProductGroupFormValues) => {
    try {
      setIsLoading(true);
      const { data: updatedProduct } = await updateProductStatus();

      const values = getGroupPatchData(formValues);
      const { data } = await axios.put<PIMProduct>(
        `/v2/tenants/${tenant_id}/pim/products/${updatedProduct.id}/groups/${group.id}`,
        values
      );
      if (
        data.display_status === "staged" ||
        data.display_status === "unpublished_staged"
      ) {
        const product_id =
          data.product_number ?? data.primary_staged_product_id ?? data.id;
        replaceProductId(product_id);
      } else {
        await mutateProduct();
      }
      notifySuccess(t("Your changes have been saved"));
      setIsEditMode(false);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error saving your changes"),
        {
          error,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateOption = async (value: string, attribute: AttributeSchema) => {
    if (!hasPermission("modify_lists")) {
      notifyError(
        t("You don't have permission to modify list {{list_name}}", {
          list_name: attribute.list_name,
        })
      );
      return;
    }
    try {
      const response = await axios.post<{ name: string; id: UUID }>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id_items(
          tenant_id,
          attribute.list_id
        ),
        {
          name: value,
        }
      );
      if (attribute.input_type === "single_select") {
        methodsOfUseForm.setValue(attribute.name, {
          value: response.data.id,
          label: value,
        });
        mutateProduct();
      }
      notifySuccess(
        t("{{value}} was saved to the {{list_name}} list", {
          value: value,
          list_name: attribute.list_name,
        })
      );
    } catch (error) {
      notifyError(
        t("There was an error adding {{value}} to the {{list_name}} list", {
          value: value,
          list_name: attribute.list_name,
        }),
        { error }
      );
    }
  };

  return (
    <AccordionCardWithEditFeatures
      title={group.display_name ? t([group.display_name]) : t(group.name)}
      handleEdit={
        !isPortfolio &&
        hasPermission("modify_products") &&
        product.is_editable &&
        product.status !== "archived"
          ? () => setIsEditMode(!isEditMode)
          : undefined
      }
      expanded={true}
      editing={isEditMode}
    >
      {!isEditMode && (
        <ProductGroupCardDetails group={group} isPortfolio={isPortfolio} />
      )}
      {!isPortfolio && isEditMode && product.status !== "archived" && (
        <EditFormWrapper>
          <AttributeGroupFormGenerator
            attributes={group.attributes as AttributeSchema[]}
            onSubmit={onSubmit}
            onCancel={() => setIsEditMode(false)}
            methodsOfUseForm={methodsOfUseForm}
            onCreateOption={onCreateOption}
            isLoading={isLoading}
          />
        </EditFormWrapper>
      )}
    </AccordionCardWithEditFeatures>
  );
};
