import React, { useEffect, useState } from "react";
import useSWR from "swr";
import type { TagClassificationConfig } from "../../../../types/types";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import type { SortingRule } from "react-table";
import { useDebounce } from "../../../../util/hooks";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { PrimaryButtonWithPlusIcon } from "../../../../components/Buttons/Buttons";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import {
  PageWrapper,
  ContentWrapper,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../../layout/portalPageLayout";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "../../../../components/Modal/Modal";
import { CreateProductForm } from "./CreateProduct/CreateProduct";
import { useRoutePath } from "../../../../util/Routing";
import {
  formatDate,
  updateCustomLabels,
  useStoreState,
  useSupportedLanguages,
  defaultHandleSort,
} from "../../../../util/util";
import { endpoints } from "../../../../endpoints";
import { useTranslation } from "react-i18next";
import { TooltipCell } from "../../../Buyer/BuyerOrders/BuyerOrders.util";
import ReactTooltip from "react-tooltip";
import { Flex, Flex1, Flex2 } from "../../../../layout/FormLayout";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import type {
  PIMProductBasePaginagedOutput,
  ProductStatusType,
} from "../../../../types/types.PIM";
import { FiltersArea } from "../../../../components/Filters/Filters";
import {
  getProductStatusColor,
  getProductStatusText,
  StatusLeft,
} from "../../../../components/Status/Status";
import { applyFiltersToURL } from "./ProductsList.util";
import type { ConstructQuery, SellerTableProduct } from "./ProductsList.util";
import { TableWithBulkAction } from "../../../../components/Table/TableWithBulkAction";
import { BulkActionOnProducts } from "./BulkActionOnProducts/BulkActionOnProducts";
import styled from "styled-components";
import { WithPermission } from "../../../../components/WithPermission/WithPermission";
import { useAuthContext } from "../../../../components/Auth";
import { Table } from "../../../../components/Table/Table";

export const TotalProducts = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 0;
`;

/**
 * Products list page (e.g. manage > products).  Used for seller admin.
 */
export function ProductsList() {
  const { t } = useTranslation();

  const { supportedLanguageOptions } = useSupportedLanguages();
  const { hasPermission } = useAuthContext();

  const {
    storefront_id,
    tenant_id,
    storefront_metadata: { only_bulk_upload_enabled },
  } = useStoreState();

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null,
    { revalidateOnFocus: true }
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);
  const queryObject = () => {
    const querylist: any = {
      product: StringParam,
      q: StringParam,
      offset: NumberParam,
      perPage: NumberParam,
    };

    return querylist;
  };

  const [query, setQuery] = useQueryParams(queryObject());

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();

  const { search, pathname } = useLocation();
  useEffect(() => {
    const groupParamsByKey = (params: any) =>
      [...params.entries()].reduce((acc, tuple) => {
        const [key, val] = tuple;
        if (acc.hasOwnProperty(key)) {
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            acc[key] = [acc[key], val];
          }
        } else {
          acc[key] = [val];
        }

        return acc;
      }, {});

    const params = new URLSearchParams(search.substring(1));
    const filters = groupParamsByKey(params);
    setFiltersFromUrl(filters);
  }, [search]);
  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const { adminPath } = useRoutePath();
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(query.perPage ?? 10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
    totalCount: 0,
  });

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const history = useHistory();
  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({ baseUrl, query, filtersFromUrl }: ConstructQuery) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );

    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    paramsWithOffset.append("show_inactive", "true");
    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        filtersFromUrl,
        params: paramsWithOffset,
      })
    );
  }

  const {
    data: productsResponse,
    error: productsError,
    mutate: mutateProducts,
  } = useSWR<PIMProductBasePaginagedOutput>(
    showModal
      ? null
      : constructQuery({
          baseUrl: endpoints.v2_tenants_id_pim_products_summary(tenant_id),
          query: debouncedSearchQuery,
          filtersFromUrl,
        })
  );

  const isLoading = !productsResponse && !productsError;
  //table

  const [tableData, setTableData] = useState<SellerTableProduct[]>([]);
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Product ID"),
        accessor: "externalId",
        disableSortBy: true,
      },
      {
        Header: t("Product Name"),
        accessor: "name",
      },
      {
        Header: t("Chemical Name"),
        accessor: "chemical_name",
      },
      {
        Header: t("Last Modified"),
        accessor: "modified_at",
        Cell: ({ value }: { value: { date: string; id: string } }) => {
          return (
            <TooltipCell data-for={`${value.id}-tooltip`}>
              {value.date}
              <ReactTooltip
                id={`${value.id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                multiline={true}
              />
            </TooltipCell>
          );
        },
      },
      {
        Header: t("Product Completion"),
        accessor: "completion_score",
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        align: "left",
        Cell: ({ value: status }: { value: ProductStatusType }) => (
          <StatusLeft
            color={getProductStatusColor(status ?? "draft")}
            text={getProductStatusText(status ?? "draft", t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  const handleClearFilter = () => {
    const destination = pathname;
    history.replace(destination);
    setQuery({ q: query.q, offset: query.offset, perPage: query.per_Page });
    mutateProducts();
  };

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: PIMProductBasePaginagedOutput) => {
      setTableData(
        data.map(
          ({
            id,
            name,
            last_modified_full: { modified_at },
            display_status,
            status,
            primary_staged_product_id,
            completion_score,
            display_completion_score,
            product_number,
            chemical_name,
          }) => {
            return {
              id: product_number ?? primary_staged_product_id ?? id,
              externalId: product_number ?? "--",
              name: name ?? "--",
              chemical_name: chemical_name ?? "--",
              modified_at: {
                date: formatDate(modified_at),
                id,
              },
              completion_score:
                display_status === "staged" ||
                display_status === "unpublished_staged"
                  ? `${
                      display_completion_score ? display_completion_score : "--"
                    }%`
                  : `${completion_score ? completion_score : "--"}%`,
              status: display_status ?? status ?? "draft",
            };
          }
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
        totalCount: pagination.total,
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, perPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    setQuery({ offset, perPage });
  }, [offset, perPage, setQuery]);

  useEffect(() => {
    // This useEffect handles setting the selected product,
    // and keeping the selected product and query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      // update the URL every time the debounced query changes
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  useEffect(() => {
    if (search) {
      mutateProducts();
    }
  }, [search, mutateProducts]);

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    const basePath = `${adminPath}/pim/products`;
    history.push(`${basePath}/${e.currentTarget.id}`);
  };

  return (
    <PageWrapper>
      <PageTitle>{t("Products")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by product name or CAS#")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        {/* All sellers can create new products. */}
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            <WithPermission permission="modify_products">
              <PrimaryButtonWithPlusIcon
                onClick={() => history.push(`${adminPath}/pim/products/new`)}
                style={{ padding: "8px 12px" }}
              >
                {only_bulk_upload_enabled ? t("Upload") : t("Create")}
              </PrimaryButtonWithPlusIcon>
            </WithPermission>
          </div>
        </Flex1>
      </Flex>
      <Modal closeModal={closeModal} show={showModal}>
        <CreateProductForm />
      </Modal>
      {!customLabelsError && (
        <FiltersArea
          resetOffset={setOffset}
          customLabels={customLabels ?? []}
          clearFilter={() => handleClearFilter()}
          showStatusFilter={true}
        />
      )}
      <DropdownContainer style={{ position: "relative", zIndex: 2 }}>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        ></DropDown>
        <TotalProducts>
          {tablePagination.totalCount} {t("products")}
        </TotalProducts>
      </DropdownContainer>
      <ContentWrapper style={{ position: "relative", zIndex: 1 }}>
        {hasPermission("modify_products") ? (
          <TableWithBulkAction
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={productsError}
            rowClick={handleRowClick}
            isCheckBoxRequired={true}
            handleSort={handleSort}
            bulkActionElement={({ rows, setResetSelectedRows }) => (
              <BulkActionOnProducts
                rows={rows}
                mutateProducts={mutateProducts}
                setResetSelectedRows={setResetSelectedRows}
              />
            )}
          />
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={productsError}
            rowClick={handleRowClick}
            handleSort={handleSort}
          />
        )}

        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
