import type { SVGButtonProps } from "../../../components/Buttons/Buttons";
import {
  ButtonWithConfirmDialog,
  CancelButton,
  GoBackButton,
  PrimaryButtonFitContainer,
  PrimaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { IDDesktop } from "../../../components/IDs/IDs";
import { InfoBlockSmall } from "../../../components/InfoBlocks/InfoBlocks";
import {
  HeaderLeft,
  HeaderRight,
  Row,
} from "../../../components/Layout/Layout";
import {
  H3,
  SoftHeader,
  SoftHeaderPrimaryTextColor,
} from "../../../components/Typography/Typography";
import type {
  SampleRequest,
  SampleRequestDetail,
  SampleRequestStatus,
  SampleStatusLabel,
  SampleRequestNextStatusSchema,
  IShipmentAdvice,
  User,
  PaginatedTransactionEvents,
  DataMutate,
  OptionType,
  ProductSKU,
  UUID,
  AccountManagerContactInfo,
} from "../../../types/types";
import { Flex, Form } from "../../../layout/FormLayout";
import {
  PageHeader,
  PageTitle,
  Card,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import Axios from "axios";
import type { AxiosError } from "axios";
import React, { useContext, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { ContactInfoBlockSmall } from "../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";

import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../endpoints";
import {
  DetailPageContentWrapper,
  QuoteOrderContent,
  TimelineWrapper,
  WideDetails,
} from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import { useRoutePath } from "../../../util/Routing";
import {
  convertSampleLabelToSampleStatus,
  convertSampleStatusToSampleLabel,
  convertSampleStatusToSampleStatusAction,
  formatDateTime,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import { SampleRequestStatusLabel } from "../SampleRequestListPage/SampleRequestListPage";
import { HorizontalSeparator } from "../SellerQuoteDetailPage/SellerQuoteDetailPageContent";
import { Auth, useAuthContext } from "../../../components/Auth";
import { SampleRequestItem } from "../../../components/TransactionItem/SampleRequestItem";
import { ShipmentAdviceSection } from "../../../components/ShipmentAdviceSection/ShipmentAdviceSection";
import { SellerCreateOrEditShipmentAdvice } from "../../../components/SellerCreateOrEditShipmentAdvice/SellerCreateOrEditShipmentAdvice";
import { useTranslation } from "react-i18next";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import useSWR from "swr";
import {
  TransactionStatusPath,
  isPartManualSampleStatusChanges,
} from "../../../components/OrderStatusPath/OrderStatusPath";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { DropDown } from "../../../components/DropDown/DropDown";
import { Timeline } from "../../../components/Timeline/Timeline";
import { AddDocumentToTransaction } from "../AddDocumentToTransaction/AddDocumentToTransaction";
import { TransactionsDocumentView } from "../../../components/DocumentView/TransactionsDocumentView";
import { get_existing_documents } from "../AddDocumentToTransaction/utils";
import ReactTooltip from "react-tooltip";
import { yupResolver } from "@hookform/resolvers/yup";
import { positiveIntegerRegex } from "../../../util/regexes";
import { strings } from "../../../util/strings";
import * as yup from "yup";
import { SampleRequestItemCardSellerResponding } from "../../../components/TransactionItem/SampleRequestItemCardSellerResponding";
import type { CustomSkuData } from "../SellerQuoteDetailPage/AddCustomSkuForm";
import { TransactionSplitForm } from "../../../components/TransactionSplitForm/TransactionSplitForm";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import axios from "axios";

const ItemsContainer = styled.div`
  margin: 20px 0;
`;

const ButtonRow = styled(Row)`
  margin-top: 40px;
`;

const SampleRequestContent = QuoteOrderContent;

const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  & > div[class*="order_status"] {
    position: relative;
    top: -6px;
    min-width: 140px;
  }
`;

const WarningText = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-top: 4px;
`;

function getModeFromStatus(
  status: Omit<SampleRequestStatus, "new">
): "seller_complete" | "seller_responding" {
  switch (status) {
    case "requested":
      return "seller_responding";
    default:
      return "seller_complete";
  }
}

type SampleresponseForm = {
  delivery_term: OptionType;
  payment_term: OptionType;
  payment_mode: OptionType;
} & {
  /**
   * We dynamically generate the (string) keys for the quote item inputs at
   * runtime, for example:
   *   "sku_92ebf76a-552a-48d4-a0ea-8248fab8eff7": OptionType<string>
   *   "number_of_units_92ebf76a-552a-48d4-a0ea-8248fab8eff7": string
   *   "price_per_unit_92ebf76a-552a-48d4-a0ea-8248fab8eff7": string
   *   "sku_7486c6e2-dc51-4958-bc31-e00399046d04: OptionType<string>
   *   "number_of_units_7486c6e2-dc51-4958-bc31-e00399046d04: string
   *   "price_per_unit_7486c6e2-dc51-4958-bc31-e00399046d04: string
   */
  // `undefined` is in here because price_per_unit can be undefined if there
  // are price tiers and in any case if you use the wrong string as a key you
  // get undefined.
  [id: string]:
    | string
    | OptionType<ProductSKU>
    | OptionType<CustomSkuData>
    | undefined;
};
const makeInputIdSku = (id: string) => `sku_${id}`;
const makeInputIdCustomSku = (id: string) => `custom_sku_${id}`;
const makeInputIdNumberOfUnits = (id: string) => `number_of_units_${id}`;
const makeInputIdPricePerUnit = (id: string) => `price_per_unit_${id}`;

/**
 * Content for sample request detail pages.
 */
export const SampleRequestDetailPageContent = ({
  sampleRequest,
  mutateSampleRequest,
}: {
  sampleRequest: SampleRequest;
  mutateSampleRequest: DataMutate<SampleRequest>;
}) => {
  const { storefront_id } = useStoreState();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { accountPath } = useRoutePath();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });
  const [showSplitSampleForm, setShowSplitSampleForm] = useState(false);
  const { roleIsSomeKindOfBuyer, roleIsSomeKindOfSeller } = useContext(Auth);

  const { t } = useTranslation();

  const [showShipmentAdviceForm, setShowShipmentAdviceForm] = useState(false);

  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);

  const [adviceToEdit, setAdviceToEdit] =
    useState<IShipmentAdvice | undefined>(undefined);

  const [showStatusChangeDialogBox, setShowStatusChangeDialogBox] =
    useState(false);

  const [selectedSampleStatus, setSelectedSampleStatus] =
    useState<SampleRequestStatus>();

  const [is_splitting_sample, set_is_splitting_sample] = useState(false);

  const methodsOfUseForm = useFormWrapper();
  const { register } = methodsOfUseForm;
  const { user, roleIsSellerAdmin } = useAuthContext();
  const history = useHistory();

  const mode: "seller_responding" | "seller_complete" = getModeFromStatus(
    sampleRequest.status
  );
  const sellerResponding = mode === "seller_responding";
  const sellerComplete = mode === "seller_complete";
  const [editing, setEditing] = useState(false);

  const formSchema = useMemo(() => {
    return yup.object().shape({
      feesList: yup.array(
        yup.object().shape({
          name: yup.string(),
          amount: yup
            .number()
            .min(0, t("Must be an integer value"))
            .typeError(strings(t).thisIsARequiredField)
            .required(strings(t).thisIsARequiredField),
        })
      ),
      ...sampleRequest.items.reduce<Record<string, unknown>>((result, item) => {
        result[makeInputIdNumberOfUnits(item.id)] = yup
          .string()
          .required(strings(t).thisIsARequiredField)
          // Use a regex to validate because it's a string.
          .matches(positiveIntegerRegex, t("Must be an integer value"));
        result[makeInputIdSku(item.id)] = yup
          .object()
          .required(strings(t).thisIsARequiredField);

        return result;
      }, {}),
    });
  }, [t, sampleRequest.items]);

  const methodsOfUseFormUpdate = useFormWrapper({
    resolver: yupResolver(formSchema),
    // We have to set the default values here for pre-population of
    // the existing `feesList` values to work.
  });

  const { formState: formStateUpdate, handleSubmit: handleSubmitUpdate } =
    methodsOfUseFormUpdate;

  const removeCartItem = async (itemToRemove: SampleRequestDetail) => {
    // If a buyer removes the last item from the request, the backend
    // automatically transitions it to "cancelled" status.
    try {
      await Axios.delete(
        endpoints.v1_storefronts_id_sampleRequests_id_items_id(
          storefront_id,
          sampleRequest.id,
          itemToRemove.id
        )
      );
      notifySuccess(t("The item has been deleted successfully"));
      mutateSampleRequestAndEvents();
    } catch (error) {
      notifyError(t("There was an error deleting the item"), { error });
    }
  };

  const onSubmit = async (values: SampleresponseForm) => {
    try {
      const patchRecipes = sampleRequest.items.map((item) => {
        const skuOption = values[makeInputIdSku(item.id)];
        const no_of_units = values[makeInputIdNumberOfUnits(item.id)];
        const item_id = item.id;

        // Should never be null, for type checker.
        const skuOptionOption =
          typeof skuOption === "object" && "id" in skuOption.value
            ? (skuOption as OptionType<ProductSKU>)
            : null;

        const sku_id = skuOptionOption?.value.id;

        return {
          sku_id,
          no_of_units,
          total_quantity: item.total_quantity,
          item_id,
        };
      });

      const patchData = {
        data: patchRecipes,
      };

      await Axios.patch(
        `/v1/storefronts/${storefront_id}/sample-requests/${sampleRequest.id}/accept`,
        patchData
      );
      notifySuccess(t("The sample request was accepted"));
      mutateSampleRequestAndEvents();
      setEditing(false);
    } catch (error) {
      notifyError(t("There was an error accepting the sample request"), {
        error,
      });
    }
  };

  /**
   * Sellers can reject a sample request.
   */
  const rejectSampleRequest = async () => {
    try {
      await Axios.patch(
        endpoints.v1_storefronts_id_sampleRequests_id_action(
          storefront_id,
          sampleRequest.id,
          "reject"
        )
      );
      notifySuccess(t("The sample request was rejected"));
      mutateSampleRequestAndEvents();
    } catch (error) {
      notifyError(t("There was an error rejecting the sample request"), {
        error,
      });
    }
  };

  /**
   * Buyers can cancel a sample request.
   */
  const cancelSampleRequest = async () => {
    try {
      await Axios.patch(
        endpoints.v1_storefronts_id_sampleRequests_id_action(
          storefront_id,
          sampleRequest.id,
          "cancel"
        )
      );
      notifySuccess(t("The sample request was cancelled"));
      mutateSampleRequestAndEvents();
    } catch (error) {
      notifyError(t("There was an error canceling the sample request"), {
        error,
      });
    }
  };

  const showDeleteItemButton =
    roleIsSomeKindOfBuyer && sampleRequest.status === "requested";

  const sampleRequestCanBeSplit =
    sampleRequest.status !== "completed" &&
    sampleRequest.status !== "cancelled" &&
    sampleRequest.status !== "rejected" &&
    !sampleRequest.status.includes("pending") &&
    sampleRequest.items.length > 1 &&
    roleIsSomeKindOfSeller;

  const updateSampleStatusAndCloseDialogBox = async () => {
    setShowStatusChangeDialogBox(false);
    try {
      await Axios.patch(
        endpoints.v1_storefronts_id_sampleRequests_id_action(
          storefront_id,
          sampleRequest.id,
          convertSampleStatusToSampleStatusAction(selectedSampleStatus!)
        )
      );
      await mutateSampleRequestAndEvents();
    } catch (error) {
      const message = (error as AxiosError)?.response?.data?.message
        ? (error as AxiosError)?.response?.data?.message
        : t("There was an error updating the sample status");
      notifyError(message as string, { error });
      setSelectedSampleStatus(sampleRequest.status);
    }
  };

  const closeSampleStatusDialog = () => {
    setShowStatusChangeDialogBox(false);
    setSelectedSampleStatus(sampleRequest.status);
  };

  const handleSellerStatusChange = (label: SampleStatusLabel) => {
    const status = convertSampleLabelToSampleStatus(label);
    if (status !== sampleRequest.status && status !== selectedSampleStatus) {
      setShowStatusChangeDialogBox(true);
      setSelectedSampleStatus(status);
    }
  };

  let accountManagerContactInfo: AccountManagerContactInfo | undefined =
    undefined;
  if (sampleRequest && sampleRequest.sold_by) {
    accountManagerContactInfo = sampleRequest.sold_by.tenant_user_contact_info;
  }

  const canEdit =
    sampleRequest.status !== "completed" &&
    sampleRequest.status !== "rejected" &&
    sampleRequest.status !== "cancelled" &&
    sampleRequest.status !== "new" &&
    !sampleRequest.status.includes("pending") &&
    sampleRequest.status !== "shipped";

  const canShowStatusPath = () =>
    sampleRequest.status !== "rejected" && sampleRequest.status !== "cancelled";

  const has_unlisted_products = sampleRequest.items.some(
    (product) => !product.product_id
  );

  const canShowStatusDropdown = () =>
    sampleRequest.status !== "new" &&
    sampleRequest.status !== "completed" &&
    !sampleRequest.status.includes("pending") &&
    !has_unlisted_products &&
    roleIsSomeKindOfSeller;

  const canShowDeclineAcceptButton = () =>
    (sampleRequest.status === "requested" ||
      sampleRequest.status.includes("pending")) &&
    roleIsSomeKindOfSeller;

  const acceptButtonDisabled = sampleRequest.status.includes("pending");

  const confirmSampleStatusChangeMessage =
    selectedSampleStatus !== "completed"
      ? t("Are you sure you want to change the status to {{sampleStatus}}?", {
          sampleStatus: convertSampleStatusToSampleLabel(
            // there will always be selectedSampleStatus at this point
            // But this is to satisfy typescript
            selectedSampleStatus ?? "accepted"
          ),
        })
      : ((
          <>
            {t("Are you sure you want to change the status to Completed?")}
            <WarningText>
              {t(
                "There will be no further changes allowed in any of the sections"
              )}
            </WarningText>
          </>
        ) as unknown as string);

  const params = new URLSearchParams();
  if (query.q) {
    params.append("q", query.q);
  }
  params.append("offset", String(query?.offset ?? 0));
  params.append("perPage", String(query?.perPage ?? 10));
  ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
    (status) => params.append("status", status)
  );
  const [formattedStatusNames, setFormattedStatusNames] = useState<string[]>(
    []
  );

  const statusOrder = [
    "new",
    "accepted",
    "in_progress",
    "shipped",
    "completed",
  ];
  useSWR<SampleRequestNextStatusSchema, AxiosError>(
    sampleRequest.status
      ? `/v1/storefronts/sample-requests/${sampleRequest.status}`
      : null,
    {
      onSuccess: (response) => {
        const sortedStatuses = response.transitions
          .filter((item) => isPartManualSampleStatusChanges(item))
          .map((item) => convertSampleStatusToSampleLabel(item))
          .sort(
            (a, b) =>
              statusOrder.indexOf(a.toLowerCase()) -
              statusOrder.indexOf(b.toLowerCase())
          );

        setFormattedStatusNames(sortedStatuses);
      },
    }
  );

  const { data, mutate: mutateEvents } = useSWR<
    PaginatedTransactionEvents,
    AxiosError
  >(
    sampleRequest?.id
      ? `/v1/storefronts/${storefront_id}/samples/${sampleRequest.id}/events?limit=100`
      : null,
    {
      onSuccess: (events) => events.data.reverse(),
    }
  );

  const sampleEvents = data?.data;

  const mutateSampleRequestAndEvents = () => {
    return Promise.allSettled([mutateSampleRequest(), mutateEvents()]);
  };

  const handleSendMessage = async (message: string) => {
    try {
      await Axios.post(
        `/v1/storefronts/${storefront_id}/samples/${sampleRequest.id}/messages`,
        {
          message: message,
          message_type: "Other", // Adjust according to your API specification
        }
      );
      // Trigger a revalidation to update the list of messages
      await mutateSampleRequestAndEvents();
    } catch (error) {
      console.error(error);
      // Implement error handling, e.g., displaying a notification
    }
  };

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "checkbox",
        Cell: ({ value }: { value: UUID }) => {
          return (
            <>
              <CheckBoxNoLabel
                name={value}
                ref={register({
                  required: false,
                  setValueAs: (value: Array<string>) => Boolean(value.length),
                })}
                data-for="checkbox"
              />
            </>
          );
        },
      },
      {
        Header: t("Name"),
        accessor: "name",
      },
      {
        Header: t("Quantity"),
        accessor: "quantity",
      },
    ],
    [t, register]
  );

  const tableData = sampleRequest.items.map((item) => {
    return {
      name: item.product.name,
      quantity: `${Intl.NumberFormat("en-US").format(
        Number(item.total_quantity)
      )} ${item.total_quantity_packaging_unit?.name || ""}`,
      checkbox: item.id,
    };
  });

  const onSplitSampleRequest = async (values: Record<UUID, boolean>) => {
    set_is_splitting_sample(true);
    try {
      const itemsToSplit = Object.entries(values).reduce<UUID[]>(
        (accumulator, [key, value]) => {
          if (value) {
            accumulator.push(key);
          }
          return accumulator;
        },
        []
      );

      const res: { data: { id: UUID } } = await axios.post(
        `/v1/storefronts/${storefront_id}/samples/${sampleRequest.id}/split`,
        { item_ids: itemsToSplit }
      );
      notifySuccess(t("Sample request split successfully"));
      set_is_splitting_sample(false);
      history.push(`${accountPath}/samples/${res.data.id}`);
    } catch (error) {
      notifyError(t("There was an error splitting the Sample Request"));
      set_is_splitting_sample(false);
    }
  };

  return (
    <PageWrapper>
      <Link to={`${accountPath}/samples?${params}`}>
        <GoBackButton text={t("Sample Requests")} />
      </Link>
      <PageHeader>
        <HeaderLeft>
          <PageTitle>{t("Sample Request")}</PageTitle>
          <IDDesktop>{`${sampleRequest.number}`}</IDDesktop>
        </HeaderLeft>
        <HeaderRight data-testid={"quote-detail-status-container"}>
          <SampleRequestStatusLabel status={sampleRequest.status} t={t} />
        </HeaderRight>
      </PageHeader>
      <Flex>
        <SoftHeader style={{ margin: "0 2px 5px 0" }}>
          {t("Created By")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {sampleRequest.created_by || `--`}
        </SoftHeaderPrimaryTextColor>
      </Flex>
      <Flex>
        <SoftHeader style={{ margin: "0 2px 0 0" }}>
          {t("Created On")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {formatDateTime(sampleRequest.created_at) || `--`}{" "}
        </SoftHeaderPrimaryTextColor>
        {(formatDateTime(sampleRequest.modified_at) !==
          formatDateTime(sampleRequest.created_at) ||
          sampleRequest.created_by !== sampleRequest.modified_by) && (
          <>
            <SoftHeader style={{ margin: "0 2px 0 10px" }}>
              {t("Last Modified")}:
            </SoftHeader>
            <SoftHeaderPrimaryTextColor>
              {sampleRequest.modified_at
                ? `${formatDateTime(sampleRequest.modified_at)} By ${
                    sampleRequest.modified_by
                  }`
                : `--`}
            </SoftHeaderPrimaryTextColor>
          </>
        )}
      </Flex>
      <HorizontalSeparator />
      <DetailPageContentWrapper>
        <SampleRequestContent>
          {canShowStatusPath() && (
            <StatusContainer style={{ position: "relative", zIndex: 1 }}>
              <TransactionStatusPath
                transactionStatus={sampleRequest.status}
                view="sellerSample"
              />
              {canShowStatusDropdown() && formattedStatusNames && (
                <DropDown
                  items={formattedStatusNames}
                  className="order_status"
                  activeItem={convertSampleStatusToSampleLabel(
                    sampleRequest.status
                  )}
                  direction={"left"}
                  clickHandler={handleSellerStatusChange}
                  showIcon
                  placeholder={t("Change status")}
                />
              )}
              <ConfirmDialog
                show={showStatusChangeDialogBox}
                confirmMessage={confirmSampleStatusChangeMessage}
                closeDialog={closeSampleStatusDialog}
                handleConfirm={updateSampleStatusAndCloseDialogBox}
              />
            </StatusContainer>
          )}
          <Card style={{ zIndex: 0 }}>
            <PageHeader>
              <HeaderLeft>
                <H3>{t("Details")}</H3>
              </HeaderLeft>
              {sampleRequestCanBeSplit && (
                <HeaderRight>
                  <PrimaryButtonMedium
                    onClick={() => setShowSplitSampleForm(true)}
                  >
                    {t("Split Sample Request")}
                  </PrimaryButtonMedium>
                </HeaderRight>
              )}
            </PageHeader>
            <HorizontalSeparator />
            <WideDetails>
              <ContactInfoBlockSmall
                address={sampleRequest.shipping_address}
                header={t("Ship to")}
                testid={"shipping-address"}
              />
              <ContactInfoBlockSmall
                address={sampleRequest.seller_address}
                accountManagerContactInfo={accountManagerContactInfo}
                header={t("Sold by")}
                testid={"sold-by-address"}
              />
              <ContactInfoBlockSmall
                address={sampleRequest.requested_by}
                header={t("Requested By")}
                testid={"requested-by-address"}
              />
            </WideDetails>

            <Form noValidate onSubmit={handleSubmitUpdate(onSubmit)}>
              <ItemsContainer>
                {roleIsSomeKindOfSeller &&
                  (sellerResponding || editing) &&
                  sampleRequest.items.map((item, index) => {
                    return (
                      <SampleRequestItemCardSellerResponding
                        sample={sampleRequest}
                        key={item.id + index}
                        item={item}
                        index={index}
                        methodsOfUseForm={methodsOfUseFormUpdate}
                        inputIdSku={makeInputIdSku(item.id)}
                        inputIdCustomSku={makeInputIdCustomSku(item.id)}
                        inputIdNumberOfUnits={makeInputIdNumberOfUnits(item.id)}
                        inputIdPricePerUnit={makeInputIdPricePerUnit(item.id)}
                      />
                    );
                  })}
                {roleIsSomeKindOfBuyer &&
                  sampleRequest.status === "requested" &&
                  sampleRequest.items.map((item, index) => {
                    return (
                      <SampleRequestItem
                        item={item}
                        handleConfirmDelete={
                          showDeleteItemButton
                            ? () => removeCartItem(item)
                            : undefined
                        }
                        index={index}
                        key={index}
                        sample={sampleRequest}
                      />
                    );
                  })}

                {sellerComplete &&
                  !editing &&
                  sampleRequest.items.map((item, index) => {
                    return (
                      <SampleRequestItem
                        item={item}
                        handleConfirmDelete={
                          showDeleteItemButton
                            ? () => removeCartItem(item)
                            : undefined
                        }
                        index={index}
                        key={index}
                      />
                    );
                  })}
              </ItemsContainer>
              <Row>
                <InfoBlockSmall
                  header={t("Decision Maker")}
                  content={
                    sampleRequest.is_decision_maker === true
                      ? t("Yes")
                      : sampleRequest.is_decision_maker === false
                      ? t("No")
                      : "--"
                  }
                />
                <InfoBlockSmall
                  header={t("Decision Timeline")}
                  content={sampleRequest.decision_timeline || "--"}
                />
                <InfoBlockSmall
                  header={t("Requested")}
                  content={sampleRequest.requested_documents.join(", ") || "--"}
                />
              </Row>
              {canShowDeclineAcceptButton() && (
                <ButtonRow>
                  <ButtonWithConfirmDialog
                    Button={(props: SVGButtonProps) => (
                      <CancelButton {...props} style={{ padding: "0" }}>
                        {t("Decline sample request")}
                      </CancelButton>
                    )}
                    testid={"sample-request-reject-button"}
                    handleConfirm={rejectSampleRequest}
                    confirmMessage={t(
                      "Are you sure you want to reject this sample request?"
                    )}
                  />
                  <PrimaryButtonFitContainer
                    type="submit"
                    disabled={acceptButtonDisabled}
                    datafor="accept_button_disabled"
                    datatip={
                      acceptButtonDisabled ? t("Awaiting buyer activation") : ""
                    }
                    style={{ maxWidth: "340px" }}
                    loading={formStateUpdate.isSubmitting}
                  >
                    {t("Accept")}
                  </PrimaryButtonFitContainer>
                  <ReactTooltip id="accept_button_disabled" effect="solid" />
                </ButtonRow>
              )}
              {sampleRequest.status === "requested" && roleIsSomeKindOfBuyer && (
                <ButtonRow>
                  <ButtonWithConfirmDialog
                    Button={(props: SVGButtonProps) => (
                      <CancelButton {...props} style={{ padding: "0" }}>
                        {t("Cancel Request")}
                      </CancelButton>
                    )}
                    testid={"sample-request-cancel-button"}
                    handleConfirm={cancelSampleRequest}
                    confirmMessage={t(
                      "Are you sure you want to cancel this sample request?"
                    )}
                  />
                </ButtonRow>
              )}
            </Form>
          </Card>

          {sampleRequest.status !== "requested" &&
            !sampleRequest.status.includes("pending") && (
              <>
                <Card>
                  <Row>
                    <H3>{t("Shipment Advice")}</H3>
                  </Row>
                  {sampleRequest.shipment_advices &&
                  sampleRequest.shipment_advices?.length > 0 ? (
                    sampleRequest.shipment_advices.map((advice) => (
                      <ShipmentAdviceSection
                        key={advice.id}
                        shipment_advice={advice}
                        orderId={sampleRequest.id}
                        isSampleRequest={true}
                        canEdit={canEdit && roleIsSellerAdmin}
                        mutateData={mutateSampleRequest}
                        handleEditShipmentAdvice={() => {
                          setAdviceToEdit(advice);
                          setShowShipmentAdviceForm(true);
                        }}
                      />
                    ))
                  ) : // If nothing has been created yet the top level object will
                  // not exist.
                  roleIsSomeKindOfBuyer ? (
                    <>{t("Shipment advice document is not available.")}</>
                  ) : (
                    <></>
                  )}
                  {roleIsSomeKindOfSeller && canEdit && (
                    <Row style={{ marginTop: "20px" }}>
                      <PrimaryButtonMedium
                        onClick={() => {
                          setAdviceToEdit(undefined);
                          setShowShipmentAdviceForm(true);
                        }}
                      >
                        {t("Add Shipment Advice")}
                      </PrimaryButtonMedium>
                    </Row>
                  )}
                  {roleIsSomeKindOfSeller && (
                    <SlideOut
                      show={showShipmentAdviceForm}
                      closeFlyout={() => setShowShipmentAdviceForm(false)}
                    >
                      <SellerCreateOrEditShipmentAdvice
                        shipmentAdvice={adviceToEdit}
                        shippingDocument={
                          adviceToEdit?.shipment_advice_document
                        }
                        mode={adviceToEdit ? "edit" : "create"}
                        closeForm={() => {
                          setShowShipmentAdviceForm(false);
                          mutateSampleRequest();
                        }}
                        mutateData={mutateSampleRequest}
                        patchShipmentAdviceDocument={(formData) =>
                          Axios.patch(
                            `/v1/storefronts/${storefront_id}/sample-requests/${sampleRequest.id}/documents/shipping_document`,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                        }
                        postShipmentAdvice={(formData) =>
                          Axios.post(
                            endpoints.v1_storefronts_id_sampleRequests_id_shipmentAdvice(
                              storefront_id,
                              sampleRequest.id
                            ),
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                        }
                        patchShipmentAdvice={(formData) =>
                          Axios.patch(
                            endpoints.v1_storefronts_id_sampleRequests_id_shipmentAdvice_id(
                              storefront_id,
                              sampleRequest.id,
                              adviceToEdit?.id
                            ),
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                        }
                        deleteShipmentAdvice={(documentId) =>
                          Axios.delete(
                            endpoints.v1_storefronts_id_sampleRequests_id_documents_id(
                              storefront_id,
                              sampleRequest.id,
                              documentId
                            )
                          )
                        }
                      />
                    </SlideOut>
                  )}
                </Card>
              </>
            )}
          {roleIsSomeKindOfSeller && (
            <SlideOut
              show={showAddDocumentForm}
              closeFlyout={() => setShowAddDocumentForm(false)}
            >
              <AddDocumentToTransaction
                existing_documents={get_existing_documents({
                  items: sampleRequest.items,
                  other_documents: sampleRequest.other_documents,
                })}
                products={sampleRequest.items
                  .filter((item) => item.product_id)
                  .map(({ product: { name }, product_id }) => ({
                    name,
                    id: product_id,
                  }))}
                transaction_type="sample-requests"
                transaction_type_id={sampleRequest.id}
                fetchData={mutateSampleRequest}
                onComplete={() => setShowAddDocumentForm(false)}
              />
            </SlideOut>
          )}
          {roleIsSomeKindOfSeller && (
            <SlideOut
              show={showSplitSampleForm}
              closeFlyout={() => setShowSplitSampleForm(false)}
            >
              <TransactionSplitForm
                formTitle={t("Split Sample Request")}
                subtitle={t("Select Products to move to a new Sample Request")}
                buttonText={t("Split Sample Request")}
                isLoading={is_splitting_sample}
                columns={tableColumns}
                tableData={tableData}
                methodsOfUseForm={methodsOfUseForm}
                onSubmit={onSplitSampleRequest}
              />
            </SlideOut>
          )}
          <TransactionsDocumentView
            items={sampleRequest.items}
            other_documents={sampleRequest.other_documents}
            onAddDocumentClick={() => setShowAddDocumentForm(true)}
            status={sampleRequest.status}
            can_add_documents={roleIsSomeKindOfSeller}
          />
        </SampleRequestContent>
        <TimelineWrapper>
          {user && sampleEvents && sampleRequest && (
            <Timeline
              messages={sampleEvents}
              loggedInUser={user as User} // Make sure to have a logic to determine the logged-in user
              fetchingData={false}
              sendMessage={handleSendMessage}
              sampleRequest={sampleRequest}
            />
          )}
        </TimelineWrapper>
      </DetailPageContentWrapper>
    </PageWrapper>
  );
};
