import { yupResolver } from "@hookform/resolvers/yup";
import { Card } from "../../../layout/portalPageLayout";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import { SectionTitle } from "../../../components/Form/Form";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { customPackagingOption } from "../../../components/quoteCart/BuyerQuoteItemFormParts";
import { Title } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import type {
  Tenant,
  ITenantCustomerSettings,
  Product,
  IPriceTierPaginatedOutput,
  IPackagingType,
  IPackagingUnit,
  IPriceTier,
  UUID,
  OptionType,
} from "../../../types/types";
import { calculatePriceTiersPricePerUom } from "../../../util/QuotesAndOrders";
import { positiveIntegerRegex } from "../../../util/regexes";
import {
  useStorefrontPackagingTypes,
  usePackagingUnits,
  packagingTypeOrUnitToOption,
} from "../../../util/SkuUtils";
import { strings } from "../../../util/strings";
import {
  useStoreState,
  useFormWrapper,
  makeUrlWithParams,
  convertProductSKUToOption,
  convertApplicationToOption,
  convertPaymentModeToOption,
  isAxiosError,
  POCManualFormSchema,
  POCFormSchema,
  addressToOption,
} from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import type {
  AddOrEditProductFormOutput,
  InlineDisplayCustomSKU,
} from "./AddOrEditProductForm";
import { AddOrEditProductForm } from "./AddOrEditProductForm";
import type {
  CreateOrderBottomFormOutput,
  CreateOrderBottomFormPOCForm,
  CreateOrderBottomFormPOCManual,
} from "./CreateOrderBottomForm";
import { CreateOrderBottomForm } from "./CreateOrderBottomForm";
import type { OrderItemToBeCreated } from "./CreateOrderItemCard";
import { CreateOrderItemCard } from "./CreateOrderItemCard";
import type { CustomerDetailsFormOutput } from "./CustomerDetailsForm";
import { CustomerDetailsForm } from "./CustomerDetailsForm";
import * as z from "zod";
import * as Yup from "yup";
import { Form } from "../../../layout/FormLayout";
import { FormProvider, useFieldArray } from "react-hook-form";
import { PrimaryButtonLarge } from "../../../components/Buttons/Buttons";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { productApplicationSchema } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import {
  RequestAddress,
  getRequestAddressSchema,
} from "../../public/RequestAddress/RequestAddress";
import { PointOfContact } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/PointOfContactForm";
import { getUserDetailsFromTenant } from "../SellerCreation.constants";
import type { LocationInputs } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/LocationForm";
import { useAddLocation } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/EditLocations";
import { ConfirmationSpacer } from "../../public/SampleRequestCart/SampleRequestCart";
import {
  configured_checkboxes_schema_yup,
  create_configured_checkboxes_fields,
} from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 36px;
`;
const CardNoMargin = styled(Card)`
  margin-top: 0;
`;

const FormSection = styled.section`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  &:nth-child(0) {
    margin-bottom: 40px !important;
  }
  &:nth-child(1) {
    margin-bottom: 0 !important;
  }
  margin-bottom: 20px;
`;

const MainSection = styled.section`
  overflow-y: auto;
`;

const OrderItemArea = styled.div`
  margin-bottom: 40px;
`;

const AddressWrapper = styled(OrderItemArea)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
`;

const OrderTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

const SectionTitleNoMargin = styled(SectionTitle)`
  margin-top: 0px;
`;

const BottomFormArea = styled.div`
  width: 40%;
`;

//margin-block-end: 0;
const TitleReset = styled(Title)`
  margin-inline-start: 0px;
  margin-block-start: 0;
  margin-inline-end: 0px;
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 * This is the object for the network request
 */
const OrderCreationSchema = z.object({
  buyer_id: z.string(),
  tenant_id: z.string(),
  order_placed_via: z.enum(["Phone", "Email", "Text", "Fax"]),
  shipping_address_id: z.string(),
  delivery_term_id: z.string(),
  payment_term_id: z.string(),
  required_eta: z.string(), // there may be a way to parse this as a date.
  payment_mode_id: z.string(),
  items: z
    .object({
      product_id: z.string(),
      sku: z.object({
        id: z.string(),
      }), // TODO make this OR skucreationargs
      price_per_unit: z.number(),
      no_of_units: z.number(),
      total_quantity: z.string(), // TODO number
      applications: z.string(), // TODO confirm this.
    })
    .array(),
});

type SellerCreateOrderPOST = z.infer<typeof OrderCreationSchema>;

export function SellerCreateOrder({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation();
  const {
    tenant_id,
    storefront_id,
    storefront_metadata: { configured_checkboxes },
  } = useStoreState();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const [selectedBuyer, setSelectedBuyer] = useState<Tenant | null>(null);
  const [orderItems, setOrderItems] = useState<OrderItemToBeCreated[]>([]);
  const customerDetailsIsDisabled = orderItems.length > 0;
  const [customerSettings, setCustomerSettings] =
    useState<ITenantCustomerSettings | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [formData, setFormData] = useState<{
    customer: CustomerDetailsFormOutput | null;
    bottomForm: CreateOrderBottomFormOutput | null;
  }>({ customer: null, bottomForm: null });
  const [selectedItemIndex, setSelectedItemIndex] =
    useState<number | null>(null);
  const [priceTiers, setPriceTiers] = useState<IPriceTier[]>([]);
  const [pointOfContactType, setPointOfContactType] =
    useState<"manual" | "existing">("existing");
  const [loading, setLoading] = useState(false);
  const isEditMode = selectedItemIndex !== null;

  // These will hit the cache, potentially should be passed as props to
  // product form.
  const { packagingTypes } = useStorefrontPackagingTypes();
  const { packagingUnits } = usePackagingUnits();
  const addLocation = useAddLocation({
    buyer_tenant_id: selectedBuyer?.id ?? "",
  });

  const yupSharedSchema = {
    product_id: Yup.string()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    // sku_id: Yup.object().required(strings(t).thisIsARequiredField),
    // price_per_uom: Yup.number().required(strings(t).thisIsARequiredField),
    applications: Yup.array().notRequired(),
    ...productApplicationSchema(t),
  };

  const yupSchemaNoPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      unit_of_measure: Yup.object().required(strings(t).thisIsARequiredField),
      //   total_quantity: Yup.string().required(strings(t).thisIsARequiredField),
      price_per_unit: Yup.string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
    })
    .defined();

  const mustBeIntegerErrorMessage = t("Must be an integer value");

  const yupSchemaCustomPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      //   total_quantity: Yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: Yup.string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage),
      unit_of_measure: Yup.object().required(strings(t).thisIsARequiredField),
      custom_packaging_type: Yup.object().required(
        strings(t).thisIsARequiredField
      ),
      price_per_unit: Yup.string()
        .nullable()
        .required(strings(t).thisIsARequiredField),
      custom_packaging_quantity: Yup.string().required(
        strings(t).thisIsARequiredField
      ),
    })
    .defined();

  const yupSchemaProductSkuPackaging = Yup.object()
    .shape({
      ...yupSharedSchema,
      packaging: Yup.object()
        .shape({
          value: Yup.object().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      total_quantity: Yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: Yup.string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage)
        .test("no_of_units", strings(t).priceTierMatchError, (val) =>
          priceTiers.length
            ? !!calculatePriceTiersPricePerUom(
                priceTiers,
                val ? parseFloat(val) : null
              )
            : !!val
        ),
      price_per_unit: Yup.string()
        .nullable()
        .test(
          "price_per_unit",
          strings(t).thisIsARequiredField,
          (val) => !!priceTiers.length || !!val
        ),
    })
    .defined();

  type FormInputsProductSkuPackaging = Yup.InferType<
    typeof yupSchemaProductSkuPackaging
  >;
  type FormInputsNoPackaging = Yup.InferType<typeof yupSchemaNoPackaging>;
  type FormInputsCustomPackaging = Yup.InferType<
    typeof yupSchemaCustomPackaging
  >;

  type FormInputsFromSchemas =
    | FormInputsProductSkuPackaging
    | FormInputsNoPackaging
    | FormInputsCustomPackaging;

  // We use Yup.lazy to choose a schema to validate against based on what the
  // user selects for `packaging`. So dynamic!
  const lazyYupSchema = Yup.lazy<FormInputsFromSchemas>((inputs) => {
    // TODO: validate presence of price_per_uom, should be required if price
    // tiers don't exist.
    if (
      (inputs as unknown as { packaging: OptionType<string> }).packaging
        .value === "no_packaging"
    ) {
      return yupSchemaNoPackaging;
    }
    if (
      (inputs as unknown as { packaging: OptionType<string> }).packaging
        .value === "custom_packaging"
    ) {
      return yupSchemaCustomPackaging;
    }
    return yupSchemaProductSkuPackaging;
  });

  const bottomOrderFormSchema = Yup.lazy((formValues: any) =>
    Yup.object().shape({
      configured_checkboxes: configured_checkboxes_schema_yup({
        formValues,
        t,
      }),
      required_eta: Yup.string().required(strings(t).thisIsARequiredField),
      payment_method: Yup.object()
        .required(strings(t).thisIsARequiredField)
        .shape({
          value: Yup.string().required(strings(t).thisIsARequiredField),
        }),
      ...(formData.customer?.shipping_address_id?.value === "new_address"
        ? {
            ...getRequestAddressSchema(t, true),
            ...(pointOfContactType === "manual"
              ? POCManualFormSchema(t, formValues)
              : POCFormSchema(t)),
          }
        : {}),
    })
  );

  const customerDetailsMethods = useFormWrapper<CustomerDetailsFormOutput>();

  const productInfoMethods = useFormWrapper<AddOrEditProductFormOutput>({
    resolver: yupResolver(lazyYupSchema),
    defaultValues: {
      packaging: { value: undefined, label: undefined },
    },
  });
  const BottomFormMethods = useFormWrapper({
    resolver: yupResolver(bottomOrderFormSchema),
  });
  const { control } = BottomFormMethods;
  const { watch: customerDetailsWatch } = customerDetailsMethods;
  const {
    watch: productInformationWatch,
    reset: productInfoReset,
    setValue: setProductValue,
  } = productInfoMethods;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  const orderCheckboxes = configured_checkboxes.filter((item) => item.quote);
  useEffect(() => {
    if (orderCheckboxes.length > 0 && fields?.length < 1) {
      orderCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [orderCheckboxes, append, fields]);

  const allCustomerDetailsValues = customerDetailsWatch();

  const {
    shipping_address_id: destinationOption,
    delivery_term: deliveryTermOption,
    payment_term: paymentTermOption,
    buyer_id: buyerOption,
  } = allCustomerDetailsValues;

  const customerDetailsValueArray = Object.values(allCustomerDetailsValues);

  const productFormIsDisabled = !(
    customerDetailsValueArray.length > 0 &&
    customerDetailsValueArray.every((val: unknown) => !!val)
  );

  const { packaging: packagingOption } = productInformationWatch(["packaging"]);

  const bottomFormOnSubmit = async <T extends CreateOrderBottomFormOutput>(
    output: T
  ) => {
    if (!loading) {
      setLoading(true);
      setFormData((data) => ({ ...data, bottomForm: output }));
      const bottomFormDetails =
        pointOfContactType === "manual"
          ? ((await bottomOrderFormSchema.validate(
              output
            )) as CreateOrderBottomFormPOCManual)
          : ((await bottomOrderFormSchema.validate(
              output
            )) as CreateOrderBottomFormPOCForm);
      let newAddress: OptionType<string> | null = null;
      if (destinationOption.value === "new_address") {
        const inputs: LocationInputs = {
          company_name: bottomFormDetails?.company_name ?? "",
          external_id: bottomFormDetails?.external_id ?? "",
          address1: bottomFormDetails.address1,
          address2: bottomFormDetails.address2,
          country: bottomFormDetails.country,
          county: bottomFormDetails.county,
          state: bottomFormDetails.state,
          city: bottomFormDetails.city,
          postal_code: bottomFormDetails.postal_code,
          is_active: true,
          contact_first_name: (
            bottomFormDetails as CreateOrderBottomFormPOCManual
          ).contact_first_name,
          contact_last_name: (
            bottomFormDetails as CreateOrderBottomFormPOCManual
          ).contact_last_name,
          email_address: (bottomFormDetails as CreateOrderBottomFormPOCManual)
            .email_address,
          phone_number: (bottomFormDetails as CreateOrderBottomFormPOCManual)
            .phone_number,
          country_code: (bottomFormDetails as CreateOrderBottomFormPOCManual)
            .country_code,
          point_of_contact_id: (
            bottomFormDetails as CreateOrderBottomFormPOCForm
          ).point_of_contact_id,
        };
        const newLocation = await addLocation({
          inputs,
          pocType: pointOfContactType,
        });
        if (newLocation) {
          newAddress = addressToOption(newLocation);
        }
      }
      const hasAddress =
        destinationOption?.value === "new_address"
          ? Boolean(newAddress)
          : Boolean(destinationOption?.value);
      if (hasAddress) {
        try {
          // These Should always exists, checks for typescripts sake.
          if (formData.customer && selectedBuyer) {
            const data: SellerCreateOrderPOST = {
              buyer_id: formData.customer.buyer_id.value,
              tenant_id: selectedBuyer.id,
              // TODO remove any
              order_placed_via: formData.customer.order_place_via.value as any,
              shipping_address_id: newAddress
                ? newAddress.value
                : formData.customer.shipping_address_id.value,
              delivery_term_id: formData.customer.delivery_term.value,
              payment_term_id: formData.customer.payment_term.value,
              required_eta: output.required_eta,
              payment_mode_id: output.payment_method.value,

              items: [
                ...orderItems.map((item) => {
                  let sku: any = {};
                  // TODO remove this
                  // @ts-ignore
                  if (item.sku && item.sku?.id) {
                    // TODO remove this
                    // @ts-ignore
                    sku.id = item.sku?.id;
                  } else {
                    sku.package_volume = item.sku.package_volume;
                    sku.packaging_type_id = item.sku.packaging_type.id;
                    sku.packaging_unit_id = item.sku.packaging_unit.id;
                  }

                  return {
                    product_id: item.product_id,
                    price_per_unit: Number(item.price_per_unit),
                    no_of_units: Number(item.no_of_units),
                    total_quantity: item.total_quantity,
                    applications: item?.product_applications[0]?.id ?? null,
                    ...(item.custom_application
                      ? { custom_application: item.custom_application }
                      : {}),
                    sku,
                  };
                }),
              ],
            };
            await Axios.post(
              `/v1/storefronts/${storefront_id}/transactions/create-order`,
              {
                transaction_state: "order_accepted",
                data,
              }
            );

            notifySuccess("Order recorded successfully");
            setLoading(false);
            onSuccess();
          }
        } catch (error) {
          if (
            isAxiosError(error) &&
            error?.response?.data?.status_code === "403"
          ) {
            notifyError(error.response.data.message);
          } else {
            notifyError("There was an error creating the order", { error });
          }
          console.error(error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const customerDetailsOnSubmit = async (output: CustomerDetailsFormOutput) => {
    setFormData((data) => ({ ...data, customer: output }));
    BottomFormMethods.handleSubmit(bottomFormOnSubmit);
  };

  const packagingSkuOption =
    packagingOption &&
    // TODO can this be removed?
    // @ts-ignore
    packagingOption?.value !== "no_packaging" &&
    packagingOption?.value !== "custom_packaging"
      ? packagingOption
      : null;

  // TODO should product form stay disabled if this is not fetched?
  const currencyCode = customerSettings?.preferred_currency;

  useSWR<IPriceTierPaginatedOutput>(
    packagingSkuOption?.value?.id &&
      destinationOption?.value &&
      destinationOption?.value !== "new_address" &&
      deliveryTermOption?.value &&
      paymentTermOption?.value &&
      buyerOption?.value
      ? makeUrlWithParams(endpoints.v1_priceTiers(), {
          seller_id: tenant_id,
          buyer_id: buyerOption.value,
          destination_id: destinationOption.value,
          product_sku_id: packagingSkuOption.value.id,
          delivery_term_id: deliveryTermOption.value,
          payment_term_id: paymentTermOption.value,
          currency: currencyCode,
        })
      : null,
    {
      onError: (error) => {
        notifyError("There was an error checking for price tiers", { error });
      },
      onSuccess: ({ data }) => setPriceTiers(data),
    }
  );

  const productDetailsOnSubmit = async (input: AddOrEditProductFormOutput) => {
    // "value" not used currently
    // const value = await customerDetailsMethods.handleSubmit(
    //   customerDetailsOnSubmit
    // )();
    // assuming customer details is valid here, because we can't get here if all
    // fields don't have values. Also no fields are clearable? SHould they be?

    const theProduct = product as Product;

    const sku =
      input.packaging.value === "custom_packaging"
        ? ({
            packaging_type: packagingTypes?.find(
              (item) => item.id === input?.custom_packaging_type?.value
            ) as IPackagingType,
            package_volume: Number(input.custom_packaging_quantity),
            packaging_unit: packagingUnits?.find(
              (item) => item.id === input?.unit_of_measure?.value
            ) as IPackagingUnit,
            erp_system_id: "",
            kind: "Buyer SKU",
          } as InlineDisplayCustomSKU)
        : input.packaging.value;

    const object: OrderItemToBeCreated = {
      price_per_unit:
        input.price_per_unit ??
        // Price tiers will exist if price_per_unit does not.
        calculatePriceTiersPricePerUom(priceTiers!, Number(input.no_of_units)),
      product_applications: input.application?.value
        ? [
            {
              name: input.application.label,
              id: input.application.value as string,
              image_url: "fake",
            },
          ]
        : [],
      ...(input.custom_application
        ? { custom_application: input.custom_application }
        : {}),
      product_id: input.product_id,
      // Is this the correct logic?
      currency: customerSettings?.preferred_currency ?? "USD",
      total_quantity: input.total_quantity,
      no_of_units: input.no_of_units,
      product: {
        name: theProduct.name,
      },
      product_identity: {
        cas_number: theProduct?.identifiers?.cas_number ?? null,
      },
      sku,
      id: uuidv4(),
      entire_product: product as Product,
    };

    const index = orderItems.findIndex(
      (item) => item.product_id === input.product_id
    );

    if (index >= 0) {
      setOrderItems((items) =>
        items.map((item, idx) => {
          if (idx === index) {
            return object;
          } else return item;
        })
      );
    } else {
      setOrderItems((items) => [...items, object]);
    }

    clearProductForm();
    setSelectedItemIndex(null);

    return object;
  };

  const clearProductForm = (): void => {
    productInfoReset();
    setProductValue("packaging", { value: undefined, label: undefined });
    setProductValue("product_id", null);
    setProduct(null);
    setProductValue("application", null);
    setProductValue("price_per_unit", null);
    setPriceTiers([]);
  };

  /**
   * Prefill the product form in edit mode.
   * @param item
   */
  const prefillProductForm = (item: OrderItemToBeCreated): void => {
    if (item.sku.kind === "Buyer SKU") {
      setProductValue("packaging", customPackagingOption);
    } else setProductValue("packaging", convertProductSKUToOption(item.sku));
    setProduct(item.entire_product);
    // Product will exist here if form submits
    // maybe?
    setProductValue("product_id", item.entire_product.id);
    setProductValue(
      "application",
      item.product_applications[0]
        ? convertApplicationToOption(item.product_applications[0])
        : null
    );
    setProductValue("price_per_unit", item.price_per_unit);

    setTimeout(() => {
      setProductValue("disabled_uom", item.sku.packaging_unit?.name);
      // This is here because I ran out of time..
      // useQuantityInputFieldClearer will clobber the values if we don't wait.
      setProductValue("total_quantity", item.total_quantity);
      setProductValue("no_of_units", item.no_of_units);

      // Below is for custom SKU:
      setProductValue(
        "custom_packaging_type",
        packagingTypeOrUnitToOption(item.sku.packaging_type)
      );
      setProductValue("custom_packaging_quantity", item.sku.package_volume);
      setProductValue(
        "unit_of_measure",
        packagingTypeOrUnitToOption(item.sku.packaging_unit)
      );
    });
  };

  return (
    <>
      {/* Title is larger than design but consistent with app. */}
      <TitleReset>{t("Record an Order")}</TitleReset>
      <Container>
        <FormSection>
          <CardNoMargin>
            {/* "Create Order Form" */}
            <FormProvider {...customerDetailsMethods}>
              <Form
                noValidate
                onSubmit={customerDetailsMethods.handleSubmit(
                  customerDetailsOnSubmit
                )}
              >
                <SectionTitleNoMargin>
                  {t("Customer Details")}
                </SectionTitleNoMargin>
                <CustomerDetailsForm
                  setSelectedBuyer={setSelectedBuyer}
                  isDisabled={customerDetailsIsDisabled}
                  setCustomerSettings={setCustomerSettings}
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
          <CardNoMargin selectedBorder={isEditMode}>
            {/* "Create/Edit Product Form" */}
            <FormProvider {...productInfoMethods}>
              <Form
                onSubmit={productInfoMethods.handleSubmit(
                  productDetailsOnSubmit
                )}
                noValidate
              >
                <SectionTitleNoMargin>
                  {t("Choose Products")}
                </SectionTitleNoMargin>
                <AddOrEditProductForm
                  isDisabled={productFormIsDisabled}
                  priceTiers={priceTiers}
                  // fallback is displayed before a buyer is selected.
                  currencyCode={currencyCode ?? "USD"}
                  product={product}
                  setProduct={setProduct}
                  isEditMode={isEditMode}
                  onCancel={() => {
                    setSelectedItemIndex(null);
                    clearProductForm();
                  }}
                  // This will exist by the time this form is interactive.
                  buyer_tenant_id={selectedBuyer?.id as UUID}
                />
              </Form>
            </FormProvider>
          </CardNoMargin>
        </FormSection>
        <MainSection>
          {orderItems.length > 0 ? (
            <CardNoMargin>
              {/* Cart section */}
              {/* Manually add 15px because the Form component does this for customer details */}
              <SectionTitleNoMargin style={{ marginBottom: "15px" }}>
                {t("Order Summary")}
              </SectionTitleNoMargin>
              <OrderItemArea>
                {orderItems &&
                  orderItems.map((item, index) => (
                    <CreateOrderItemCard
                      key={item.id}
                      isSelected={selectedItemIndex === index}
                      setSelectedItemIndex={setSelectedItemIndex}
                      item={item}
                      index={index}
                      editItem={(item) => prefillProductForm(item)}
                      removeItem={(item) => {
                        setOrderItems((items) =>
                          items.filter((i) => i.id !== item.id)
                        );
                      }}
                    />
                  ))}
              </OrderItemArea>
              <FormProvider {...BottomFormMethods}>
                <FlexBetween>
                  <SectionTitleNoMargin>{t("Your Terms")}</SectionTitleNoMargin>
                  <OrderTotalContainer>
                    <>
                      <SectionTitleNoMargin>{t("Total")}</SectionTitleNoMargin>
                      <SectionTitleNoMargin>
                        {" "}
                        {formatPrice(
                          orderItems.reduce(
                            (acc, item) =>
                              (acc +=
                                parseFloat(item.total_quantity) *
                                parseFloat(item.price_per_unit)),
                            0
                          ),
                          customerSettings?.preferred_currency
                        )}
                      </SectionTitleNoMargin>
                    </>
                  </OrderTotalContainer>
                </FlexBetween>
                <BottomFormArea>
                  <Form
                    noValidate
                    onSubmit={BottomFormMethods.handleSubmit(
                      bottomFormOnSubmit
                    )}
                  >
                    <CreateOrderBottomForm
                      showConfirmation={false}
                      defaultPaymentMethod={
                        customerSettings?.default_payment_mode
                          ? convertPaymentModeToOption(
                              customerSettings?.default_payment_mode
                            )
                          : null
                      }
                    />
                    {Boolean(destinationOption) &&
                    destinationOption.value === "new_address" &&
                    Boolean(selectedBuyer) ? (
                      <AddressWrapper>
                        <RequestAddress
                          methodsOfUseForm={BottomFormMethods}
                          hideBillingAddress
                          showBillingAddressOption={false}
                          showLocationId={true}
                          show_tax_id={true}
                          formTitle={t("Add new address")}
                        />
                        <PointOfContact
                          methodsOfUseForm={BottomFormMethods}
                          pointOfContactType={pointOfContactType}
                          setPointOfContactType={setPointOfContactType}
                          defaultExistingUser={getUserDetailsFromTenant(
                            selectedBuyer!
                          )}
                        />
                      </AddressWrapper>
                    ) : (
                      <></>
                    )}
                    <ConfirmationSpacer>
                      {fields && fields?.length > 0 && (
                        <>
                          {create_configured_checkboxes_fields({
                            fields,
                            methodsOfUseForm: BottomFormMethods,
                            t,
                          })}
                        </>
                      )}
                    </ConfirmationSpacer>
                    <PrimaryButtonLarge
                      loading={loading}
                      type="submit"
                      onClick={() => {
                        customerDetailsMethods.handleSubmit(
                          customerDetailsOnSubmit
                        )();
                      }}
                    >
                      {t("Create Order")}
                    </PrimaryButtonLarge>
                  </Form>
                </BottomFormArea>
              </FormProvider>
              {/* Submit cart form. */}
            </CardNoMargin>
          ) : (
            // TODO correct font size for body when it exists
            <CardNoMargin style={{ lineHeight: "28px", fontSize: "19px" }}>
              <SectionTitleNoMargin>{t("Order Summary")}</SectionTitleNoMargin>
              <p>{t("To record an order follow these steps")}</p>
              <ol type="1">
                <li>{t("Choose your customer and review the details")}</li>
                <li>{t("Add products to the cart")}</li>
                <li>{t("Review the cart and place the order")}</li>
              </ol>
            </CardNoMargin>
          )}
        </MainSection>
      </Container>
    </>
  );
}
