import React from "react";
import type { IOrderItem, OrderStatus } from "../../types/types";
import { StatusLeft, StatusRight } from "../Status/Status";
import type { FiltersDropDownItem } from "../FiltersDropDown/FiltersDropDown";

type OrderStatusProps = {
  status: OrderStatus;
  position?: "right" | "left";
  id?: string;
  t: (s: string) => string;
};

export const BuyerOrderStatus = ({
  status,
  position,
  id,
  t,
}: OrderStatusProps) => {
  switch (status) {
    case "new":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Order Placed")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Order Placed")} id={id} />
      );
    case "accepted":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Order Accepted")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Order Accepted")} id={id} />
      );
    case "in_progress":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("In Progress")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("In Progress")} id={id} />
      );
    case "completed":
      return position === "left" ? (
        <StatusLeft color="green" text={t("Completed")} id={id} />
      ) : (
        <StatusRight color="green" text={t("Completed")} id={id} />
      );
    case "invoiced":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Invoiced")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Invoiced")} id={id} />
      );
    case "declined":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Declined")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Declined")} id={id} />
      );
    case "cancelled":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Cancelled")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Cancelled")} id={id} />
      );
    case "payment_received":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Payment Received")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Payment Received")} id={id} />
      );
    case "shipped":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Shipped")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Shipped")} id={id} />
      );
    case "pending_activation":
    case "pending":
      return position === "left" ? (
        <StatusLeft color="gray" text={t("Pending Activation")} id={id} />
      ) : (
        <StatusRight color="gray" text={t("Pending Activation")} id={id} />
      );
    default:
      return position === "left" ? (
        <StatusLeft color="gray" text={status} id={id} />
      ) : (
        <StatusRight color="gray" text={status} id={id} />
      );
  }
};

export const SellerOrderStatus = ({
  status,
  position,
  id,
  t,
}: OrderStatusProps) => {
  switch (status) {
    case "new":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("New Order")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("New Order")} id={id} />
      );
    case "accepted":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Order Accepted")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Order Accepted")} id={id} />
      );
    case "in_progress":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("In Progress")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("In Progress")} id={id} />
      );
    case "completed":
      return position === "left" ? (
        <StatusLeft color="green" text={t("Completed")} id={id} />
      ) : (
        <StatusRight color="green" text={t("Completed")} id={id} />
      );
    case "invoiced":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Invoiced")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Invoiced")} id={id} />
      );
    case "declined":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Declined")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Declined")} id={id} />
      );
    case "cancelled":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Cancelled")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Cancelled")} id={id} />
      );
    case "payment_received":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Payment Received")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Payment Received")} id={id} />
      );
    case "shipped":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Shipped")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Shipped")} id={id} />
      );
    case "pending_activation":
    case "pending":
      return position === "left" ? (
        <StatusLeft color="gray" text={t("Pending Activation")} id={id} />
      ) : (
        <StatusRight color="gray" text={t("Pending Activation")} id={id} />
      );
    default:
      return <StatusRight color="gray" text={status} id={id} />;
  }
};

export const getBuyerOrderStatusFilters = (
  t: (s: string) => string
): FiltersDropDownItem[] => [
  { color: "blue", text: t("Order Placed"), value: "new" },
  { color: "fullBlue", text: t("Order Accepted"), value: "accepted" },
  { color: "fullBlue", text: t("In Progress"), value: "in_progress" },
  { color: "red", text: t("Invoiced"), value: "invoiced" },
  { color: "fullBlue", text: t("Shipped"), value: "shipped" },
  { color: "green", text: t("Completed"), value: "completed" },
  { color: "red", text: t("Declined"), value: "declined" },
  { color: "red", text: t("Cancelled"), value: "cancelled" },
];

export const SellerOrderStatusFilters = (
  t: (s: string) => string
): FiltersDropDownItem[] => [
  { color: "blue", text: t("New Order"), value: "new" },
  { color: "fullBlue", text: t("Order Accepted"), value: "accepted" },
  { color: "fullBlue", text: t("In Progress"), value: "in_progress" },
  { color: "red", text: t("Invoiced"), value: "invoiced" },
  { color: "fullBlue", text: t("Shipped"), value: "shipped" },
  { color: "green", text: t("Completed"), value: "completed" },
  { color: "red", text: t("Declined"), value: "declined" },
  { color: "red", text: "Cancelled", value: "cancelled" },
  { color: "gray", text: t("Pending Activation"), value: "pending_activation" },
];

export const getOrderProductsAsText = (
  orderItems: IOrderItem[],
  t: (s: string) => string
) => {
  if (orderItems[0]?.product ?? undefined) {
    if (orderItems.length === 1) {
      return `${orderItems[0].product.name}`;
    }

    if (orderItems.length > 1) {
      return `${orderItems[0].product.name} + ${orderItems.length - 1} ${t(
        "more"
      )}`;
    }
  }
  return `--`;
};
